import { createSlice } from '@reduxjs/toolkit';

import AttorneyDetailReportRepository, {
  IndexParams as AttorneyDetailReportIndexParams,
} from 'repositories/Reports/AttorneyDetailReportRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { IAttorneyDetailReport } from 'domain/attorneyDetailReport/types';
import { createAsyncAction } from 'utils/createAsyncAction';

export type AttorneyDetailReportSliceStateType = {
  attorneyDetailReport: IAttorneyDetailReport;
  attorneyDetailReportState: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type AttorneyDetailReportSliceActionsType = {
  loadAttorneyDetailReport: (params: AttorneyDetailReportIndexParams) => {
    unwrap: () => Promise<IAttorneyDetailReport>;
  };
  reset: () => void;
};

export const loadAttorneyDetailReport = createAsyncAction(
  'attorneyDetailReport/index',
  AttorneyDetailReportRepository.index,
);

const initialState: AttorneyDetailReportSliceStateType = {
  attorneyDetailReport: {} as IAttorneyDetailReport,
  attorneyDetailReportState: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const attorneyDetailReportSlice = createSlice({
  name: 'attorneyDetailReport',
  initialState,
  reducers: {
    reset: state => {
      state.attorneyDetailReport = {} as IAttorneyDetailReport;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadAttorneyDetailReport.pending, state => {
      state.attorneyDetailReportState.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadAttorneyDetailReport.fulfilled, (state, { payload }) => {
      state.attorneyDetailReportState.fetchStatus = FetchStatus.fulfilled;
      state.attorneyDetailReport = payload;
    });
    builder.addCase(loadAttorneyDetailReport.rejected, state => {
      state.attorneyDetailReportState.fetchStatus = FetchStatus.rejected;
    });
  },
});

const { reset } = attorneyDetailReportSlice.actions;

export { reset };

export default attorneyDetailReportSlice.reducer;
