import publicRoutes from './public/paths';
import attorneyRoutes from './attorney/paths';
import marketeerRoutes from './marketeer/paths';
import adminRoutes from './admin/paths';
import adminAppRoutes from './adminApp/paths';
import * as apiRoutes from './apiRoutes';
import * as apiAdminRoutes from './apiAdminRoutes';

const appRoutes = {
  public: publicRoutes,
  attorney: attorneyRoutes,
  marketeer: marketeerRoutes,
  admin: adminRoutes,
  adminApp: adminAppRoutes,
};

export { apiRoutes, appRoutes, apiAdminRoutes };
