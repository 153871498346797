export default {
  currentPassword: {
    label: 'Current Password',
    placeholder: 'Enter current password',
  },
  password: {
    label: 'New Password',
    placeholder: 'Enter new password',
  },
  passwordConfirmation: {
    label: 'Repeat new Password',
    placeholder: 'Repeat new password',
    errorLabel: 'Password Confirmation',
  },
  submit: 'Change',
  cancel: 'Cancel',
  successMessage: 'Your password has been changed!',
};
