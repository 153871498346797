import notFoundPage from './notFoundPage';
import companies from './companies';
import companyCreate from './companyCreate';
import companyEdit from './companyEdit';
import contactCreate from './contactCreate';
import contactEdit from './contactEdit';
import contacts from './contacts';
import activities from './activities';
import activityDetails from './activityDetails';
import activityCreate from './activityCreate';
import activityEdit from './activityEdit';
import companyDetails from './companyDetails';
import goalCreate from './goalCreate';
import goalEdit from './goalEdit';
import profileEdit from './profileEdit';
import teams from './teams';
import contactDetails from './contactDetails';
import taskCreate from './taskCreate';
import taskEdit from './taskEdit';
import tasks from './tasks';
import members from './members';
import projects from './projects';
import projectDetails from './projectDetails';
import teamDetails from './teamDetails';
import team from './team';
import projectCreate from './projectCreate';
import projectEdit from './projectEdit';
import departmentProductivity from './departmentProductivity';
import projectNameDetail from './projectNameDetail';
import teamDetailReport from './teamDetailReport';
import attorneyDetailReport from './attorneyDetailReport';
import practiceGroupReport from './practiceGroupReport';
import personalTeamDetailReport from './personalTeamDetailReport';
import practiceGroupDetail from './practiceGroupDetail';
import practiceGroupDetailReport from './practiceGroupDetailReport';
import projectType from './projectType';
import attorneyDetailReportUser from './attorneyDetailReportUser';
import workspaceNotAllowed from './workspaceNotAllowed';
import notifications from './notifications';
import notificationsCreate from './notificationsCreate';
import globalSearch from './globalSearch';
import achievements from './achievements';
import calendarEventCreate from './calendarEventCreate';
import calendarEventEdit from './calendarEventEdit';
import calendarEventDetails from './calendarEventDetails';
import companyReport from './companyReport';
import companyDetailReport from './companyDetailReport';
import winlossReport from './winlossReport';
import awardNominationsReport from './awardNominationsReport';

export default {
  notFoundPage,
  companyCreate,
  companyEdit,
  contactCreate,
  contactEdit,
  companies,
  contacts,
  activities,
  activityDetails,
  activityCreate,
  activityEdit,
  companyDetails,
  goalCreate,
  goalEdit,
  profileEdit,
  teams,
  contactDetails,
  taskCreate,
  taskEdit,
  tasks,
  members,
  projects,
  projectDetails,
  teamDetails,
  team,
  projectCreate,
  projectEdit,
  departmentProductivity,
  projectNameDetail,
  teamDetailReport,
  attorneyDetailReport,
  practiceGroupReport,
  personalTeamDetailReport,
  practiceGroupDetail,
  practiceGroupDetailReport,
  projectType,
  attorneyDetailReportUser,
  workspaceNotAllowed,
  notifications,
  notificationsCreate,
  globalSearch,
  achievements,
  calendarEventCreate,
  calendarEventEdit,
  calendarEventDetails,
  companyReport,
  companyDetailReport,
  winlossReport,
  awardNominationsReport,
};
