import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { IDailyGroupedScore } from 'domain/dailyGroupedScore/types';
import { IMembership } from 'domain/membership/types';
import { ITeam } from 'domain/team/types';

export type ShowTotalPointsParams = {
  clientId: ID;
  queryParams: {
    q: {
      createdAtGteq: string;
      createdAtLteq: string;
    };
  };
};

export type ShowDailyScoreParams = {
  clientId: ID;
  params: {
    minDate: string;
    maxDate: string;
  };
};

const AttorneyDashboardRepository = {
  teamsTotalPoints({ clientId, queryParams }: ShowTotalPointsParams): Promise<CollectionResponse<'teams', ITeam[]>> {
    const url = apiRoutes.teamsTotalPointsApiSiteV1ClientDashboardPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  teamMembersTotalPoints({
    clientId,
    queryParams,
  }: ShowTotalPointsParams): Promise<CollectionResponse<'memberships', IMembership[]>> {
    const url = apiRoutes.teamMembersTotalPointsApiSiteV1ClientDashboardPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  personalDailyScoreEvents({
    clientId,
    params,
  }: ShowDailyScoreParams): Promise<CollectionResponse<'dailyGroupedScores', IDailyGroupedScore[]>> {
    const url = apiRoutes.personalDailyScoreEventsApiSiteV1ClientDashboardPath(clientId);
    return Fetcher.get(url, params);
  },
  averageDailyScoreEvents({
    clientId,
    params,
  }: ShowDailyScoreParams): Promise<CollectionResponse<'dailyGroupedScores', IDailyGroupedScore[]>> {
    const url = apiRoutes.averageDailyClientScoreEventsApiSiteV1ClientDashboardPath(clientId);
    return Fetcher.get(url, params);
  },
};

export default AttorneyDashboardRepository;
