import { AxiosResponse } from 'axios';

import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { IUser } from 'domain/user/types';
import { PasswordRecoveryFormDataToSubmit } from 'forms/public/passwordRecoveryForm';
import { PasswordResetFormDataToSubmit } from 'forms/public/passwordResetForm';
import { PasswordChangeFormDataToSubmit } from 'forms/passwordChangeForm';
import { ProfileFormDataToSubmit } from 'forms/profileForm';
import { TableFilterQueryParams } from 'types/tableFilter';

export type UpdateCurrentUserParams = {
  params: ProfileFormDataToSubmit;
};

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

const UsersRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'users', IUser[]>> {
    const url = apiRoutes.apiSiteV1ClientUsersPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  current(): Promise<SingleResponse<'user', IUser>> {
    const url = apiRoutes.apiSiteV1CurrentUserPath();
    return Fetcher.get(url);
  },
  changePassword(params: PasswordChangeFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.changePasswordApiSiteV1CurrentUserPath();
    return Fetcher.put(url, params);
  },
  passwordRecovery(params: PasswordRecoveryFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1UserPasswordResetPath();
    return Fetcher.post(url, params);
  },
  passwordReset(params: PasswordResetFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1UserPasswordResetPath();
    return Fetcher.put(url, params);
  },
  updateCurrentUser({ params }: UpdateCurrentUserParams): Promise<SingleResponse<'contact', IUser>> {
    const url = apiRoutes.apiSiteV1CurrentUserPath();
    return Fetcher.putFormData(url, params);
  },
  stopImpersonating(): Promise<AxiosResponse> {
    const url = apiRoutes.stopImpersonatingApiSiteV1AdminLoginUsersPath();
    return Fetcher.post(url, {});
  },
};

export default UsersRepository;
