export default {
  activity: 'Activity',
  goalFilterLabel: 'Goal',
  stateFilterLabel: 'Status',
  activityTypeFilterLabel: 'Activity Types',
  addActivity: 'Add new activity',
  exportButton: 'Export',
  completedActivityState: 'Completed',
  uncompletedActivityState: 'Mark as completed',
  reopenActivityState: 'Reopen',
  actions: {
    userName: 'User Name',
    activityName: 'Activity Name',
    activityTypeName: 'Activity Type Name',
    dueDate: 'Due Date',
    details: 'Details',
    goalName: 'Goal Name',
    delete: 'Delete',
    cancel: 'Cancel',
  },
  columnsHeads: {
    activity: 'Activity',
    contact: 'Contact',
    dueDate: 'Due date',
  },
};
