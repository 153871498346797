import { createSlice } from '@reduxjs/toolkit';

import { IContactRelation } from 'domain/contactRelation/types';
import { FetchStatus } from 'enums/FetchStatus';
import ContactRelationsRepository, { IndexParams } from 'repositories/ContactRelationsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type ContactRelationsSliceStateType = {
  contactRelations: IContactRelation[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ContactRelationsSliceActionsType = {
  loadContactRelationsList: (params: IndexParams) => {
    unwrap: () => void;
  };
};

const initialState: ContactRelationsSliceStateType = {
  contactRelations: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadContactRelationsList = createAsyncAction('contactRelations/index', ContactRelationsRepository.index);

const slice = createSlice({
  name: 'contactRelations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadContactRelationsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadContactRelationsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.contactRelations = payload.contactRelations;
    });
    builder.addCase(loadContactRelationsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});
export default slice.reducer;
