import {
  IAttorneyDetailReport,
  IAttorneyDetailReportUser,
  IAttorneyDetailReportUserProjectsTypeTimeSpentResponse,
  IAttorneyDetailReportUserStatsResponse,
} from 'domain/attorneyDetailReport/types';
import { ProjectDepartment } from 'domain/project/enums';
import { apiRoutes } from 'routes';
import { TableFilterQueryParams } from 'types/tableFilter';
import Fetcher from 'utils/fetcher';

export type IndexParams = {
  clientId: ID;
  queryParams?: TableFilterQueryParams;
};

export type AttorneyUserDetailReportUserParams = {
  clientId: ID;
  userId: ID;
  queryParams: TableFilterQueryParams;
  department: ProjectDepartment;
};

export type StatsParams = {
  clientId: ID;
  userId: ID;
  queryParams?: TableFilterQueryParams;
};

export type ProjectsTimeSpentParams = {
  clientId: ID;
  userId: ID;
  queryParams?: TableFilterQueryParams;
};

const AttorneyDetailReportRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<IAttorneyDetailReport> {
    const url = apiRoutes.apiSiteV1ClientReportsAttorneysDetailReportPath(clientId);

    return Fetcher.get(url, queryParams);
  },
  user({
    clientId,
    userId,
    queryParams,
    department,
  }: AttorneyUserDetailReportUserParams): Promise<CollectionResponse<'projects', IAttorneyDetailReportUser[]>> {
    const url = apiRoutes.projectsApiSiteV1ClientReportsUserAttorneyDetailReportsPath(clientId, userId);
    const params = {
      ...queryParams,
      q: {
        ...queryParams.q,
        departmentEq: department,
      },
    };

    return Fetcher.get(url, params);
  },
  stats({ clientId, userId, queryParams }: StatsParams): Promise<IAttorneyDetailReportUserStatsResponse> {
    const url = apiRoutes.projectsStatsApiSiteV1ClientReportsUserAttorneyDetailReportsPath(clientId, userId);
    return Fetcher.get(url, queryParams);
  },
  projectsTimeSpent({
    clientId,
    userId,
    queryParams,
  }: ProjectsTimeSpentParams): Promise<IAttorneyDetailReportUserProjectsTypeTimeSpentResponse> {
    const url = apiRoutes.projectsTypeTimeSpentApiSiteV1ClientReportsUserAttorneyDetailReportsPath(clientId, userId);

    return Fetcher.get(url, queryParams);
  },
};

export default AttorneyDetailReportRepository;
