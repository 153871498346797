import { either, is, isEmpty, isNil } from 'ramda';

const TRUE_STRING_VALUE = 'true';

export const isBlank = either(isEmpty, isNil);

export const isTrue = (value: string | boolean): boolean => {
  return value === true || value === TRUE_STRING_VALUE;
};

export const formatNumberToFixed = (number: string | number, count: number): number => {
  return Number(Number(number).toFixed(count));
};

export const stringifyObjectValues = (data: UnknownObject): UnknownObject => {
  const updatedData = Object.keys(data).reduce((acc, key) => {
    const isNestedValue = is(Object, acc[key]) && !is(Array, acc[key]);

    if (isNestedValue) {
      acc[key] = JSON.stringify(acc[key]);
    }

    return acc;
  }, data);

  return updatedData;
};

export const parseObjectValues = <T = UnknownObject>(data: T): T => {
  if (!data) {
    return data;
  }

  const updatedData = Object.keys(data).reduce((acc, key) => {
    try {
      const value = acc[key] as string;
      const parsedValue = JSON.parse(value);
      const isNestedValue = is(Object, parsedValue) && !is(Array, parsedValue);
      const currentValue = isNestedValue ? parsedValue : value;

      acc[key] = currentValue;
    } catch (e) {
      return acc;
    }

    return acc;
  }, data);

  return updatedData;
};
