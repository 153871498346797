export const download = (file: Blob, filename: string): void => {
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(file);
  link.download = filename;

  link.click();
};

export const downloadFile = async (url: string): Promise<void> => {
  try {
    const response = await fetch(url);
    const filename = response.headers.get('Content-Disposition').split('"')[1];
    const file = await response.blob();

    return download(file, filename);
  } catch (error) {
    return Promise.resolve(null);
  }
};
