import validations from './validations';
import signInForm from './signInForm';
import changePasswordForm from './changePasswordForm';
import passwordRecoveryForm from './passwordRecoveryForm';
import passwordResetForm from './passwordResetForm';

export default {
  validations,
  signInForm,
  changePasswordForm,
  passwordRecoveryForm,
  passwordResetForm,
};
