import { createApi } from '@reduxjs/toolkit/query/react';

import { EventCalendarView } from 'enums/EventCalendar';
import { baseQueryWithCamelize } from 'utils/fetcher';

export default createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithCamelize({ baseUrl: '/' }),
  endpoints: () => ({}),
  tagTypes: [EventCalendarView.list],
});
