import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IActivity } from 'domain/activity/types';
import { downloadFile } from 'utils/download';
import { decamelize } from 'utils/keysConverter';
import { ActivityFormDataToSubmit } from 'forms/activityForm';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type ActionParams = {
  clientId: ID;
  activityId: ID;
};

export type ShowParams = {
  clientId: ID;
  activityId: ID;
};

export type CompleteParams = {
  clientId: ID;
  activityId: ID;
  completedAt?: string;
};

export type OpenParams = {
  clientId: ID;
  activityId: ID;
};

export type CreateParams = {
  clientId: ID;
  params: ActivityFormDataToSubmit;
};

export type UpdateParams = {
  clientId: ID;
  activityId: ID;
  params: ActivityFormDataToSubmit;
};

const ActivitiesRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'activities', IActivity[]>> {
    const url = apiRoutes.apiSiteV1ClientActivitiesPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  delete({ clientId, activityId }: ActionParams): Promise<SingleResponse<'activity', IActivity>> {
    const url = apiRoutes.apiSiteV1ClientActivityPath(clientId, activityId);
    return Fetcher.delete(url);
  },
  complete({ clientId, activityId, completedAt }: CompleteParams): Promise<SingleResponse<'activity', IActivity>> {
    const url = apiRoutes.completeApiSiteV1ClientActivityPath(clientId, activityId);
    return Fetcher.put(url, { completedAt });
  },
  open({ clientId, activityId }: ActionParams): Promise<SingleResponse<'activity', IActivity>> {
    const url = apiRoutes.openApiSiteV1ClientActivityPath(clientId, activityId);
    return Fetcher.put(url, {});
  },
  async export({ clientId, queryParams }: IndexParams): Promise<void> {
    const url = apiRoutes.exportApiSiteV1ClientActivitiesPath(clientId, decamelize(queryParams));

    return downloadFile(url);
  },
  create({ clientId, params }: CreateParams): Promise<SingleResponse<'activity', IActivity>> {
    const url = apiRoutes.apiSiteV1ClientActivitiesPath(clientId);
    return Fetcher.postFormData(url, params);
  },
  update({ clientId, activityId, params }: UpdateParams): Promise<SingleResponse<'activity', IActivity>> {
    const url = apiRoutes.apiSiteV1ClientActivityPath(clientId, activityId);
    return Fetcher.putFormData(url, params);
  },
  show({ clientId, activityId }: ShowParams): Promise<SingleResponse<'activity', IActivity>> {
    const url = apiRoutes.apiSiteV1ClientActivityPath(clientId, activityId);
    return Fetcher.get(url);
  },
};

export default ActivitiesRepository;
