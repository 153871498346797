export default {
  departmentReports: 'Department Reports',
  firmOverviewReports: 'Firm Overview Reports',
  item: {
    link: 'Show the report',
  },
  detailedReport: 'Detailed report',
  total: 'Total',
  totalHours: 'Total Hours',
  member: 'Member',
  businessDevelopment: 'Business Development',
  marketing: 'Marketing',
  communication: 'Communication',
  communications: 'Communications',
  completed: 'Completed',
  outstanding: 'Outstanding',
  timeSpent: 'Time spent',
  noTeamText: 'No Teams found. Check your Team filter settings or Teams list for your account',
  export: 'Export',
  projectTypes: {
    proposal: 'Proposal',
    practice: 'Practice',
    attorneyCoaching: 'Attorney coaching',
    clientFeedback: 'Client feedback',
    mediaRelations: 'Media relation',
    nominations: 'Nominations',
    external: 'External',
    internal: 'Internal',
    events: 'Events',
    tickets: 'Tickets',
    sponsorships: 'Sponsorship',
    creative: 'Creative',
  },
  totalProjects: 'Total Projects',
  completedProjects: 'Completed Projects',
  outstandingProjects: 'Outstanding Projects',
  columns: {
    projectName: 'Project name',
    type: 'Type',
    dueDate: 'Due date',
    completed: 'Completed',
    attorneys: 'Attorneys',
    practiceGroup: 'Practice Group',
    teamMembers: 'Team Members',
    hours: 'Hours',
  },
  filters: {
    filterByAttorney: 'Filter by attorney',
    projectType: 'Project type',
    practiceGroupFilterLabel: 'Practice Group',
    teamMember: 'Team Member',
    dueDate: 'Due date',
    completedDate: 'Completed date',
  },
  noContentToDisplay: 'No content to display',
  subCategories: 'Sub-categories',
  departmentProductivityReport: {
    name: 'Department Productivity Report',
    description:
      'Take a broad look at your team’s overall productivity and efficiency over a specific period of time. ',
  },
  mbdTeamDetailReport: {
    name: 'MBD Team Detail Report',
    description:
      'See where each team member is spending their time by project or task type, practice group, attorney and more.',
  },
  projectNameDetail: {
    name: 'Project Name Detail Report',
    description: 'View a list of your team’s projects based on a set time frame.',
  },
  attorneyDetailReport: {
    name: 'Attorney Detail Report',
    description:
      'Determine which attorneys are utilizing your team members and how much time is being spent on projects and tasks.',
  },
  practiceGroupDetailReport: {
    name: 'Practice Group Detail Report',
    description:
      'Get a clear view of which practice groups are utilizing your team members and how much time is being spent on projects and tasks for them.',
  },
  companyDetailReport: {
    name: 'Company Detail Report',
    description:
      'Discover what clients and companies your team is focusing their time on acquiring, growing or servicing.',
  },
  projectTypeReport: {
    name: 'Project Type Report',
    description:
      'Filter by project type(s) for a broad view of progress and productivity over a certain period of time.',
  },
  winLossReport: {
    name: 'Win/Loss Report',
    description: 'Determine the success rate of your pitches, proposals and RFPs at a glance.',
  },
  awardsNominationsReport: {
    name: 'Awards, Nominations and Rankings Report',
    description:
      'See a summarization of your department’s time, efforts and results for firm and attorney awards and nominations.',
  },
};
