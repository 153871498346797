import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalLayout } from 'enums/ModalLayout';

export type Modal = {
  title?: string;
  Content: React.ElementType;
  Actions?: React.ReactNode;
  modalLayout?: ModalLayout;
  isActionsVisible?: boolean;
  props?: { onCancel?: () => void } & UnknownObject;
};

export type ModalsSliceStateType = {
  modals: Modal[];
};

export type ModalsSliceActionsType = {
  showModal: (modal: Modal) => void;
  hideModal: () => void;
  hideAllModals: () => void;
};

const initialState: ModalsSliceStateType = {
  modals: [],
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<Modal>) => {
      state.modals.push(payload);
    },
    hideModal: state => {
      state.modals.splice(-1);
    },
    hideAllModals: () => {
      return initialState;
    },
  },
});

const {
  actions: { showModal, hideModal, hideAllModals },
} = slice;

export { showModal, hideModal, hideAllModals };

export default slice.reducer;
