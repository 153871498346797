export default {
  currentWeek: 'Current week',
  currentMonth: 'Current month',
  pastWeek: 'Past week',
  pastMonth: 'Past month',
  upcomingActivities: {
    title: 'Upcoming activities',
    moreActivitiesLabel: 'more activities',
    showAllLabel: 'Show all activities',
    addButton: 'Add New Activity',
    noData: 'No activities',
  },
  rating: {
    title: 'Achievements',
    pointsLabel: 'points',
    levelLabel: 'Level',
    daysLabel: 'days',
    seeAllLabel: 'See all achievements',
    hallOfFame: 'Hall\nof\nFame',
  },
  leaderboard: {
    title: 'Leaderboard',
    showAllLabel: 'Show all',
    noUserMemberLabel: 'Former Member',
  },
  activityPointsChart: {
    title: 'Your activity points',
    yourPointsLabel: 'Your points',
    firmAverageLabel: 'Firm Average',
  },
};
