import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IMembership } from 'domain/membership/types';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

const MembersRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'memberships', IMembership[]>> {
    const url = apiRoutes.apiSiteV1ClientMembershipsPath(clientId);
    return Fetcher.get(url, queryParams);
  },
};

export default MembersRepository;
