import { createSlice } from '@reduxjs/toolkit';

import { IProject } from 'domain/project/types';
import { FetchStatus } from 'enums/FetchStatus';
import ProjectsRepository, {
  IndexParams,
  ShowParams,
  ActionParams,
  CreateParams,
  UpdateParams,
  CompleteParams,
} from 'repositories/ProjectsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type ProjectsSliceStateType = {
  projects: IProject[];
  project: IProject;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  meta: ResponseMeta;
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  complete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ProjectsSliceActionsType = {
  loadProjectsList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'projects', IProject[]>> };
  createProject: (params: CreateParams) => { unwrap: () => void };
  updateProject: (params: UpdateParams) => { unwrap: () => void };
  showProject: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<'project', IProject>> };
  resetProjects: () => void;
  resetProject: () => void;
  completeProject: (params: CompleteParams) => { unwrap: () => void };
  openProject: (params: ActionParams) => { unwrap: () => void };
  deleteProject: (params: ActionParams) => { unwrap: () => void };
};

const initialState: ProjectsSliceStateType = {
  projects: [],
  project: {} as IProject,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  complete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  meta: { perPage: 8 } as ResponseMeta,
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadProjectsList = createAsyncAction('projects/index', ProjectsRepository.index);
export const showProject = createAsyncAction('projects/show', ProjectsRepository.show);
export const completeProject = createAsyncAction('projects/complete', ProjectsRepository.complete);
export const openProject = createAsyncAction('projects/open', ProjectsRepository.open);
export const deleteProject = createAsyncAction('projects/delete', ProjectsRepository.delete);
export const createProject = createAsyncAction('projects/create', ProjectsRepository.create);
export const updateProject = createAsyncAction('projects/update', ProjectsRepository.update);

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetProjects: state => {
      state.projects = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
    resetProject: state => {
      state.project = {} as IProject;
      state.show.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadProjectsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadProjectsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.projects = payload.projects;
      state.meta = payload.meta;
    });
    builder.addCase(loadProjectsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showProject.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showProject.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.project = payload.project;
    });
    builder.addCase(showProject.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(deleteProject.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteProject.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteProject.rejected, state => {
      state.delete.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(completeProject.pending, state => {
      state.complete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(completeProject.fulfilled, state => {
      state.complete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(completeProject.rejected, state => {
      state.complete.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetProjects, resetProject },
} = slice;

export { resetProjects, resetProject };

export default slice.reducer;
