import qs from 'query-string';
import { pathOr } from 'ramda';
import { useMemo } from 'react';
import { useParams, useLocation, useNavigate, NavigateFunction, useMatch, matchPath } from 'react-router-dom';

import { isBlank } from 'utils/data';

export type LocationType = { hash: string; pathname: string; search: string; state: unknown };
export type UseRouterType = {
  push: NavigateFunction;
  replace: (path: string) => void;
  goBack: () => void;
  pathname: string;
  match: typeof useMatch;
  matchPath: typeof matchPath;
  location: LocationType;
  params: Record<string, string>;
  getUrlParam: (param?: string) => qs.ParsedQuery;
};

const useRouter = (): UseRouterType => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch;

  const getUrlParam = (param: string) => {
    const search = qs.parse(location.search, { arrayFormat: 'comma' });

    if (isBlank(param)) {
      return search;
    }

    return pathOr(null, [param], search);
  };

  const handleReplace = (path: string): void => {
    navigate(path, { replace: true });
  };

  const handleGoBack = (): void => {
    navigate(-1);
  };

  return useMemo(() => {
    return {
      push: navigate,
      replace: handleReplace,
      pathname: location.pathname,
      match,
      matchPath,
      location,
      goBack: handleGoBack,
      params,
      getUrlParam,
    };
  }, [params, match, location, navigate]);
};

export default useRouter;
