export default {
  goalLabel: 'Goal',
  typeLabel: 'Type',
  periodLabel: 'Period',
  startingDateLabel: 'Starting Date',
  dueDateLabel: 'Due Date',
  completedAtLabel: 'Completed At',
  contactLabel: 'Contact',
  detailsLabel: 'Additional Details',
  statusLabel: 'Status',
  timeSpentComment: 'spent on this activity',
  noDetailsLabel: 'No Additional Details have been added',
};
