export default {
  project: 'Project',
  departmentFilterLabel: 'Department',
  typeFilterLabel: 'Type',
  stateFilterLabel: 'Status',
  assignedToFilterLabel: 'Assigned to',
  dueDateFilterLabel: 'Due date',
  deleteModalTitle: 'Are you sure want to delete this?',
  addProject: 'Add new project',
  completionDate: {
    filterLabel: 'Completed date',
    openedLabel: 'opened',
  },
  actions: {
    details: 'See details',
    complete: 'Complete',
    open: 'Open',
    edit: 'Edit',
    delete: 'Delete',
    copy: 'Copy',
    todos: 'ToDos',
    addToCalendar: 'Add to calendar',
    removeFromCalendar: 'Remove from calendar',
    addedToCalendar: 'Added to Calendar',
    cancel: 'Cancel',
    completeError: 'You cannot change status',
  },
  state: {
    completedLabel: 'Completed',
    openedLabel: 'Opened',
  },
  columnsHeads: {
    department: 'Department',
    project: 'Project',
    dueDate: 'Due date',
    assignedTo: 'Assigned to',
    status: '% Complete',
    attorney: 'Attorney',
    todo: 'ToDo',
  },
};
