import { createSlice } from '@reduxjs/toolkit';

import { IUser } from 'domain/user/types';
import { FetchStatus } from 'enums/FetchStatus';
import UsersRepository, { IndexParams, ShowParams } from 'repositories/Client/UsersRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type UsersSliceStateType = {
  users: IUser[];
  user: IUser;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type UsersSliceActionsType = {
  loadUsersList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'users', IUser[]>> };
  showUser: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<'user', IUser>> };
  resetUsers: () => void;
  resetUser: () => void;
};

const initialState: UsersSliceStateType = {
  users: [],
  user: {} as IUser,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadUsersList = createAsyncAction('users/index', UsersRepository.index);
export const showUser = createAsyncAction('users/show', UsersRepository.show);

const slice = createSlice({
  name: 'clientUsers',
  initialState,
  reducers: {
    resetUsers: state => {
      state.users = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
    resetUser: state => {
      state.user = {} as IUser;
      state.show.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadUsersList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadUsersList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.users = payload.users;
    });
    builder.addCase(loadUsersList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showUser.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showUser.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.user = payload.user;
    });
    builder.addCase(showUser.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetUsers, resetUser },
} = slice;

export { resetUsers, resetUser };

export default slice.reducer;
