import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';

const baseOptions = createTheme({
  palette,
  typography,
});

export default baseOptions;
