import {
  UsersSliceActionsType,
  UsersSliceStateType,
  loadCurrentUser,
  reloadCurrentUser,
  updateCurrentUser,
  stopImpersonating,
} from 'store/usersSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';
import { UpdateCurrentUserParams } from 'repositories/UsersRepository';

type UseUsersType = Pick<UsersSliceStateType, 'currentUser'> &
  UsersSliceActionsType & {
    isCurrentUserLoadFinished: boolean;
  };

const useUsers = (): UseUsersType => {
  const dispatch = useAppDispatch();
  const {
    currentUser,
    current: { fetchStatus: currentFetchStatus },
  } = useAppSelector(state => state.users);

  const isCurrentUserLoadFinished = getFetchStatus(currentFetchStatus).isFinished;

  return {
    currentUser,
    isCurrentUserLoadFinished,
    loadCurrentUser: () => dispatch(loadCurrentUser()),
    reloadCurrentUser: () => dispatch(reloadCurrentUser()),
    updateCurrentUser: (params: UpdateCurrentUserParams) => dispatch(updateCurrentUser(params)),
    stopImpersonating: () => dispatch(stopImpersonating()),
  };
};

export default useUsers;
