export enum ProjectDepartment {
  businessDevelopment = 'business_development',
  communications = 'communications',
  marketing = 'marketing',
}

export enum ProjectType {
  proposal = 'proposal',
  practiceIndustry = 'practice',
  attorneyCoaching = 'attorney_coaching',
  clientFeedback = 'client_feedback',
  mediaRelations = 'media_relations',
  nominations = 'nominations',
  external = 'external',
  internal = 'internal',
  events = 'events',
  tickets = 'tickets',
  sponsorships = 'sponsorships',
  creative = 'creative',
}

export const enum ProjectState {
  opened = 'opened',
  completed = 'completed',
}

export enum ProjectApprovalValue {
  yes = 'yes',
  no = 'no',
  notApplicable = 'not_applicable',
}

export enum ProjectWinLossValue {
  won = 'won',
  inProgress = 'in_progress',
  passed = 'passed',
  notApplicable = 'not_applicable',
  lost = 'lost',
  unknown = 'unknown',
  unsure = 'unsure',
}

export enum ProjectOutcomeValue {
  received = 'received',
  notReceived = 'not_received',
  outstanding = 'outstanding',
}
