/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /admin/sidekiq */
export function sidekiqWebPath(options?: object): string {
  return "/" + "admin" + "/" + "sidekiq" + $buildOptions(options, []);
}

/** /letter_opener */
export function letterOpenerWebPath(options?: object): string {
  return "/" + "letter_opener" + $buildOptions(options, []);
}

/** /letter_opener/ */
export function letterOpenerWebLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + $buildOptions(options, []);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /letter_opener/:id(/:style)(.:format) */
export function letterOpenerWebLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + ($hasPresentOwnProperty(options, "style") ? "/" + (options as any).style : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","style","format"]);
}

/** /letter_opener/:id/delete(.:format) */
export function letterOpenerWebDeleteLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + "/" + "delete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/gamifications/levels(.:format) */
export function apiSiteV1AdminGamificationsLevelsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "gamifications" + "/" + "levels" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/gamifications/levels/:id(.:format) */
export function apiSiteV1AdminGamificationsLevelPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "gamifications" + "/" + "levels" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/activities/export(.:format) */
export function exportApiSiteV1AdminClientReportsActivitiesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "activities" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/activities(.:format) */
export function apiSiteV1AdminClientReportsActivitiesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "activities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/memberships_score_events/export(.:format) */
export function exportApiSiteV1AdminClientReportsMembershipsScoreEventsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "memberships_score_events" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/memberships_score_events(.:format) */
export function apiSiteV1AdminClientReportsMembershipsScoreEventsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "memberships_score_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/score_events_by_memberships/export(.:format) */
export function exportApiSiteV1AdminClientReportsScoreEventsByMembershipsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "score_events_by_memberships" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/score_events_by_memberships(.:format) */
export function apiSiteV1AdminClientReportsScoreEventsByMembershipsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "score_events_by_memberships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/memberships/export(.:format) */
export function exportApiSiteV1AdminClientReportsMembershipsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "memberships" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/reports/memberships(.:format) */
export function apiSiteV1AdminClientReportsMembershipsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "memberships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/users/bulk_import(.:format) */
export function bulkImportApiSiteV1AdminClientUsersPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "users" + "/" + "bulk_import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/industries/bulk_import(.:format) */
export function bulkImportApiSiteV1AdminClientIndustriesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "industries" + "/" + "bulk_import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/companies/bulk_import(.:format) */
export function bulkImportApiSiteV1AdminClientCompaniesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "companies" + "/" + "bulk_import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/practice_groups/bulk_import(.:format) */
export function bulkImportApiSiteV1AdminClientPracticeGroupsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "practice_groups" + "/" + "bulk_import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/offices/bulk_import(.:format) */
export function bulkImportApiSiteV1AdminClientOfficesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "offices" + "/" + "bulk_import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/badges(.:format) */
export function apiSiteV1AdminClientBadgesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "badges" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/admin/clients/:client_id/badges/:id(.:format) */
export function apiSiteV1AdminClientBadgePath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + client_id + "/" + "badges" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/admin/clients(.:format) */
export function apiSiteV1AdminClientsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/clients/:id(.:format) */
export function apiSiteV1AdminClientPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "clients" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/users(.:format) */
export function apiSiteV1AdminUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/login_users/:id/impersonate(.:format) */
export function impersonateApiSiteV1AdminLoginUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "login_users" + "/" + id + "/" + "impersonate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/login_users/stop_impersonating(.:format) */
export function stopImpersonatingApiSiteV1AdminLoginUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "login_users" + "/" + "stop_impersonating" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/login_users/:id/restore(.:format) */
export function restoreApiSiteV1AdminLoginUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "login_users" + "/" + id + "/" + "restore" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/login_users(.:format) */
export function apiSiteV1AdminLoginUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "login_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/login_users/:id(.:format) */
export function apiSiteV1AdminLoginUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "login_users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/filter_login_users(.:format) */
export function apiSiteV1AdminFilterLoginUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "filter_login_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/filter_login_users/:id(.:format) */
export function apiSiteV1AdminFilterLoginUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "filter_login_users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/non_login_users/:id/restore(.:format) */
export function restoreApiSiteV1AdminNonLoginUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "non_login_users" + "/" + id + "/" + "restore" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/non_login_users(.:format) */
export function apiSiteV1AdminNonLoginUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "non_login_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/non_login_users/:id(.:format) */
export function apiSiteV1AdminNonLoginUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "non_login_users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/user_clients/:user_client_id/badges/grouped(.:format) */
export function groupedApiSiteV1AdminUserClientBadgesPath(user_client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_clients" + "/" + user_client_id + "/" + "badges" + "/" + "grouped" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_client_id","format"]);
}

/** /api/site/v1/admin/user_clients/:user_client_id/badges(.:format) */
export function apiSiteV1AdminUserClientBadgesPath(user_client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_clients" + "/" + user_client_id + "/" + "badges" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_client_id","format"]);
}

/** /api/site/v1/admin/user_clients/:user_client_id/badges/:id(.:format) */
export function apiSiteV1AdminUserClientBadgePath(user_client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_clients" + "/" + user_client_id + "/" + "badges" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_client_id","id","format"]);
}

/** /api/site/v1/admin/user_clients/:user_client_id/progress_histories(.:format) */
export function apiSiteV1AdminUserClientProgressHistoriesPath(user_client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_clients" + "/" + user_client_id + "/" + "progress_histories" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_client_id","format"]);
}

/** /api/site/v1/admin/user_clients/:user_client_id/progress_histories/:id(.:format) */
export function apiSiteV1AdminUserClientProgressHistoryPath(user_client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_clients" + "/" + user_client_id + "/" + "progress_histories" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_client_id","id","format"]);
}

/** /api/site/v1/admin/user_clients(.:format) */
export function apiSiteV1AdminUserClientsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_clients" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/user_clients/:id(.:format) */
export function apiSiteV1AdminUserClientPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_clients" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/industries(.:format) */
export function apiSiteV1AdminIndustriesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "industries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/industries/:id(.:format) */
export function apiSiteV1AdminIndustryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "industries" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/companies(.:format) */
export function apiSiteV1AdminCompaniesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/companies/:id(.:format) */
export function apiSiteV1AdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/contact_relations(.:format) */
export function apiSiteV1AdminContactRelationsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "contact_relations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/contact_relations/:id(.:format) */
export function apiSiteV1AdminContactRelationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "contact_relations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/contacts/export(.:format) */
export function exportApiSiteV1AdminContactsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "contacts" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/contacts(.:format) */
export function apiSiteV1AdminContactsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "contacts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/contacts/:id(.:format) */
export function apiSiteV1AdminContactPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "contacts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/user_contacts(.:format) */
export function apiSiteV1AdminUserContactsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_contacts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/user_contacts/:id(.:format) */
export function apiSiteV1AdminUserContactPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_contacts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/score_events(.:format) */
export function apiSiteV1AdminScoreEventsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "score_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/score_events/:id(.:format) */
export function apiSiteV1AdminScoreEventPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "score_events" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/goals/:id/complete(.:format) */
export function completeApiSiteV1AdminGoalPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "goals" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/goals/:id/open(.:format) */
export function openApiSiteV1AdminGoalPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "goals" + "/" + id + "/" + "open" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/goals(.:format) */
export function apiSiteV1AdminGoalsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "goals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/goals/:id(.:format) */
export function apiSiteV1AdminGoalPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "goals" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/activity_types(.:format) */
export function apiSiteV1AdminActivityTypesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "activity_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/activity_types/:id(.:format) */
export function apiSiteV1AdminActivityTypePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "activity_types" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/activities/:id/complete(.:format) */
export function completeApiSiteV1AdminActivityPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "activities" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/activities/:id/open(.:format) */
export function openApiSiteV1AdminActivityPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "activities" + "/" + id + "/" + "open" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/activities(.:format) */
export function apiSiteV1AdminActivitiesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "activities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/activities/:id(.:format) */
export function apiSiteV1AdminActivityPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "activities" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/practice_groups(.:format) */
export function apiSiteV1AdminPracticeGroupsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "practice_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/practice_groups/:id(.:format) */
export function apiSiteV1AdminPracticeGroupPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "practice_groups" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/teams(.:format) */
export function apiSiteV1AdminTeamsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "teams" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/teams/:id(.:format) */
export function apiSiteV1AdminTeamPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "teams" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/memberships(.:format) */
export function apiSiteV1AdminMembershipsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "memberships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/memberships/:id(.:format) */
export function apiSiteV1AdminMembershipPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "memberships" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/projects/:id/complete(.:format) */
export function completeApiSiteV1AdminProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "projects" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/projects/:id/open(.:format) */
export function openApiSiteV1AdminProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "projects" + "/" + id + "/" + "open" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/projects(.:format) */
export function apiSiteV1AdminProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/projects/:id(.:format) */
export function apiSiteV1AdminProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "projects" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/task_templates/:task_template_id/task_template_items/bulk_update(.:format) */
export function bulkUpdateApiSiteV1AdminTaskTemplateTaskTemplateItemsPath(task_template_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "task_templates" + "/" + task_template_id + "/" + "task_template_items" + "/" + "bulk_update" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["task_template_id","format"]);
}

/** /api/site/v1/admin/task_templates(.:format) */
export function apiSiteV1AdminTaskTemplatesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "task_templates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/task_templates/:id(.:format) */
export function apiSiteV1AdminTaskTemplatePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "task_templates" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/task_template_items(.:format) */
export function apiSiteV1AdminTaskTemplateItemsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "task_template_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/task_template_items/:id(.:format) */
export function apiSiteV1AdminTaskTemplateItemPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "task_template_items" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/offices(.:format) */
export function apiSiteV1AdminOfficesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "offices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/offices/:id(.:format) */
export function apiSiteV1AdminOfficePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "offices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/tasks/:id/complete(.:format) */
export function completeApiSiteV1AdminTaskPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "tasks" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/tasks/:id/open(.:format) */
export function openApiSiteV1AdminTaskPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "tasks" + "/" + id + "/" + "open" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/tasks(.:format) */
export function apiSiteV1AdminTasksPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/tasks/:id(.:format) */
export function apiSiteV1AdminTaskPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "tasks" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/purposes(.:format) */
export function apiSiteV1AdminPurposesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "purposes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/purposes/:id(.:format) */
export function apiSiteV1AdminPurposePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "purposes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/audits(.:format) */
export function apiSiteV1AdminAuditsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "audits" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/audits/:id(.:format) */
export function apiSiteV1AdminAuditPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "audits" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/project_notes(.:format) */
export function apiSiteV1AdminProjectNotesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "project_notes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/project_notes/:id(.:format) */
export function apiSiteV1AdminProjectNotePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "project_notes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/project_users(.:format) */
export function apiSiteV1AdminProjectUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "project_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/project_users/:id(.:format) */
export function apiSiteV1AdminProjectUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "project_users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/notifications(.:format) */
export function apiSiteV1AdminNotificationsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "notifications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/notifications/:id(.:format) */
export function apiSiteV1AdminNotificationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "notifications" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/user_client_notifications/:id/mark_as_read(.:format) */
export function markAsReadApiSiteV1AdminUserClientNotificationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_client_notifications" + "/" + id + "/" + "mark_as_read" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/user_client_notifications/:id/mark_as_unread(.:format) */
export function markAsUnreadApiSiteV1AdminUserClientNotificationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_client_notifications" + "/" + id + "/" + "mark_as_unread" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/user_client_notifications(.:format) */
export function apiSiteV1AdminUserClientNotificationsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_client_notifications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/user_client_notifications/:id(.:format) */
export function apiSiteV1AdminUserClientNotificationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "user_client_notifications" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/calendar_events/calendar_events_with_recurrence(.:format) */
export function calendarEventsWithRecurrenceApiSiteV1AdminCalendarEventsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "calendar_events" + "/" + "calendar_events_with_recurrence" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/calendar_events/:calendar_event_id/recurrence_periods/:id(.:format) */
export function apiSiteV1AdminCalendarEventRecurrencePeriodPath(calendar_event_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "calendar_events" + "/" + calendar_event_id + "/" + "recurrence_periods" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["calendar_event_id","id","format"]);
}

/** /api/site/v1/admin/calendar_events(.:format) */
export function apiSiteV1AdminCalendarEventsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "calendar_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/calendar_events/:id(.:format) */
export function apiSiteV1AdminCalendarEventPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "calendar_events" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/marketing_roles(.:format) */
export function apiSiteV1AdminMarketingRolesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "marketing_roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/marketing_roles/:id(.:format) */
export function apiSiteV1AdminMarketingRolePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "marketing_roles" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/admin/publications(.:format) */
export function apiSiteV1AdminPublicationsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "publications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/publications/:id(.:format) */
export function apiSiteV1AdminPublicationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "publications" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/clients/:client_id/industries(.:format) */
export function apiSiteV1ClientIndustriesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "industries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/companies/:company_id/activities/export(.:format) */
export function exportApiSiteV1ClientCompanyActivitiesPath(client_id: ScalarType, company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "companies" + "/" + company_id + "/" + "activities" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","company_id","format"]);
}

/** /api/site/v1/clients/:client_id/companies(.:format) */
export function apiSiteV1ClientCompaniesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/companies/:id(.:format) */
export function apiSiteV1ClientCompanyPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/contact_relations(.:format) */
export function apiSiteV1ClientContactRelationsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "contact_relations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/user_contacts(.:format) */
export function apiSiteV1ClientUserContactsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "user_contacts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/user_contacts/:id(.:format) */
export function apiSiteV1ClientUserContactPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "user_contacts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/contacts/:contact_id/activities/export(.:format) */
export function exportApiSiteV1ClientContactActivitiesPath(client_id: ScalarType, contact_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "contacts" + "/" + contact_id + "/" + "activities" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","contact_id","format"]);
}

/** /api/site/v1/clients/:client_id/contacts/:contact_id/users(.:format) */
export function apiSiteV1ClientContactUsersPath(client_id: ScalarType, contact_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "contacts" + "/" + contact_id + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","contact_id","format"]);
}

/** /api/site/v1/clients/:client_id/contacts/export(.:format) */
export function exportApiSiteV1ClientContactsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "contacts" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/contacts/bulk_import(.:format) */
export function bulkImportApiSiteV1ClientContactsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "contacts" + "/" + "bulk_import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/contacts(.:format) */
export function apiSiteV1ClientContactsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "contacts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/contacts/:id(.:format) */
export function apiSiteV1ClientContactPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "contacts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/goals/:id/complete(.:format) */
export function completeApiSiteV1ClientGoalPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "goals" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/goals/export(.:format) */
export function exportApiSiteV1ClientGoalsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "goals" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/goals(.:format) */
export function apiSiteV1ClientGoalsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "goals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/goals/:id(.:format) */
export function apiSiteV1ClientGoalPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "goals" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/activity_types(.:format) */
export function apiSiteV1ClientActivityTypesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "activity_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/activities/:id/complete(.:format) */
export function completeApiSiteV1ClientActivityPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "activities" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/activities/:id/open(.:format) */
export function openApiSiteV1ClientActivityPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "activities" + "/" + id + "/" + "open" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/activities/export(.:format) */
export function exportApiSiteV1ClientActivitiesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "activities" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/activities(.:format) */
export function apiSiteV1ClientActivitiesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "activities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/activities/:id(.:format) */
export function apiSiteV1ClientActivityPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "activities" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/practice_groups(.:format) */
export function apiSiteV1ClientPracticeGroupsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "practice_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/practice_groups/:id(.:format) */
export function apiSiteV1ClientPracticeGroupPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "practice_groups" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/users(.:format) */
export function apiSiteV1ClientUsersPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/users/:id(.:format) */
export function apiSiteV1ClientUserPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/teams/:team_id/memberships(.:format) */
export function apiSiteV1ClientTeamMembershipsPath(client_id: ScalarType, team_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "teams" + "/" + team_id + "/" + "memberships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","team_id","format"]);
}

/** /api/site/v1/clients/:client_id/teams/:team_id/memberships/:id(.:format) */
export function apiSiteV1ClientTeamMembershipPath(client_id: ScalarType, team_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "teams" + "/" + team_id + "/" + "memberships" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","team_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/teams/:team_id/score_events/daily_grouped(.:format) */
export function dailyGroupedApiSiteV1ClientTeamScoreEventsPath(client_id: ScalarType, team_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "teams" + "/" + team_id + "/" + "score_events" + "/" + "daily_grouped" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","team_id","format"]);
}

/** /api/site/v1/clients/:client_id/teams/:team_id/score_events(.:format) */
export function apiSiteV1ClientTeamScoreEventsPath(client_id: ScalarType, team_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "teams" + "/" + team_id + "/" + "score_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","team_id","format"]);
}

/** /api/site/v1/clients/:client_id/teams(.:format) */
export function apiSiteV1ClientTeamsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "teams" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/teams/:id(.:format) */
export function apiSiteV1ClientTeamPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "teams" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/memberships(.:format) */
export function apiSiteV1ClientMembershipsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "memberships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:id/complete(.:format) */
export function completeApiSiteV1ClientProjectPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:id/open(.:format) */
export function openApiSiteV1ClientProjectPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + id + "/" + "open" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/unique_type_of_awards(.:format) */
export function uniqueTypeOfAwardsApiSiteV1ClientProjectsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + "unique_type_of_awards" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:project_id/audits(.:format) */
export function apiSiteV1ClientProjectAuditsPath(client_id: ScalarType, project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + project_id + "/" + "audits" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","project_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:project_id/audits/:id(.:format) */
export function apiSiteV1ClientProjectAuditPath(client_id: ScalarType, project_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + project_id + "/" + "audits" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","project_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:project_id/project_notes(.:format) */
export function apiSiteV1ClientProjectProjectNotesPath(client_id: ScalarType, project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + project_id + "/" + "project_notes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","project_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:project_id/project_notes/:id(.:format) */
export function apiSiteV1ClientProjectProjectNotePath(client_id: ScalarType, project_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + project_id + "/" + "project_notes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","project_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:project_id/tasks/bulk_complete(.:format) */
export function bulkCompleteApiSiteV1ClientProjectTasksPath(client_id: ScalarType, project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + project_id + "/" + "tasks" + "/" + "bulk_complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","project_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:project_id/project_users/bulk_update(.:format) */
export function bulkUpdateApiSiteV1ClientProjectProjectUsersPath(client_id: ScalarType, project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + project_id + "/" + "project_users" + "/" + "bulk_update" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","project_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:project_id/project_users(.:format) */
export function apiSiteV1ClientProjectProjectUsersPath(client_id: ScalarType, project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + project_id + "/" + "project_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","project_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects(.:format) */
export function apiSiteV1ClientProjectsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/projects/:id(.:format) */
export function apiSiteV1ClientProjectPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "projects" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/global_search(.:format) */
export function apiSiteV1ClientGlobalSearchIndexPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "global_search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/department_productivity_report/monthly_grouped(.:format) */
export function monthlyGroupedApiSiteV1ClientReportsDepartmentProductivityReportIndexPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "department_productivity_report" + "/" + "monthly_grouped" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/department_productivity_report(.:format) */
export function apiSiteV1ClientReportsDepartmentProductivityReportIndexPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "department_productivity_report" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/projects_details_report/stats(.:format) */
export function statsApiSiteV1ClientReportsProjectsDetailsReportIndexPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "projects_details_report" + "/" + "stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/projects_details_report(.:format) */
export function apiSiteV1ClientReportsProjectsDetailsReportIndexPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "projects_details_report" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/project_type_report/projects(.:format) */
export function projectsApiSiteV1ClientReportsProjectTypeReportIndexPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "project_type_report" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/project_type_report/stats(.:format) */
export function statsApiSiteV1ClientReportsProjectTypeReportIndexPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "project_type_report" + "/" + "stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/practice_groups/:practice_group_id/detailed_practice_group_reports/projects(.:format) */
export function projectsApiSiteV1ClientReportsPracticeGroupDetailedPracticeGroupReportsPath(client_id: ScalarType, practice_group_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "practice_groups" + "/" + practice_group_id + "/" + "detailed_practice_group_reports" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","practice_group_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/practice_groups/:practice_group_id/detailed_practice_group_reports/project_stats(.:format) */
export function projectStatsApiSiteV1ClientReportsPracticeGroupDetailedPracticeGroupReportsPath(client_id: ScalarType, practice_group_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "practice_groups" + "/" + practice_group_id + "/" + "detailed_practice_group_reports" + "/" + "project_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","practice_group_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/mbd_teams_detail_report(.:format) */
export function apiSiteV1ClientReportsMbdTeamsDetailReportPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "mbd_teams_detail_report" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/attorneys_detail_report(.:format) */
export function apiSiteV1ClientReportsAttorneysDetailReportPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "attorneys_detail_report" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/practice_group_report(.:format) */
export function apiSiteV1ClientReportsPracticeGroupReportPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "practice_group_report" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/companies_report(.:format) */
export function apiSiteV1ClientReportsCompaniesReportPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "companies_report" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/win_loss_reports/projects(.:format) */
export function projectsApiSiteV1ClientReportsWinLossReportsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "win_loss_reports" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/win_loss_reports/stats(.:format) */
export function statsApiSiteV1ClientReportsWinLossReportsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "win_loss_reports" + "/" + "stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/awards_nominations_reports/projects(.:format) */
export function projectsApiSiteV1ClientReportsAwardsNominationsReportsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "awards_nominations_reports" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/awards_nominations_reports/stats(.:format) */
export function statsApiSiteV1ClientReportsAwardsNominationsReportsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "awards_nominations_reports" + "/" + "stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/users/:user_id/mbd_teams_detail_report/projects_stats(.:format) */
export function projectsStatsApiSiteV1ClientReportsUserMbdTeamsDetailReportIndexPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "users" + "/" + user_id + "/" + "mbd_teams_detail_report" + "/" + "projects_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/users/:user_id/mbd_teams_detail_report/users(.:format) */
export function usersApiSiteV1ClientReportsUserMbdTeamsDetailReportIndexPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "users" + "/" + user_id + "/" + "mbd_teams_detail_report" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/users/:user_id/mbd_teams_detail_report(.:format) */
export function apiSiteV1ClientReportsUserMbdTeamsDetailReportIndexPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "users" + "/" + user_id + "/" + "mbd_teams_detail_report" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/users/:user_id/attorney_detail_reports/projects(.:format) */
export function projectsApiSiteV1ClientReportsUserAttorneyDetailReportsPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "users" + "/" + user_id + "/" + "attorney_detail_reports" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/users/:user_id/attorney_detail_reports/projects_stats(.:format) */
export function projectsStatsApiSiteV1ClientReportsUserAttorneyDetailReportsPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "users" + "/" + user_id + "/" + "attorney_detail_reports" + "/" + "projects_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/users/:user_id/attorney_detail_reports/projects_type_time_spent(.:format) */
export function projectsTypeTimeSpentApiSiteV1ClientReportsUserAttorneyDetailReportsPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "users" + "/" + user_id + "/" + "attorney_detail_reports" + "/" + "projects_type_time_spent" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/companies/:company_id/detailed_company_reports/projects(.:format) */
export function projectsApiSiteV1ClientReportsCompanyDetailedCompanyReportsPath(client_id: ScalarType, company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "companies" + "/" + company_id + "/" + "detailed_company_reports" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","company_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/companies/:company_id/detailed_company_reports/projects_stats(.:format) */
export function projectsStatsApiSiteV1ClientReportsCompanyDetailedCompanyReportsPath(client_id: ScalarType, company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "companies" + "/" + company_id + "/" + "detailed_company_reports" + "/" + "projects_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","company_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/companies/:company_id/detailed_company_reports/projects_type_time_spent(.:format) */
export function projectsTypeTimeSpentApiSiteV1ClientReportsCompanyDetailedCompanyReportsPath(client_id: ScalarType, company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + "companies" + "/" + company_id + "/" + "detailed_company_reports" + "/" + "projects_type_time_spent" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","company_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/:id/download(.:format) */
export function downloadApiSiteV1ClientReportPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + id + "/" + "download" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/reports(.:format) */
export function apiSiteV1ClientReportsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/reports/:id(.:format) */
export function apiSiteV1ClientReportPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "reports" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/dashboard/teams_total_points(.:format) */
export function teamsTotalPointsApiSiteV1ClientDashboardPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "dashboard" + "/" + "teams_total_points" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/dashboard/team_members_total_points(.:format) */
export function teamMembersTotalPointsApiSiteV1ClientDashboardPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "dashboard" + "/" + "team_members_total_points" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/dashboard/personal_daily_score_events(.:format) */
export function personalDailyScoreEventsApiSiteV1ClientDashboardPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "dashboard" + "/" + "personal_daily_score_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/dashboard/average_daily_client_score_events(.:format) */
export function averageDailyClientScoreEventsApiSiteV1ClientDashboardPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "dashboard" + "/" + "average_daily_client_score_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/dashboard/monthly_projects_reports(.:format) */
export function monthlyProjectsReportsApiSiteV1ClientDashboardPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "dashboard" + "/" + "monthly_projects_reports" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/task_templates/:task_template_id/task_template_items(.:format) */
export function apiSiteV1ClientTaskTemplateTaskTemplateItemsPath(client_id: ScalarType, task_template_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "task_templates" + "/" + task_template_id + "/" + "task_template_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","task_template_id","format"]);
}

/** /api/site/v1/clients/:client_id/task_templates(.:format) */
export function apiSiteV1ClientTaskTemplatesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "task_templates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/offices(.:format) */
export function apiSiteV1ClientOfficesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "offices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/tasks/:id/complete(.:format) */
export function completeApiSiteV1ClientTaskPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "tasks" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/tasks/:id/open(.:format) */
export function openApiSiteV1ClientTaskPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "tasks" + "/" + id + "/" + "open" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/tasks(.:format) */
export function apiSiteV1ClientTasksPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/tasks/:id(.:format) */
export function apiSiteV1ClientTaskPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "tasks" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/purposes(.:format) */
export function apiSiteV1ClientPurposesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "purposes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/notifications(.:format) */
export function apiSiteV1ClientNotificationsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "notifications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/notifications/:id(.:format) */
export function apiSiteV1ClientNotificationPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "notifications" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/user_client_notifications/:id/mark_as_read(.:format) */
export function markAsReadApiSiteV1ClientUserClientNotificationPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "user_client_notifications" + "/" + id + "/" + "mark_as_read" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/user_client_notifications/:id/mark_as_unread(.:format) */
export function markAsUnreadApiSiteV1ClientUserClientNotificationPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "user_client_notifications" + "/" + id + "/" + "mark_as_unread" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/user_client_notifications(.:format) */
export function apiSiteV1ClientUserClientNotificationsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "user_client_notifications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/user_client_notifications/:id(.:format) */
export function apiSiteV1ClientUserClientNotificationPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "user_client_notifications" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/users/:user_id/badges/grouped(.:format) */
export function groupedApiSiteV1ClientUserBadgesPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "users" + "/" + user_id + "/" + "badges" + "/" + "grouped" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/users/:user_id/badges(.:format) */
export function apiSiteV1ClientUserBadgesPath(client_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "users" + "/" + user_id + "/" + "badges" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","user_id","format"]);
}

/** /api/site/v1/clients/:client_id/calendar_events/calendar_events_with_recurrence(.:format) */
export function calendarEventsWithRecurrenceApiSiteV1ClientCalendarEventsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "calendar_events" + "/" + "calendar_events_with_recurrence" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/calendar_events/:calendar_event_id/recurrence_periods/:id(.:format) */
export function apiSiteV1ClientCalendarEventRecurrencePeriodPath(client_id: ScalarType, calendar_event_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "calendar_events" + "/" + calendar_event_id + "/" + "recurrence_periods" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","calendar_event_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/calendar_events(.:format) */
export function apiSiteV1ClientCalendarEventsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "calendar_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/calendar_events/:id(.:format) */
export function apiSiteV1ClientCalendarEventPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "calendar_events" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/marketing_roles(.:format) */
export function apiSiteV1ClientMarketingRolesPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "marketing_roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/filter_users(.:format) */
export function apiSiteV1ClientFilterUsersPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "filter_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients/:client_id/filter_users/:id(.:format) */
export function apiSiteV1ClientFilterUserPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "filter_users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","id","format"]);
}

/** /api/site/v1/clients/:client_id/publications(.:format) */
export function apiSiteV1ClientPublicationsPath(client_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + client_id + "/" + "publications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["client_id","format"]);
}

/** /api/site/v1/clients(.:format) */
export function apiSiteV1ClientsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/clients/new(.:format) */
export function newApiSiteV1ClientPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/clients/:id/edit(.:format) */
export function editApiSiteV1ClientPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/clients/:id(.:format) */
export function apiSiteV1ClientPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "clients" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/site/v1/session(.:format) */
export function apiSiteV1SessionPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "session" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/current_user/change_password(.:format) */
export function changePasswordApiSiteV1CurrentUserPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "current_user" + "/" + "change_password" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/current_user(.:format) */
export function apiSiteV1CurrentUserPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "current_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/user/password_reset(.:format) */
export function apiSiteV1UserPasswordResetPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "user" + "/" + "password_reset" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/sso/saml/login(.:format) */
export function loginApiSiteV1SsoSamlPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "sso" + "/" + "saml" + "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/sso/saml/consume(.:format) */
export function consumeApiSiteV1SsoSamlPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "sso" + "/" + "saml" + "/" + "consume" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/sso/saml/metadata(.:format) */
export function metadataApiSiteV1SsoSamlPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "sso" + "/" + "saml" + "/" + "metadata" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format) */
export function railsConductorInboundEmailIncineratePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "incinerate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
