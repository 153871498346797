import { createSlice } from '@reduxjs/toolkit';

import { IPurpose } from 'domain/purpose/types';
import { FetchStatus } from 'enums/FetchStatus';
import PurposesRepository, { IndexParams } from 'repositories/PurposesRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type PurposesSliceStateType = {
  purposes: IPurpose[];
  meta: ResponseMeta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type PurposesSliceActionsType = {
  loadPurposesList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'purposes', IPurpose[]>> };
  resetPurposes: () => void;
};

const initialState: PurposesSliceStateType = {
  purposes: [],
  meta: { perPage: 8 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadPurposesList = createAsyncAction('purposes/index', PurposesRepository.index);

const slice = createSlice({
  name: 'purposes',
  initialState,
  reducers: {
    resetPurposes: state => {
      state.purposes = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadPurposesList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadPurposesList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.purposes = payload.purposes;
      state.meta = payload.meta;
    });
    builder.addCase(loadPurposesList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetPurposes },
} = slice;

export { resetPurposes };

export default slice.reducer;
