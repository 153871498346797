import activitiesTable from './activitiesTable';
import activityForm from './activityForm';
import activityInfo from './activityInfo';
import admin from './admin';
import attorneyDashboardInfo from './attorneyDashboardInfo';
import autocompleteAsync from './autocompleteAsync';
import awardNominationsReport from './awardNominationsReport';
import badgeHistory from './badgeHistory';
import badgesList from './badgesList';
import badgesTable from './badgesTable';
import calendarEventForm from './calendarEventForm';
import calendarEventInfo from './calendarEventInfo';
import clientShow from './clientShow';
import companiesTable from './companiesTable';
import companyForm from './companyForm';
import companyInfo from './companyInfo';
import companyReportTable from './companyReportTable';
import completeEntityModal from './completeEntityModal';
import contactForm from './contactForm';
import contactInfo from './contactInfo';
import contactsImportForm from './contactsImportForm';
import contactsTable from './contactsTable';
import departmentProductivityReport from './departmentProductivityReport';
import eventCalendar from './eventCalendar';
import fileUploadInput from './fileUploadInput';
import goalForm from './goalForm';
import goalsTable from './goalsTable';
import kanban from './kanban';
import listFilter from './listFilter';
import listView from './listView';
import marketeerDashboardInfo from './marketeerDashboardInfo';
import membersTable from './membersTable';
import membershipsTable from './membershipsTable';
import modalComponent from './modalComponent';
import multipleListFilter from './multipleListFilter';
import navigation from './navigation';
import nonLoginsBulkImportForm from './nonLoginsBulkImportForm';
import notificationForm from './notificationForm';
import notificationsSentTable from './notificationSentTable';
import notificationsIncomingTable from './notificationsIncomingTable';
import passwordChangeForm from './passwordChangeForm';
import passwordRecoveryForm from './passwordRecoveryForm';
import passwordResetForm from './passwordResetForm';
import periodFilter from './periodFilter';
import periodPicker from './periodPicker';
import personalTeamDetailReport from './personalTeamDetailReport';
import practiceGroupDetailReport from './practiceGroupDetailReport';
import profileForm from './profileForm';
import projectForm from './projectForm';
import projectInfo from './projectInfo';
import projectNameDetailReport from './projectNameDetailReport';
import projectStats from './projectStats';
import projectTypeReport from './projectTypeReport';
import projectUsersModal from './projectUsersModal';
import projectsTable from './projectsTable';
import reports from './reports';
import searchFilter from './searchFilter';
import signInForm from './signInForm';
import tableFilter from './tableFilter';
import taskForm from './taskForm';
import tasksTable from './tasksTable';
import teamInfo from './teamInfo';
import teamsTable from './teamsTable';
import uncompletedTasksModal from './uncompletedTasksModal';
import winlossReport from './winlossReport';
import workspaceSwitcher from './workspaceSwitcher';

export default {
  badgesList,
  badgesTable,
  badgeHistory,
  membershipsTable,
  listView,
  passwordResetForm,
  passwordRecoveryForm,
  signInForm,
  navigation,
  admin,
  workspaceSwitcher,
  searchFilter,
  listFilter,
  tableFilter,
  companiesTable,
  companyForm,
  fileUploadInput,
  contactsTable,
  contactForm,
  contactsImportForm,
  activitiesTable,
  activityInfo,
  activityForm,
  companyInfo,
  goalsTable,
  goalForm,
  profileForm,
  passwordChangeForm,
  teamsTable,
  contactInfo,
  taskForm,
  tasksTable,
  membersTable,
  projectInfo,
  teamInfo,
  attorneyDashboardInfo,
  projectsTable,
  projectForm,
  projectStats,
  reports,
  marketeerDashboardInfo,
  departmentProductivityReport,
  nonLoginsBulkImportForm,
  projectNameDetailReport,
  periodFilter,
  periodPicker,
  personalTeamDetailReport,
  practiceGroupDetailReport,
  projectTypeReport,
  notificationForm,
  notificationsIncomingTable,
  notificationsSentTable,
  autocompleteAsync,
  uncompletedTasksModal,
  kanban,
  eventCalendar,
  calendarEventForm,
  calendarEventInfo,
  completeEntityModal,
  modalComponent,
  companyReportTable,
  clientShow,
  winlossReport,
  multipleListFilter,
  projectUsersModal,
  awardNominationsReport,
};
