export default {
  project: {
    label: 'Project Name',
    placeholder: 'Select project',
  },
  name: {
    label: 'Name',
    placeholder: 'Add Name',
  },
  dueDate: {
    label: 'Due Date',
    placeholder: 'Select Date',
  },
  timeSpent: {
    auditCompletedAtPlaceholder: 'Completed At',
    label: 'Time Spent',
    placeholder: {
      hours: 'hh',
      minutes: 'mm',
    },
  },
  timeSpentHistory: {
    title: 'Time Spent History',
  },
  assignedTo: {
    label: 'Assign ToDo',
    placeholder: 'Select User',
  },
  createdAt: {
    label: 'Created Date',
    placeholder: 'Select Date',
  },
  completedAt: {
    label: 'Completed Date',
    placeholder: 'Select Date',
  },
  createSuccessMessage: 'Successfully created',
  updateSuccessMessage: 'Successfully updated',
  collapseDetails: 'Collapse Details',
  save: 'Save',
  saveAndReturnToProjectButton: 'Save and return to Project',
  saveAndCreateTaskButton: 'Save and Create Another ToDo',
};
