import { createSlice } from '@reduxjs/toolkit';

import { ITask } from 'domain/task/types';
import { FetchStatus } from 'enums/FetchStatus';
import TasksRepository, {
  CreateParams,
  ShowParams,
  UpdateParams,
  IndexParams,
  ActionParams,
  CompleteParams,
} from 'repositories/TasksRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type TasksSliceStateType = {
  tasks: ITask[];
  meta: ResponseMeta;
  task: ITask;
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  complete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  open: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TasksSliceActionsType = {
  loadTasksList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'tasks', ITask[]>> };
  completeTask: (params: CompleteParams) => { unwrap: () => void };
  openTask: (params: ActionParams) => { unwrap: () => void };
  deleteTask: (params: ActionParams) => { unwrap: () => void };
  resetTasks: () => void;
  createTask: (params: CreateParams) => { unwrap: () => void };
  updateTask: (params: UpdateParams) => { unwrap: () => void };
  showTask: (params: ShowParams) => { unwrap: () => void };
  resetTask: () => void;
};

const initialState: TasksSliceStateType = {
  tasks: [],
  meta: { perPage: 8 } as ResponseMeta,
  task: {} as ITask,
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  complete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  open: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTasksList = createAsyncAction('tasks/index', TasksRepository.index);
export const completeTask = createAsyncAction('tasks/complete', TasksRepository.complete);
export const openTask = createAsyncAction('tasks/open', TasksRepository.open);
export const deleteTask = createAsyncAction('tasks/delete', TasksRepository.delete);
export const createTask = createAsyncAction('tasks/create', TasksRepository.create);
export const updateTask = createAsyncAction('tasks/update', TasksRepository.update);
export const showTask = createAsyncAction('tasks/show', TasksRepository.show);

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetTasks: state => {
      state.tasks = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
    resetTask: state => {
      state.task = {} as ITask;
      state.show.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTasksList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTasksList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.tasks = payload.tasks;
      state.meta = payload.meta;
    });
    builder.addCase(loadTasksList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(completeTask.pending, state => {
      state.complete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(completeTask.fulfilled, (state, { payload }) => {
      state.complete.fetchStatus = FetchStatus.fulfilled;
      const taskId = payload.task.id;
      const taskState = payload.task.state;
      const currentTask = state.tasks.find(task => task.id === taskId);

      currentTask.state = taskState;

      return state;
    });
    builder.addCase(completeTask.rejected, state => {
      state.complete.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(openTask.pending, state => {
      state.open.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(openTask.fulfilled, (state, { payload }) => {
      state.open.fetchStatus = FetchStatus.fulfilled;
      const taskId = payload.task.id;
      const taskState = payload.task.state;
      const currentTask = state.tasks.find(task => task.id === taskId);
      currentTask.state = taskState;
      return state;
    });
    builder.addCase(openTask.rejected, state => {
      state.open.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(deleteTask.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteTask.fulfilled, state => {
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteTask.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showTask.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showTask.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.task = payload.task;
    });
    builder.addCase(showTask.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(updateTask.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateTask.fulfilled, (state, { payload }) => {
      state.update.fetchStatus = FetchStatus.fulfilled;
      const taskId = payload.task.id;
      state.tasks = state.tasks.map(task => (task.id === taskId ? payload.task : task));

      return state;
    });
    builder.addCase(updateTask.rejected, state => {
      state.update.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetTask, resetTasks },
} = slice;

export { resetTask, resetTasks };

export default slice.reducer;
