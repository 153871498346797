import { IMonthlyProjectsReport } from 'domain/monthlyProjectsReports/types';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

export type MonthlyProjectsReportsParams = {
  clientId: ID;
  params: {
    minDate: string;
    maxDate: string;
  };
};

const MonthlyProjectsReportsRepository = {
  show({
    clientId,
    params,
  }: MonthlyProjectsReportsParams): Promise<CollectionResponse<'monthlyProjectReport', IMonthlyProjectsReport[]>> {
    const url = apiRoutes.monthlyProjectsReportsApiSiteV1ClientDashboardPath(clientId);
    return Fetcher.get(url, params);
  },
};

export default MonthlyProjectsReportsRepository;
