import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { ITask } from 'domain/task/types';
import { TaskFormDataToSubmit } from 'forms/taskForm';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type ActionParams = {
  clientId: ID;
  taskId: ID;
};

export type CreateParams = {
  clientId: ID;
  params: TaskFormDataToSubmit;
};

export type UpdateParams = {
  clientId: ID;
  taskId: ID;
  params: TaskFormDataToSubmit;
};

export type ShowParams = {
  taskId: ID;
  clientId: ID;
};

export type BulkCompleteProps = {
  clientId: ID;
  projectId: ID;
  taskIds: ID[];
  completedAt?: string;
};

export type CompleteParams = {
  clientId: ID;
  taskId: ID;
  completedAt?: string;
};

const TasksRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'tasks', ITask[]>> {
    const url = apiRoutes.apiSiteV1ClientTasksPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  create({ clientId, params }: CreateParams): Promise<SingleResponse<'task', ITask>> {
    const url = apiRoutes.apiSiteV1ClientTasksPath(clientId);
    return Fetcher.postFormData(url, params);
  },
  update({ clientId, taskId, params }: UpdateParams): Promise<SingleResponse<'task', ITask>> {
    const url = apiRoutes.apiSiteV1ClientTaskPath(clientId, taskId);
    return Fetcher.putFormData(url, params);
  },
  show({ clientId, taskId }: ShowParams): Promise<SingleResponse<'task', ITask>> {
    const url = apiRoutes.apiSiteV1ClientTaskPath(clientId, taskId);
    return Fetcher.get(url);
  },
  complete({ clientId, taskId, completedAt }: CompleteParams): Promise<SingleResponse<'task', ITask>> {
    const url = apiRoutes.completeApiSiteV1ClientTaskPath(clientId, taskId);
    return Fetcher.put(url, { completedAt });
  },
  open({ clientId, taskId }: ActionParams): Promise<SingleResponse<'task', ITask>> {
    const url = apiRoutes.openApiSiteV1ClientTaskPath(clientId, taskId);
    return Fetcher.put(url, {});
  },
  delete({ clientId, taskId }: ActionParams): Promise<SingleResponse<'task', ITask>> {
    const url = apiRoutes.apiSiteV1ClientTaskPath(clientId, taskId);
    return Fetcher.delete(url);
  },
  bulkComplete({ clientId, projectId, taskIds, completedAt }: BulkCompleteProps): Promise<unknown> {
    const url = apiRoutes.bulkCompleteApiSiteV1ClientProjectTasksPath(clientId, projectId);
    return Fetcher.put(url, { taskIds, completedAt });
  },
};

export default TasksRepository;
