import { ModalLayout } from 'enums/ModalLayout';
import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  [ModalLayout.default]: {
    '& .MuiDialog-paper': {
      maxWidth: '550px',
      width: '100%',
      padding: '32px 40px 40px',
    },
  },
  [ModalLayout.large]: {
    '& .MuiDialog-paper': {
      maxWidth: '760px',
      width: '100%',
      padding: '32px 40px 40px',
    },
  },
  title: {
    '&.MuiTypography-root': {
      fontSize: '25px',
      fontWeight: '700',
      marginBottom: '30px',
    },
  },
  actions: {
    marginTop: '30px',
  },
  icon: {
    width: '24px',
    height: '24px',
    display: 'block',
  },
  hideButton: {
    '&.MuiButtonBase-root': {
      position: 'absolute',
      top: '16px',
      right: '16px',
    },
  },
};

export default styles;
