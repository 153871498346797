import MuiButton from './MuiButton';
import MuiTextField from './MuiTextField';
import MuiFormHelperText from './MuiFormHelperText';
import MuiFormLabel from './MuiFormLabel';
import MuiInput from './MuiInput';
import MuiInputLabel from './MuiInputLabel';
import MuiLink from './MuiLink';
import MuiCssBaseline from './MuiCssBaseline';
import MuiCircularProgress from './MuiCircularProgress';
import MuiAvatar from './MuiAvatar';
import MuiTableCell from './MuiTableCell';
import MuiCheckbox from './MuiCheckbox';
import MuiPagination from './MuiPagination';
import MuiFormControl from './MuiFormControl';
import MuiSelect from './MuiSelect';
import MuiPaper from './MuiPaper';
import MuiTabs from './MuiTabs';
import MuiTab from './MuiTab';
import MuiTabPanel from './MuiTabPanel';
import MuiChip from './MuiChip';
import MuiIconButton from './MuiIconButton';
import MuiAutocomplete from './MuiAutocomplete';
import MuiRadio from './MuiRadio';
import MuiSwitch from './MuiSwitch';

const overrides = {
  components: {
    MuiButton,
    MuiTextField,
    MuiFormHelperText,
    MuiFormLabel,
    MuiInput,
    MuiInputLabel,
    MuiLink,
    MuiCssBaseline,
    MuiCircularProgress,
    MuiAvatar,
    MuiTableCell,
    MuiCheckbox,
    MuiPagination,
    MuiFormControl,
    MuiSelect,
    MuiPaper,
    MuiTabs,
    MuiTab,
    MuiTabPanel,
    MuiChip,
    MuiIconButton,
    MuiAutocomplete,
    MuiRadio,
    MuiSwitch,
  },
};

export default overrides;
