import { createSlice } from '@reduxjs/toolkit';

import { IIndustry } from 'domain/industry/types';
import { FetchStatus } from 'enums/FetchStatus';
import IndustriesRepository, { IndexParams } from 'repositories/IndustriesRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type IndustriesSliceStateType = {
  industries: IIndustry[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type IndustriesSliceActionsType = {
  loadIndustriesList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'industries', IIndustry[]>> };
  resetIndustries: () => void;
};

const initialState: IndustriesSliceStateType = {
  industries: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadIndustriesList = createAsyncAction('industries/index', IndustriesRepository.index);

const slice = createSlice({
  name: 'industries',
  initialState,
  reducers: {
    resetIndustries: state => {
      state.industries = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadIndustriesList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadIndustriesList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.industries = payload.industries;
    });
    builder.addCase(loadIndustriesList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetIndustries },
} = slice;

export { resetIndustries };

export default slice.reducer;
