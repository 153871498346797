export default {
  title: 'Change Password',
  password: {
    label: 'Password',
    placeholder: 'Enter new Password',
  },
  passwordConfirmation: {
    fieldName: 'Password confirmation',
    label: 'Confirm your Password',
    placeholder: 'Confirm your Password',
  },
  submit: 'Change Password',
  successMessage: {
    title: 'Success',
    text: 'Your password has been changed!\nNow you can sign in using your credentials.',
    link: 'Sign In',
  },
};
