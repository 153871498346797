import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IProject } from 'domain/project/types';
import { ProjectFormDataToSubmit } from 'forms/projectForm';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type CreateParams = {
  clientId: ID;
  params: ProjectFormDataToSubmit;
};

export type ShowParams = {
  clientId: ID;
  projectId: ID;
};

export type ActionParams = {
  clientId: ID;
  projectId: ID;
};

export type UpdateParams = {
  clientId: ID;
  projectId: ID;
  params: ProjectFormDataToSubmit;
};

export type CompleteParams = {
  clientId: ID;
  projectId: ID;
  completedAt?: string;
};

const ProjectsRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'projects', IProject[]>> {
    const url = apiRoutes.apiSiteV1ClientProjectsPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  show({ clientId, projectId }: ShowParams): Promise<SingleResponse<'project', IProject>> {
    const url = apiRoutes.apiSiteV1ClientProjectPath(clientId, projectId);
    return Fetcher.get(url);
  },
  complete({ clientId, projectId, completedAt }: CompleteParams): Promise<SingleResponse<'project', IProject>> {
    const url = apiRoutes.completeApiSiteV1ClientProjectPath(clientId, projectId);
    return Fetcher.put(url, { completedAt });
  },
  open({ clientId, projectId }: ActionParams): Promise<SingleResponse<'project', IProject>> {
    const url = apiRoutes.openApiSiteV1ClientProjectPath(clientId, projectId);
    return Fetcher.put(url, {});
  },
  delete({ clientId, projectId }: ActionParams): Promise<SingleResponse<'project', IProject>> {
    const url = apiRoutes.apiSiteV1ClientProjectPath(clientId, projectId);
    return Fetcher.delete(url);
  },
  create({ clientId, params }: CreateParams): Promise<SingleResponse<'project', IProject>> {
    const url = apiRoutes.apiSiteV1ClientProjectsPath(clientId);
    return Fetcher.postFormData(url, params);
  },
  update({ clientId, projectId, params }: UpdateParams): Promise<SingleResponse<'project', IProject>> {
    const url = apiRoutes.apiSiteV1ClientProjectPath(clientId, projectId);
    return Fetcher.putFormData(url, params);
  },
  uniqueTypeOfAwards({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'typeOfAwards', string[]>> {
    const url = apiRoutes.uniqueTypeOfAwardsApiSiteV1ClientProjectsPath(clientId);
    return Fetcher.get(url, queryParams);
  },
};

export default ProjectsRepository;
