import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import {
  IDepartmentProductivityMonthlyGroupedReport,
  IDepartmentProductivityReport,
} from 'domain/departmentProductivityReport/types';
import { TableFilterQueryParams } from 'types/tableFilter';

export type IndexParams = {
  clientId: ID;
  queryParams?: TableFilterQueryParams;
};

export type GroupedParams = {
  clientId: ID;
  queryParams?: TableFilterQueryParams;
};

const DepartmentProductivityReportRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<IDepartmentProductivityReport> {
    const url = apiRoutes.apiSiteV1ClientReportsDepartmentProductivityReportIndexPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  monthlyGrouped({ clientId, queryParams }: GroupedParams): Promise<IDepartmentProductivityMonthlyGroupedReport> {
    const url = apiRoutes.monthlyGroupedApiSiteV1ClientReportsDepartmentProductivityReportIndexPath(clientId);
    return Fetcher.get(url, queryParams);
  },
};

export default DepartmentProductivityReportRepository;
