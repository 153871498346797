import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IProjectAudit } from 'domain/projectAudit/types';
import { ProjectAuditFormDataToSubmit } from 'forms/projectAuditForm';

export type IndexParams = {
  clientId: ID;
  projectId: ID;
  queryParams: TableFilterQueryParams;
};

export type CreateParams = {
  clientId: ID;
  projectId: ID;
  params: ProjectAuditFormDataToSubmit;
};

export type ActionParams = {
  clientId: ID;
  projectId: ID;
  auditId: ID;
};

const ProjectAuditsRepository = {
  index({ clientId, projectId, queryParams }: IndexParams): Promise<CollectionResponse<'audits', IProjectAudit[]>> {
    const url = apiRoutes.apiSiteV1ClientProjectAuditsPath(clientId, projectId);
    return Fetcher.get(url, queryParams);
  },
  create({ clientId, projectId, params }: CreateParams): Promise<SingleResponse<'audit', IProjectAudit>> {
    const url = apiRoutes.apiSiteV1ClientProjectAuditsPath(clientId, projectId);
    return Fetcher.postFormData(url, params);
  },
  delete({ clientId, projectId, auditId }: ActionParams): Promise<SingleResponse<'audit', IProjectAudit>> {
    const url = apiRoutes.apiSiteV1ClientProjectAuditPath(clientId, projectId, auditId);
    return Fetcher.delete(url);
  },
};

export default ProjectAuditsRepository;
