import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiTabsType = {
  defaultProps: ComponentsProps['MuiTabs'];
  styleOverrides: ComponentsOverrides['MuiTabs'];
};

const MuiTabs: MuiTabsType = {
  styleOverrides: {
    root: {
      borderBottom: '1px solid',
      borderColor: palette.dark.lighter,
    },
    indicator: {
      backgroundColor: palette.orange.main,
    },
  },
  defaultProps: {},
};

export default MuiTabs;
