import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiFormLabelType = {
  defaultProps: ComponentsProps['MuiFormLabel'];
  styleOverrides: ComponentsOverrides['MuiFormLabel'];
};

const MuiFormLabel: MuiFormLabelType = {
  styleOverrides: {
    root: {
      color: palette.black.main,
      fontSize: '12px',
      fontWeight: '600',
      '&.Mui-focused': {
        color: palette.black.main,
      },
      '& span': {
        color: palette.red.main,
      },
    },
  },
  defaultProps: {},
};

export default MuiFormLabel;
