import { createSlice } from '@reduxjs/toolkit';

import { ProjectDepartment } from 'domain/project/enums';
import { IProjectNameDetailReportStats, IReportProject } from 'domain/projectNameDetailReport/types';
import { FetchStatus } from 'enums/FetchStatus';
import ProjectTypeReportRepository, {
  IndexParams,
  StatsParams,
} from 'repositories/Reports/ProjectTypeReportRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

type ProjectTypeReportItem = {
  data: IReportProject[];
  meta: ResponseMeta;
  fetchStatus: FetchStatus;
};

export type ProjectTypeReportSliceStateType = Record<ProjectDepartment, ProjectTypeReportItem> & {
  reportStats: IProjectNameDetailReportStats;
  stats: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ProjectTypeReportSliceActionsType = {
  loadProjectTypeReport: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'projects', IReportProject[]>>;
  };
  loadProjectTypeReportStats: (params: StatsParams) => {
    unwrap: () => Promise<IProjectNameDetailReportStats>;
  };
};

const initialState: ProjectTypeReportSliceStateType = {
  [ProjectDepartment.businessDevelopment]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  [ProjectDepartment.communications]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  [ProjectDepartment.marketing]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  reportStats: {} as IProjectNameDetailReportStats,
  stats: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadProjectTypeReport = createAsyncAction('projectTypeReport/index', ProjectTypeReportRepository.index);
export const loadProjectTypeReportStats = createAsyncAction(
  'projectTypeReport/stats',
  ProjectTypeReportRepository.stats,
);

const slice = createSlice({
  name: 'projectTypeReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadProjectTypeReport.pending, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadProjectTypeReport.fulfilled, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.fulfilled;
      state[department].data = action.payload.projects;
      state[department].meta = action.payload.meta;
    });
    builder.addCase(loadProjectTypeReport.rejected, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadProjectTypeReportStats.pending, state => {
      state.stats.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadProjectTypeReportStats.fulfilled, (state, { payload }) => {
      state.stats.fetchStatus = FetchStatus.fulfilled;
      state.reportStats = payload;
    });
    builder.addCase(loadProjectTypeReportStats.rejected, state => {
      state.stats.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
