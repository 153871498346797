import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IContact } from 'domain/contact/types';
import { ContactFormDataToSubmit } from 'forms/contactForm';
import { downloadFile } from 'utils/download';
import { decamelize } from 'utils/keysConverter';
import { IUser } from 'domain/user/types';
import { ContactsBulkImportFormData } from 'forms/contactBulkImportForm';

export type IndexParams = {
  clientId: ID;
  contactId?: ID;
  queryParams: TableFilterQueryParams;
};
export type CreateParams = {
  clientId: ID;
  params: ContactFormDataToSubmit;
};
export type UpdateParams = {
  clientId: ID;
  contactId: ID;
  params: ContactFormDataToSubmit;
};
export type ShowParams = {
  contactId: ID;
  clientId: ID;
};

export type ImportParams = {
  clientId: ID;
  params: ContactsBulkImportFormData;
};

export type ImportResponse = {
  data: {
    ignoredLines: number[];
    invalidLines: number[];
    totalCount: number;
  };
};

const ContactsRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'contacts', IContact[]>> {
    const url = apiRoutes.apiSiteV1ClientContactsPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  create({ clientId, params }: CreateParams): Promise<SingleResponse<'contact', IContact>> {
    const url = apiRoutes.apiSiteV1ClientContactsPath(clientId);
    return Fetcher.postFormData(url, params);
  },
  update({ clientId, contactId, params }: UpdateParams): Promise<SingleResponse<'contact', IContact>> {
    const url = apiRoutes.apiSiteV1ClientContactPath(clientId, contactId);
    return Fetcher.putFormData(url, params);
  },
  show({ clientId, contactId }: ShowParams): Promise<SingleResponse<'contact', IContact>> {
    const url = apiRoutes.apiSiteV1ClientContactPath(clientId, contactId);
    return Fetcher.get(url);
  },
  import({ clientId, params }: ImportParams): Promise<ImportResponse> {
    const url = apiRoutes.bulkImportApiSiteV1ClientContactsPath(clientId);

    return Fetcher.postFormData(url, params);
  },
  async export({ clientId, queryParams }: IndexParams): Promise<void> {
    const url = apiRoutes.exportApiSiteV1ClientContactsPath(clientId, decamelize(queryParams));

    return downloadFile(url);
  },
  async exportActivities({ clientId, contactId, queryParams }: IndexParams): Promise<void> {
    const url = apiRoutes.exportApiSiteV1ClientContactActivitiesPath(clientId, contactId, decamelize(queryParams));

    return downloadFile(url);
  },
  loadUsers({ clientId, contactId, queryParams }: IndexParams): Promise<CollectionResponse<'users', IUser[]>> {
    const url = apiRoutes.apiSiteV1ClientContactUsersPath(clientId, contactId);
    return Fetcher.get(url, queryParams);
  },
};

export default ContactsRepository;
