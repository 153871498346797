import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiAutocompleteType = {
  defaultProps: ComponentsProps['MuiAutocomplete'];
  styleOverrides: ComponentsOverrides['MuiAutocomplete'];
};

const MuiAutocomplete: MuiAutocompleteType = {
  styleOverrides: {
    groupLabel: {
      color: palette.black.light,
      fontSize: '11px',
      fontWeight: '500',
      lineHeight: '1',
      padding: '4px 24px 12px',
      backgroundColor: palette.white.main,
    },
  },
  defaultProps: {},
};

export default MuiAutocomplete;
