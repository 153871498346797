import components from './components';
import navigation from './navigation';
import forms from './forms';
import layout from './layout';
import pages from './pages';
import presenters from './presenters';

const en = {
  components,
  navigation,
  forms,
  layout,
  pages,
  presenters,
};

export default en;

export const ns = Object.keys(en);
