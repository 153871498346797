import {
  ActivitiesSliceStateType,
  ActivitiesSliceActionsType,
  loadActivitiesList,
  deleteActivity,
  completeActivity,
  openActivity,
  exportActivitiesList,
  createActivity,
  updateActivity,
  showActivity,
  resetActivity,
  resetActivities,
} from 'store/activitiesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';
import {
  IndexParams,
  CreateParams,
  UpdateParams,
  ShowParams,
  ActionParams,
  CompleteParams,
} from 'repositories/ActivitiesRepository';

type UseActivitiesType = Pick<ActivitiesSliceStateType, 'activities' | 'meta' | 'activity'> &
  ActivitiesSliceActionsType & {
    isActivitiesLoadFinished: boolean;
    isActivityLoadFinished: boolean;
    isActivityLoadFailed: boolean;
    isActivityDeletingFinished: boolean;
    isActivityCompletingFinished: boolean;
    isActivityCompletingPending: boolean;
    isActivityOpeningFinished: boolean;
  };

const useActivities = (): UseActivitiesType => {
  const dispatch = useAppDispatch();
  const {
    activities,
    meta,
    activity,
    index: { fetchStatus: indexFetchStatus },
    show: { fetchStatus: showFetchStatus },
    delete: { fetchStatus: deleteFetchStatus },
    complete: { fetchStatus: completeFetchStatus },
    open: { fetchStatus: openFetchStatus },
  } = useAppSelector(state => state.activities);

  const isActivitiesLoadFinished = getFetchStatus(indexFetchStatus).isFinished;

  const isActivityLoadFinished = getFetchStatus(showFetchStatus).isFinished;
  const isActivityLoadFailed = getFetchStatus(showFetchStatus).isFailed;

  const isActivityDeletingFinished = getFetchStatus(deleteFetchStatus).isFinished;
  const isActivityCompletingFinished = getFetchStatus(completeFetchStatus).isFinished;
  const isActivityCompletingPending = getFetchStatus(completeFetchStatus).isPending;
  const isActivityOpeningFinished = getFetchStatus(openFetchStatus).isFinished;

  return {
    activities,
    meta,
    activity,
    isActivitiesLoadFinished,
    isActivityLoadFinished,
    isActivityLoadFailed,
    isActivityDeletingFinished,
    isActivityCompletingFinished,
    isActivityCompletingPending,
    isActivityOpeningFinished,
    loadActivitiesList: (params: IndexParams) => dispatch(loadActivitiesList(params)),
    deleteActivity: (params: ActionParams) => dispatch(deleteActivity(params)),
    completeActivity: (params: CompleteParams) => dispatch(completeActivity(params)),
    openActivity: (params: ActionParams) => dispatch(openActivity(params)),
    exportActivitiesList: (params: IndexParams) => dispatch(exportActivitiesList(params)),
    createActivity: (params: CreateParams) => dispatch(createActivity(params)),
    updateActivity: (params: UpdateParams) => dispatch(updateActivity(params)),
    showActivity: (params: ShowParams) => dispatch(showActivity(params)),
    resetActivity: () => dispatch(resetActivity()),
    resetActivities: () => dispatch(resetActivities()),
  };
};

export default useActivities;
