import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import Icon from 'components/Icon';

type MuiCheckboxType = {
  defaultProps: ComponentsProps['MuiCheckbox'];
  styleOverrides: ComponentsOverrides['MuiCheckbox'];
};

const MuiCheckbox: MuiCheckboxType = {
  styleOverrides: {
    root: {
      padding: '0px',
      marginRight: '8px',
      borderRadius: '4px',
      '& svg': {
        width: '22px',
        height: '22px',
      },
    },
  },
  defaultProps: {
    disableRipple: true,
    icon: <Icon name="checkboxEmpty" />,
    checkedIcon: <Icon name="checkbox" />,
    indeterminateIcon: <Icon name="checkboxIndeterminate" />,
  },
};

export default MuiCheckbox;
