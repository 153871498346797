import { configureStore } from '@reduxjs/toolkit';

import history from 'utils/history';
import createAdminReducers from 'components/Admin/createAdminReducers';

import usersReducer from './usersSlice';
import sessionReducer from './sessionSlice';
import sidebarReducer from './ui/sidebarSlice';
import companiesReducer from './companiesSlice';
import industriesReducer from './industriesSlice';
import contactsReducer from './contactsSlice';
import contactRelationsReducer from './contactRelationsSlice';
import activitiesReducer from './activitiesSlice';
import modalsReducer from './ui/modalsSlice';
import goalsReducer from './goalsSlice';
import activityTypesReducer from './activityTypesSlice';
import clientUsersReducer from './clientUsersSlice';
import teamsReducer from './teamsSlice';
import workspaceReducer from './ui/workspaceSlice';
import tasksReducer from './tasksSlice';
import projectsReducer from './projectsSlice';
import membershipsReducer from './membershipsSlice';
import practiceGroupsSlice from './practiceGroupsSlice';
import projectNotesReducer from './projectNotesSlice';
import projectAuditsReducer from './projectAuditsSlice';
import teamMembershipsReducer from './teamMemberships';
import teamScoreReducer from './teamScoreSlice';
import dailyScoresReducer from './dailyScoresSlice';
import totalPointsReducer from './totalPointsSlice';
import monthlyProjectsReportsReducer from './reports/monthlyProjectsReportsSlice';
import departmentProductivityReportReducer from './reports/departmentProductivityReportSlice';
import projectNameDetailReportReducer from './reports/projectNameDetailReportSlice';
import teamDetailReportReducer from './reports/teamDetailReportSlice';
import attorneyDetailReportReducer from './reports/attorneyDetailReportSlice';
import practiceGroupReportReducer from './reports/practiceGroupReportSlice';
import practiceGroupDetailReportReducer from './reports/practiceGroupDetailReportSlice';
import projectTypeReportReducer from './reports/projectTypeReportSlice';
import purposesReducer from './purposesSlice';
import attorneyDetailReportUserReducer from './reports/attorneyDetailReportUserSlice';
import clientNotificationsReducer from './client/clientNotificationsSlice';
import userClientNotificationsReducer from './client/userClientNotificationsSlice';
import globalSearchReducer from './globalSearchSlice';
import projectsColumnsSnapshotsReducer from './projectsKanbanSlice';
import api from './api';

const adminReducers = createAdminReducers({ history });

export const store = configureStore({
  reducer: {
    users: usersReducer,
    session: sessionReducer,
    sidebar: sidebarReducer,
    companies: companiesReducer,
    industries: industriesReducer,
    goals: goalsReducer,
    admin: adminReducers.admin,
    router: adminReducers.router,
    contacts: contactsReducer,
    contactRelations: contactRelationsReducer,
    activities: activitiesReducer,
    modals: modalsReducer,
    activityTypes: activityTypesReducer,
    clientUsers: clientUsersReducer,
    teams: teamsReducer,
    workspace: workspaceReducer,
    tasks: tasksReducer,
    projects: projectsReducer,
    memberships: membershipsReducer,
    practiceGroups: practiceGroupsSlice,
    projectNotes: projectNotesReducer,
    projectAudits: projectAuditsReducer,
    teamMemberships: teamMembershipsReducer,
    teamScore: teamScoreReducer,
    dailyScores: dailyScoresReducer,
    totalPoints: totalPointsReducer,
    monthlyProjectsReports: monthlyProjectsReportsReducer,
    departmentProductivityReport: departmentProductivityReportReducer,
    projectNameDetailReport: projectNameDetailReportReducer,
    teamDetailReport: teamDetailReportReducer,
    attorneyDetailReport: attorneyDetailReportReducer,
    practiceGroupReport: practiceGroupReportReducer,
    practiceGroupDetailReport: practiceGroupDetailReportReducer,
    projectTypeReport: projectTypeReportReducer,
    purposes: purposesReducer,
    attorneyDetailReportUser: attorneyDetailReportUserReducer,
    clientNotifications: clientNotificationsReducer,
    userClientNotifications: userClientNotificationsReducer,
    globalSearch: globalSearchReducer,
    [api.reducerPath]: api.reducer,
    projectsColumnsSnapshots: projectsColumnsSnapshotsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
