import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Workspace } from 'enums/Workspace';

export type WorkspaceSliceStateType = {
  workspace: Workspace;
};

export type WorkspaceSliceActionsType = {
  changeWorkspace: (workspace: Workspace) => void;
};

const initialState: WorkspaceSliceStateType = {
  workspace: null,
};

const slice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    changeWorkspace: (state, { payload }: PayloadAction<Workspace>) => {
      state.workspace = payload;
    },
  },
});

const {
  actions: { changeWorkspace },
} = slice;

export { changeWorkspace };

export default slice.reducer;
