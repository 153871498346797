import { ProjectOutcomeValue } from 'domain/project/enums';

export default {
  project: 'Project',
  todo: 'ToDo',
  completeButtonLabel: 'Mark as completed',
  openButtonLabel: 'Re-open project',
  filesLabel: 'Files Attached',
  timeSpentComment: 'spent on this project',
  stateLabel: 'State',
  statusLabel: '% Complete',
  noAdditionalDetails: 'No Additional Details',
  startingDateLabel: 'Starting Date',
  createdDateLabel: 'Created Date',
  completedDateLabel: 'Completed Date',
  leadsAttorneyLabel: 'Lead Attorney(s)',
  attorneyTeamLabel: 'Attorney Team',
  purposeLabel: 'Type',
  dueDateLabel: 'Due Date',
  assignedToLabel: 'Assigned to',
  winLossLabel: 'Win/Loss',
  typeOfWorkLabel: 'Type of Work',
  projectedRevenueLabel: 'Projected Revenue',
  addToCalendar: 'Add to Calendar',
  addToCalendarSubtitle: 'Switch to add project to calendar',
  removeFromCalendarSubtitle: 'Switch to remove project from calendar',
  practiceGroupLabel: 'Practice Group',
  subPracticeGroupLabel: 'Sub Practice Group',
  companyLabel: 'Company',
  outcomeLabel: 'Outcome',
  publicationLabel: 'Publication',
  yearsLabel: 'Years',
  files: {
    noData: 'No Files',
  },
  notesLabel: 'Notes',
  notes: {
    noData: 'No Notes',
    delete: 'Delete',
    text: 'Add new note',
    submit: 'Save the note',
    collapse: 'Collapse',
    more: 'More',
  },
  tasksLabel: 'ToDos',
  tasksTable: {
    modalTitle: 'Create new ToDo',
    buttonText: 'Add new ToDo',
    noResources: 'No ToDos',
    actions: {
      editModalLabel: 'Edit ToDo',
      deleteModalLabel: 'Are you sure want to delete this?',
      complete: 'Complete',
      reopen: 'Reopen',
      edit: 'Edit',
      delete: 'Delete',
      cancel: 'Cancel',
    },
  },
  dueDate: {
    setDueDateButton: 'Set Due Date',
    saveButton: 'Save changes',
    removeButton: 'Remove due date',
  },
  assignedTo: {
    assignButton: 'Assign To...',
    saveButton: 'Save changes',
    removeButton: 'Remove assignee',
  },
  usersList: {
    assignedTo: 'Assigned to:',
    noData: 'No Users',
  },
  addTimeSpent: 'Add time spent',
  audit: 'Audit',
  auditModal: {
    auditLabel: 'Audit',
    closeButtonLabel: 'Okay',
    noDataLabel: 'No data',
    nameLabel: 'Name/Type',
    dateLabel: 'Date',
    userLabel: 'User',
    hoursLabel: 'Hours',
    projectType: 'Project',
    todoType: 'ToDo',
  },
  addTimeSpentModal: {
    auditDate: 'Audit Date',
    addTimeSpentTitle: 'Add time spent',
    timeSpentComment: 'spent on this project',
    timeSpent: {
      label: 'Time Spent',
      placeholder: {
        hours: 'HH',
        minutes: 'MM',
      },
    },
    submitButtonLabel: 'Add time spent',
    cancelButtonLabel: 'Cancel',
    createSuccessMessage: 'Successfully added',
    removeSuccessMessage: 'Successfully removed',
  },
  toDoNotCompletedModal: {
    title: 'ToDo not completed',
    content: 'ToDo cannot be completed because it is not assigned to anyone or has no due date.',
  },
  projectNotCompletedModal: {
    title: 'Project not completed',
    content: 'Project cannot be completed because it is not assigned to anyone or has no due date.',
  },
  leadAttorney: 'Lead Attorney',
  attorneyTeam: 'Attorney Team',
  fullNameHeader: 'Full Name',
  isLeadHeader: 'Is Lead',
  isRecognizedHeader: 'Is Recognized',
  [ProjectOutcomeValue.received]: 'Received',
  [ProjectOutcomeValue.notReceived]: 'Not received',
  [ProjectOutcomeValue.outstanding]: 'Outstanding',
  columnsHeads: {
    name: 'Name',
    assignedTo: 'Assigned to',
    dueDate: 'Due Date',
    completedAt: 'Completed at',
  },
};
