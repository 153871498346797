import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import ProjectNotesRepository, {
  ActionParams,
  CreateParams,
  IndexParams,
} from 'repositories/Project/ProjectNotesRepository';
import { IProjectNote } from 'domain/projectNote/types';

export type ProjectNotesSliceStateType = {
  projectNotes: IProjectNote[];
  meta: ResponseMeta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ProjectNotesSliceActionsType = {
  loadProjectNotesList: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'projectNotes', unknown[]>>;
  };
  resetProjectNotes: () => void;
  createProjectNote: (params: CreateParams) => { unwrap: () => void };
  deleteProjectNote: (params: ActionParams) => { unwrap: () => void };
};

const initialState: ProjectNotesSliceStateType = {
  projectNotes: [],
  meta: { perPage: 10 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadProjectNotesList = createAsyncAction('projectNotes/index', ProjectNotesRepository.index);
export const createProjectNote = createAsyncAction('projectNotes/create', ProjectNotesRepository.create);
export const deleteProjectNote = createAsyncAction('projectNotes/delete', ProjectNotesRepository.delete);

const slice = createSlice({
  name: 'projectNotes',
  initialState,
  reducers: {
    resetProjectNotes: state => {
      state.projectNotes = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadProjectNotesList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadProjectNotesList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.projectNotes = payload.projectNotes;
      state.meta = payload.meta;
    });
    builder.addCase(loadProjectNotesList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetProjectNotes },
} = slice;

export { resetProjectNotes };

export default slice.reducer;
