import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiLinkType = {
  defaultProps: ComponentsProps['MuiLink'];
  styleOverrides: ComponentsOverrides['MuiLink'];
};

const MuiLink: MuiLinkType = {
  styleOverrides: {
    root: {
      fontFamily: 'Nunito, Inter, Arial',
      fontSize: '14px',
      fontWeight: '700',
      textTransform: 'none',
      textDecoration: 'none',
    },
  },
  defaultProps: {
    color: palette.green.main,
    variant: 'button',
  },
};

export default MuiLink;
