import { IUserClientNotification, IUserClientNotificationsResponse } from 'domain/userClientNotifications/types';
import { apiRoutes } from 'routes';
import { TableFilterQueryParams } from 'types/tableFilter';
import Fetcher from 'utils/fetcher';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type ChangeNotificationStateParams = {
  clientId: ID;
  notificationId: ID;
};

const UserClientNotificationsRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<IUserClientNotificationsResponse> {
    const url = apiRoutes.apiSiteV1ClientUserClientNotificationsPath(clientId);

    return Fetcher.get(url, queryParams);
  },
  markAsRead({ clientId, notificationId }: ChangeNotificationStateParams): Promise<IUserClientNotification> {
    const url = apiRoutes.markAsReadApiSiteV1ClientUserClientNotificationPath(clientId, notificationId);

    return Fetcher.put(url, {});
  },
  markAsUnread({ clientId, notificationId }: ChangeNotificationStateParams): Promise<IUserClientNotification> {
    const url = apiRoutes.markAsUnreadApiSiteV1ClientUserClientNotificationPath(clientId, notificationId);

    return Fetcher.put(url, {});
  },
};

export default UserClientNotificationsRepository;
