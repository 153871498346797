import { ProjectOutcomeValue } from 'domain/project/enums';

export default {
  dueDateFilterLabel: 'Due Date',
  winlossFilterLabel: 'Filter by Win/Loss Value',
  companyFilterLabel: 'Filter by Company Name',
  projectFilterLabel: 'Filter by Project Name',
  industryFilterLabel: 'Filter by Industry',
  practiceGroupFilterLabel: 'Filter by Practice Area',
  purposesFilterLabel: 'Filter by Type',
  nomineeFilterLabel: 'Filter by Nominee',
  outcomeFilterLabel: 'Filter by Outcome',
  awardCategoryLabel: 'Filter by Award Category',
  publicationsFilterLabel: 'Filter by Publications',
  yearsFilterLabel: 'Filter by Years',
  export: 'Export',
  wonRevenueLabel: 'Anticipated Revenue',
  lostRevenueLabel: 'Lost Revenue',
  totalRevenueLabel: 'Revenue',
  spendLabel: 'Total Spend',
  awardsNominationsRankingProjects: 'Awards / Nominations / Ranking Projects',
  totalHours: 'Total hours',
  publication: 'Publication',
  nominee: 'Nominee',
  practiceArea: 'Practice Area',
  type: 'Type',
  awardCategory: 'Award Category',
  projectName: 'Project Name',
  years: 'Year(s)',
  outcome: 'Outcome',
  ytdHours: 'YTD Hours',
  totalSpend: 'Total Spend',
  [ProjectOutcomeValue.received]: 'Received',
  [ProjectOutcomeValue.notReceived]: 'Not received',
  [ProjectOutcomeValue.outstanding]: 'Outstanding',
};
