import { last } from 'ramda';

import {
  ModalsSliceStateType,
  ModalsSliceActionsType,
  Modal,
  showModal,
  hideModal,
  hideAllModals,
} from 'store/ui/modalsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

type UseModalsType = ModalsSliceStateType &
  ModalsSliceActionsType & {
    currentModal: Modal;
  };

const useModals = (): UseModalsType => {
  const dispatch = useAppDispatch();
  const { modals } = useAppSelector(state => state.modals);

  const currentModal = last(modals);

  return {
    currentModal,
    modals,
    showModal: (modal: Modal) => dispatch(showModal(modal)),
    hideModal: () => dispatch(hideModal()),
    hideAllModals: () => dispatch(hideAllModals()),
  };
};

export default useModals;
