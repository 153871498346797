import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { IGoal } from 'domain/goal/types';
import { TableFilterQueryParams } from 'types/tableFilter';
import { GoalFormDataToSubmit } from 'forms/goalForm';
import { decamelize } from 'utils/keysConverter';
import { downloadFile } from 'utils/download';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type CreateParams = {
  clientId: ID;
  params: GoalFormDataToSubmit;
};

export type UpdateParams = {
  clientId: ID;
  goalId: ID;
  params: GoalFormDataToSubmit;
};

export type ShowParams = {
  clientId: ID;
  goalId: ID;
};

export type CompleteParams = {
  clientId: ID;
  goalId: ID;
  completedAt?: string;
};

const GoalsRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'goals', IGoal[]>> {
    const url = apiRoutes.apiSiteV1ClientGoalsPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  create({ clientId, params }: CreateParams): Promise<SingleResponse<'goal', IGoal>> {
    const url = apiRoutes.apiSiteV1ClientGoalsPath(clientId);
    return Fetcher.postFormData(url, params);
  },
  update({ clientId, goalId, params }: UpdateParams): Promise<SingleResponse<'goal', IGoal>> {
    const url = apiRoutes.apiSiteV1ClientGoalPath(clientId, goalId);
    return Fetcher.putFormData(url, params);
  },
  show({ clientId, goalId }: ShowParams): Promise<SingleResponse<'goal', IGoal>> {
    const url = apiRoutes.apiSiteV1ClientGoalPath(clientId, goalId);
    return Fetcher.get(url);
  },
  complete({ clientId, goalId, completedAt }: CompleteParams): Promise<SingleResponse<'goal', IGoal>> {
    const url = apiRoutes.completeApiSiteV1ClientGoalPath(clientId, goalId);
    return Fetcher.put(url, { completedAt });
  },
  async export({ clientId, queryParams }: IndexParams): Promise<void> {
    const url = apiRoutes.exportApiSiteV1ClientGoalsPath(clientId, decamelize(queryParams));

    return downloadFile(url);
  },
};

export default GoalsRepository;
