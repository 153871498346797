export default {
  showReport: 'Show the report',
  activitiesTable: {
    stateFilterLabel: 'Status',
    activityTypeFilterLabel: 'Activity Types',
    assignedAttorneyFilterLabel: 'Assigned attorney',
    exportButton: 'Export activities',
    noDataLabel: 'No activities',
  },
  contactsTable: {
    exportButton: 'Export contacts',
    noDataLabel: 'No contacts',
    actions: {
      editLabel: 'Edit',
    },
  },
  columnsHeads: {
    person: 'Person',
    position: 'Position',
  },
};
