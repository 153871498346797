import React, { useEffect } from 'react';
import { Button, IconButton, Dialog, Typography } from '@mui/material';
import { isNil } from 'ramda';

import useRouter from 'hooks/useRouter';
import { ModalLayout } from 'enums/ModalLayout';
import useModals from 'hooks/useModals';
import Icon from 'components/Icon';
import Box from 'components/Box';

import styles from './styles';

const Modal: React.FC = () => {
  const { currentModal, hideModal, hideAllModals } = useModals();
  const { location } = useRouter();

  useEffect(() => {
    hideAllModals();
  }, [location]);

  if (!currentModal) {
    return null;
  }

  const {
    title = null,
    Content,
    Actions = null,
    modalLayout = ModalLayout.default,
    isActionsVisible = true,
    props = {},
  } = currentModal;
  const currentModalLayout = styles[modalLayout];

  const handleCancel = () => {
    const { onCancel } = props;

    if (onCancel) {
      onCancel();
    }

    hideModal();
  };

  const renderActions = () => (
    <Box sx={styles.actions}>
      {isNil(Actions) ? (
        <Button size="small" onClick={handleCancel}>
          Close
        </Button>
      ) : (
        <Actions />
      )}
    </Box>
  );

  return (
    <Dialog open scroll="body" sx={currentModalLayout}>
      {isNil(title) || (
        <Typography variant="h2" sx={styles.title}>
          {title}
        </Typography>
      )}

      <Content {...props} />

      {isActionsVisible && renderActions()}

      <IconButton sx={styles.hideButton} onClick={handleCancel}>
        <Icon name="x" sx={styles.icon} />
      </IconButton>
    </Dialog>
  );
};

export default Modal;
