import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IMembership } from 'domain/membership/types';

export type IndexParams = {
  clientId: ID;
  teamId: ID;
  queryParams?: TableFilterQueryParams;
};

const TeamMembershipsRepository = {
  index({ clientId, teamId, queryParams }: IndexParams): Promise<CollectionResponse<'memberships', IMembership[]>> {
    const url = apiRoutes.apiSiteV1ClientTeamMembershipsPath(clientId, teamId);
    return Fetcher.get(url, queryParams);
  },
};

export default TeamMembershipsRepository;
