import { ProjectDepartment } from 'domain/project/enums';
import { IProjectNameDetailReportStats, IReportProject } from 'domain/projectNameDetailReport/types';
import { ITeamDetailReport, ITeamDetailUser } from 'domain/teamDetailReport/types';
import { apiRoutes } from 'routes';
import { TableFilterQueryParams } from 'types/tableFilter';
import Fetcher from 'utils/fetcher';

export type IndexParams = {
  clientId: ID;
  queryParams?: TableFilterQueryParams;
};

export type ShowParams = {
  clientId: ID;
  userId: ID;
  department: ProjectDepartment;
  queryParams?: TableFilterQueryParams;
};

export type StatsParams = {
  clientId: ID;
  userId: ID;
  queryParams?: TableFilterQueryParams;
};

export type UsersParams = {
  clientId: ID;
  userId: ID;
  queryParams?: TableFilterQueryParams;
};

const TeamDetailReportRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<ITeamDetailReport> {
    const url = apiRoutes.apiSiteV1ClientReportsMbdTeamsDetailReportPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  users({ clientId, userId, queryParams }: UsersParams): Promise<CollectionResponse<'users', ITeamDetailUser[]>> {
    const url = apiRoutes.usersApiSiteV1ClientReportsUserMbdTeamsDetailReportIndexPath(clientId, userId);
    return Fetcher.get(url, queryParams);
  },
  show({
    clientId,
    userId,
    department,
    queryParams,
  }: ShowParams): Promise<CollectionResponse<'projects', IReportProject[]>> {
    const url = apiRoutes.apiSiteV1ClientReportsUserMbdTeamsDetailReportIndexPath(clientId, userId);
    const params = {
      ...queryParams,
      q: {
        ...queryParams.q,
        departmentEq: department,
      },
    };

    return Fetcher.get(url, params);
  },
  stats({ clientId, userId, queryParams }: StatsParams): Promise<IProjectNameDetailReportStats> {
    const url = apiRoutes.projectsStatsApiSiteV1ClientReportsUserMbdTeamsDetailReportIndexPath(clientId, userId);
    return Fetcher.get(url, queryParams);
  },
};

export default TeamDetailReportRepository;
