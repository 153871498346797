import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiTabPanelType = {
  defaultProps: ComponentsProps['MuiTabPanel'];
  styleOverrides: ComponentsOverrides['MuiTabPanel'];
};

const MuiTabPanel: MuiTabPanelType = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
  defaultProps: {},
};

export default MuiTabPanel;
