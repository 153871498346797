import { makeRoutes } from 'utils/routes';

const paths = makeRoutes(
  {
    rootPath: () => '/*',
    dashboardPath: () => '/dashboard',
    teamsPath: () => '/teams',
    tasksPath: () => '/tasks',
    taskCreatePath: () => '/tasks/create',
    taskEditPath: (taskId: ID) => `/tasks/${taskId}/edit`,
    projectsPath: () => '/projects',
    projectCreatePath: () => `/projects/create`,
    projectPath: (projectId: ID) => `/projects/${projectId}`,
    projectEditPath: (projectId: ID) => `/projects/${projectId}/edit`,
    membersPath: () => '/members',
    memberEditPath: (membershipId: ID) => `/members/${membershipId}/edit`,
    teamPath: (teamId: ID) => `/teams/${teamId}`,
    reportsPath: () => '/reports',
    departmentProductivityReportPath: () => `/reports/department-productivity-report`,
    projectNameDetailReportPath: () => `/reports/project-name-detail-report`,
    teamDetailReportPath: () => '/reports/team-detail-report',
    personalTeamDetailReportPath: (memberId: ID) => `/reports/team-detail-report/${memberId}`,
    attorneyDetailReportPath: () => `/reports/attorney-detail-report`,
    attorneyDetailReportUserPath: (id: ID) => `/reports/attorney-detail-report/${id}`,
    practiceGroupReportPath: () => `/reports/practice-group-report`,
    practiceGroupDetailReportPath: (practiceGroupId: ID) => `/reports/practice-group-report/${practiceGroupId}`,
    teamDetailReport: () => '/reports/team-detail-report',
    personalTeamDetailReport: (memberId: ID) => `/reports/team-detail-report/${memberId}`,
    attorneyDetailReport: () => `/reports/attorney-detail-report`,
    projectTypeReportPath: () => `/reports/project-type-report`,
    companyReportPath: () => '/reports/company-report',
    companyDetailReportPath: (id: ID) => `/reports/company-detail-report/${id}`,
    companiesPath: () => '/companies',
    companyPath: (companyId: ID) => `/companies/${companyId}`,
    companyCreatePath: () => '/companies/create',
    companyEditPath: (companyId: ID) => `/companies/${companyId}/edit`,
    contactsPath: () => '/contacts',
    contactPath: (contactId: ID) => `/contacts/${contactId}`,
    contactCreatePath: () => '/contacts/create',
    contactEditPath: (contactId: ID) => `/contacts/${contactId}/edit`,
    globalSearchPath: () => '/global-search',
    achievementsPath: () => '/achievements',
    calendarEventsPath: () => '/calendar-events',
    calendarEventCreatePath: () => '/calendar-events/create',
    calendarEventEditPath: (eventId: ID) => `/calendar-events/${eventId}/edit`,
    calendarRecurrenceEventEditPath: (eventId: ID, recurrenceEventId: ID) =>
      `/calendar-events/${eventId}/${recurrenceEventId}/edit`,
    calendarEventPath: (calendarEventId: ID) => `/calendar-events/${calendarEventId}`,
    calendarRecurrenceEventPath: (calendarEventId: ID, recurrenceEventId: ID) =>
      `/calendar-events/${calendarEventId}/${recurrenceEventId}`,
    notificationsPath: () => '/notifications',
    notificationCreatePath: () => '/notifications/create',
    notificationEditPath: (notificationId: ID) => `/notifications/${notificationId}/edit`,
    winlossReportPath: () => '/reports/winloss-report',
    awardNominationsReportPath: () => '/reports/award-nominations-report',
  },
  '/mbd',
);

export default paths;
