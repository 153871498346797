export default {
  teamFilterLabel: 'Team',
  workspaceFilterLabel: 'Workspace',
  practiceGroupFilterLabel: 'Practice group',
  columnsHeads: {
    member: 'Member',
    workspace: 'Workspace',
    team: 'Team',
    practiceGroup1: 'Practice Group 1',
    practiceGroup2: 'Practice Group 2',
    invited: 'Invited',
  },
};
