import { createSlice } from '@reduxjs/toolkit';

import { IPracticeGroup } from 'domain/practiceGroup/types';
import { FetchStatus } from 'enums/FetchStatus';
import PracticeGroupsRepository, { IndexParams, ShowParams } from 'repositories/PracticeGroupsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type PracticeGroupsSliceStateType = {
  practiceGroups: IPracticeGroup[];
  practiceGroup: IPracticeGroup;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type PracticeGroupsSliceActionsType = {
  loadPracticeGroupsList: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'practiceGroups', IPracticeGroup[]>>;
  };
  loadPracticeGroup: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<'practiceGroup', IPracticeGroup>> };
  resetPracticeGroups: () => void;
};

const initialState: PracticeGroupsSliceStateType = {
  practiceGroups: [],
  practiceGroup: {} as IPracticeGroup,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadPracticeGroupsList = createAsyncAction('practiceGroups/index', PracticeGroupsRepository.index);
export const loadPracticeGroup = createAsyncAction('practiceGroups/show', PracticeGroupsRepository.show);

const slice = createSlice({
  name: 'practiceGroups',
  initialState,
  reducers: {
    resetPracticeGroups: state => {
      state.practiceGroups = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadPracticeGroupsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadPracticeGroupsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.practiceGroups = payload.practiceGroups;
    });
    builder.addCase(loadPracticeGroupsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadPracticeGroup.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadPracticeGroup.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.practiceGroup = payload.practiceGroup;
    });
    builder.addCase(loadPracticeGroup.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetPracticeGroups },
} = slice;

export { resetPracticeGroups };

export default slice.reducer;
