import { createSlice } from '@reduxjs/toolkit';

import TeamScoreRepository, { IndexParams } from 'repositories/TeamScoreRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import { TeamScore } from 'domain/team/types';

export type TeamScoreSliceStateType = {
  score: TeamScore[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TeamScoreSliceActionsType = {
  loadTeamScore: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'dailyGroupedScores', TeamScore[]>>;
  };
};

const initialState: TeamScoreSliceStateType = {
  score: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTeamScore = createAsyncAction('teamScore/index', TeamScoreRepository.index);

const slice = createSlice({
  name: 'teamScore',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadTeamScore.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTeamScore.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;

      state.score = payload.dailyGroupedScores;
    });
    builder.addCase(loadTeamScore.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
