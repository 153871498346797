import { createSlice } from '@reduxjs/toolkit';

import { IMonthlyProjectsReport } from 'domain/monthlyProjectsReports/types';
import { FetchStatus } from 'enums/FetchStatus';
import MonthlyProjectsReportsRepository, {
  MonthlyProjectsReportsParams,
} from 'repositories/Reports/MonthlyProjectsReportsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type MonthlyProjectsReportsSliceType = {
  monthlyProjectsReports: IMonthlyProjectsReport[];
  show: { fetchStatus: FetchStatus };
};

export type MonthlyProjectsReportsSliceActionsType = {
  loadMonthlyProjectsReports: (params: MonthlyProjectsReportsParams) => {
    unwrap: () => Promise<CollectionResponse<'monthlyProjectReport', IMonthlyProjectsReport[]>>;
  };
};

const initialState: MonthlyProjectsReportsSliceType = {
  monthlyProjectsReports: [],
  show: { fetchStatus: FetchStatus.idle },
};

export const loadMonthlyProjectsReports = createAsyncAction(
  'monthlyProjectsReports/show',
  MonthlyProjectsReportsRepository.show,
);

const slice = createSlice({
  name: 'monthlyProjectsReports',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadMonthlyProjectsReports.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadMonthlyProjectsReports.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.monthlyProjectsReports = payload.monthlyProjectReport;
    });
    builder.addCase(loadMonthlyProjectsReports.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
