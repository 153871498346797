import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiTextFieldType = {
  defaultProps: ComponentsProps['MuiTextField'];
  styleOverrides: ComponentsOverrides['MuiTextField'];
};

const MuiTextField: MuiTextFieldType = {
  styleOverrides: {
    root: {
      width: '223px',
      marginRight: '15px',
      '&.MuiFormControl-fullWidth': {
        width: '100%',
      },
      '&:last-of-type': {
        marginRight: '0',
      },
    },
  },
  defaultProps: {
    variant: 'standard',
    autoComplete: 'off',
    inputProps: {
      required: false,
    },
    fullWidth: true,
  },
};

export default MuiTextField;
