/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isDate } from 'date-fns';
import humps from 'humps';
import { is } from 'ramda';

export const camelizeKeys = (object: any): any => humps.camelizeKeys(object);

const isObject = (obj: any): boolean => is(Object, obj);

const isFile = (obj: any): boolean => obj instanceof File;

const isArray = (obj: any): obj is [] => obj instanceof Array;

export const camelize = (obj: string): string => humps.camelize(obj);

export const decamelize = (obj: any | any[]): any | any[] => {
  if (isArray(obj)) {
    return obj.map(item => decamelize(item));
  }

  if (isObject(obj) && !isFile(obj)) {
    return Object.keys(obj).reduce((acc, next) => {
      const decamelizedKey = humps.decamelize(next);
      const decamelizedValue = isDate(obj[next]) ? obj[next] : decamelize(obj[next]);
      return {
        ...acc,
        [decamelizedKey]: decamelizedValue,
      };
    }, {});
  }
  return obj;
};
