import { ComponentsOverrides } from '@mui/material';

type MuiPaperType = {
  styleOverrides: ComponentsOverrides['MuiPaper'];
};

const MuiPaper: MuiPaperType = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
    },
  },
};

export default MuiPaper;
