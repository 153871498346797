import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import Icon from 'components/Icon';

type MuiRadioType = {
  defaultProps: ComponentsProps['MuiRadio'];
  styleOverrides: ComponentsOverrides['MuiRadio'];
};

const MuiRadio: MuiRadioType = {
  styleOverrides: {
    root: {},
  },
  defaultProps: {
    disableRipple: true,
    checkedIcon: <Icon name="radioButtonChecked" />,
    icon: <Icon name="radioButton" />,
  },
};

export default MuiRadio;
