import { makeRoutes } from 'utils/routes';

const paths = makeRoutes(
  {
    rootPath: () => '/*',
    dashboardPath: () => '/dashboard',
    companiesPath: () => '/companies',
    companyPath: (companyId: ID) => `/companies/${companyId}`,
    companyCreatePath: () => '/companies/create',
    companyEditPath: (companyId: ID) => `/companies/${companyId}/edit`,
    contactsPath: () => '/contacts',
    contactPath: (contactId: ID) => `/contacts/${contactId}`,
    contactCreatePath: () => '/contacts/create',
    contactEditPath: (contactId: ID) => `/contacts/${contactId}/edit`,
    activitiesPath: () => '/activities',
    activityPath: (activityId: ID) => `/activities/${activityId}`,
    activityCreatePath: () => '/activities/create',
    activityEditPath: (activityId: ID) => `/activities/${activityId}/edit`,
    goalsPath: () => '/goals',
    goalCreatePath: () => '/goals/create',
    goalEditPath: (goalId: ID) => `/goals/${goalId}/edit`,
    teamPath: () => '/team',
    notificationsPath: () => '/notifications',
    notificationCreatePath: () => '/notifications/create',
    notificationEditPath: (notificationId: ID) => `/notifications/${notificationId}/edit`,
    globalSearchPath: () => '/global-search',
    achievementsPath: () => '/achievements',
  },
  '/attorney',
);

export default paths;
