import { createSlice } from '@reduxjs/toolkit';

import {
  IDepartmentProductivityMonthlyGroupedReport,
  IDepartmentProductivityReport,
} from 'domain/departmentProductivityReport/types';
import { FetchStatus } from 'enums/FetchStatus';
import DepartmentProductivityReportRepository, {
  IndexParams,
} from 'repositories/Reports/DepartmentProductivityReportRepository.ts';
import { createAsyncAction } from 'utils/createAsyncAction';

export type DepartmentProductivityReportSliceStateType = {
  departmentProductivityReport: IDepartmentProductivityReport;
  monthlyGroupedReport: IDepartmentProductivityMonthlyGroupedReport;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  monthlyGrouped: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type DepartmentProductivityReportSliceActionsType = {
  loadDepartmentProductivityReport: (params: IndexParams) => {
    unwrap: () => Promise<IDepartmentProductivityReport>;
  };
  loadMonthlyGroupedReport: (params: IndexParams) => {
    unwrap: () => Promise<IDepartmentProductivityMonthlyGroupedReport>;
  };
};

const initialState: DepartmentProductivityReportSliceStateType = {
  departmentProductivityReport: {} as IDepartmentProductivityReport,
  monthlyGroupedReport: {} as IDepartmentProductivityMonthlyGroupedReport,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  monthlyGrouped: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadDepartmentProductivityReport = createAsyncAction(
  'departmentProductivityReport/index',
  DepartmentProductivityReportRepository.index,
);
export const loadMonthlyGroupedReport = createAsyncAction(
  'departmentProductivityReport/monthlyGrouped',
  DepartmentProductivityReportRepository.monthlyGrouped,
);

const slice = createSlice({
  name: 'departmentProductivityReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDepartmentProductivityReport.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadDepartmentProductivityReport.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.departmentProductivityReport = payload;
    });
    builder.addCase(loadDepartmentProductivityReport.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });

    builder.addCase(loadMonthlyGroupedReport.pending, state => {
      state.monthlyGrouped.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadMonthlyGroupedReport.fulfilled, (state, { payload }) => {
      state.monthlyGrouped.fetchStatus = FetchStatus.fulfilled;
      state.monthlyGroupedReport = payload;
    });
    builder.addCase(loadMonthlyGroupedReport.rejected, state => {
      state.monthlyGrouped.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
