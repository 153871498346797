import { ProjectDepartment } from 'domain/project/enums';
import { IReportProject, IPracticeGroupDetailReportProjectStats } from 'domain/practiceGroupDetailReport/types';
import { apiRoutes } from 'routes';
import { TableFilterQueryParams } from 'types/tableFilter';
import Fetcher from 'utils/fetcher';

export type ProjectStatsParams = {
  clientId: ID;
  practiceGroupId: ID;
  queryParams?: TableFilterQueryParams;
};

export type ProjectIndexParams = {
  clientId: ID;
  practiceGroupId: ID;
  department: ProjectDepartment;
  queryParams?: TableFilterQueryParams;
};

const DetailedPracticeGroupReportRepository = {
  projects({
    clientId,
    practiceGroupId,
    department,
    queryParams,
  }: ProjectIndexParams): Promise<CollectionResponse<'projects', IReportProject[]>> {
    const params = {
      ...queryParams,
      q: {
        ...queryParams.q,
        departmentEq: department,
      },
    };
    const url = apiRoutes.projectsApiSiteV1ClientReportsPracticeGroupDetailedPracticeGroupReportsPath(
      clientId,
      practiceGroupId,
    );

    return Fetcher.get(url, params);
  },
  projectStats({
    clientId,
    practiceGroupId,
    queryParams,
  }: ProjectStatsParams): Promise<IPracticeGroupDetailReportProjectStats> {
    const url = apiRoutes.projectStatsApiSiteV1ClientReportsPracticeGroupDetailedPracticeGroupReportsPath(
      clientId,
      practiceGroupId,
    );

    return Fetcher.get(url, queryParams);
  },
};

export default DetailedPracticeGroupReportRepository;
