import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import ProjectAuditsRepository, {
  IndexParams,
  CreateParams,
  ActionParams,
} from 'repositories/Project/ProjectAuditsRepository';
import { IProjectAudit } from 'domain/projectAudit/types';

export type ProjectAuditsSliceStateType = {
  projectAudits: IProjectAudit[];
  meta: ResponseMeta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ProjectAuditsSliceActionsType = {
  loadProjectAuditsList: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'audits', IProjectAudit[]>>;
  };
  createProjectAudit: (params: CreateParams) => { unwrap: () => void };
  deleteProjectAudit: (params: ActionParams) => { unwrap: () => void };
  resetProjectAudits: () => void;
};

const initialState: ProjectAuditsSliceStateType = {
  projectAudits: [],
  meta: { perPage: 8 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadProjectAuditsList = createAsyncAction('audits/index', ProjectAuditsRepository.index);
export const createProjectAudit = createAsyncAction('audits/create', ProjectAuditsRepository.create);
export const deleteProjectAudit = createAsyncAction('projectNotes/delete', ProjectAuditsRepository.delete);

const slice = createSlice({
  name: 'projectAudits',
  initialState,
  reducers: {
    resetProjectAudits: state => {
      state.projectAudits = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadProjectAuditsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadProjectAuditsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.projectAudits = payload.audits;
      state.meta = payload.meta;
    });
    builder.addCase(loadProjectAuditsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetProjectAudits },
} = slice;

export { resetProjectAudits };

export default slice.reducer;
