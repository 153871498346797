import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiTabType = {
  defaultProps: ComponentsProps['MuiTab'];
  styleOverrides: ComponentsOverrides['MuiTab'];
};

const MuiTab: MuiTabType = {
  styleOverrides: {
    root: {
      textTransform: 'capitalize',
      fontSize: '14px',
      lineHeight: '14px',
      padding: '0 5px',
      color: palette.dark.light,
    },
    textColorPrimary: {
      '&.Mui-selected': {
        color: palette.dark.main,
      },
    },
  },
  defaultProps: {},
};

export default MuiTab;
