export default {
  seeDetails: 'See Details',
  markAsRead: 'Mark as read',
  markAsUnread: 'Mark as unread',
  detail: 'Detail',
  columnTitle: {
    notification: 'Notification',
    relatedTo: 'Related To',
    date: 'Date',
    author: 'Author',
  },
};
