import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiFormControlType = {
  defaultProps: ComponentsProps['MuiFormControl'];
  styleOverrides: ComponentsOverrides['MuiFormControl'];
};

const MuiFormControl: MuiFormControlType = {
  styleOverrides: {
    root: {
      position: 'relative',
    },
  },
  defaultProps: {},
};

export default MuiFormControl;
