import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { ITeam } from 'domain/team/types';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type ShowParams = {
  clientId: ID;
  teamId: ID;
};

const TeamsRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'teams', ITeam[]>> {
    const url = apiRoutes.apiSiteV1ClientTeamsPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  show({ clientId, teamId }: ShowParams): Promise<SingleResponse<'team', ITeam>> {
    const url = apiRoutes.apiSiteV1ClientTeamPath(clientId, teamId);
    return Fetcher.get(url);
  },
};

export default TeamsRepository;
