export default {
  dueDateFilterLabel: 'Due Date',
  winlossFilterLabel: 'Filter by Win/Loss Value',
  companyFilterLabel: 'Filter by Company Name',
  projectFilterLabel: 'Filter by Project Name',
  industryFilterLabel: 'Filter by Industry',
  practiceGroupFilterLabel: 'Filter by Practice Group',
  purposesFilterLabel: 'Filter by Type',
  attorneyFilterLabel: 'Filter by Attorney',
  export: 'Export',
  wonRevenueLabel: 'Anticipated Revenue',
  lostRevenueLabel: 'Lost Revenue',
  totalRevenueLabel: 'Revenue',
  spendLabel: 'Total Spend',
  columnsHeads: {
    companyName: 'Company name',
    winLoss: 'Win/Loss',
    projectName: 'Project name',
    industry: 'Industry',
    practiceGroups: 'Practice Groups',
    type: 'Type',
    attorneys: 'Attorneys',
    hours: 'Hours',
    totalSpend: 'Total Spend',
    anticipatedRevenue: 'Anticipated Revenue',
  },
};
