export default {
  title: 'Sign in to your account',
  userName: {
    label: 'Username',
    placeholder: 'Enter your Username',
  },
  password: {
    label: 'Password',
    placeholder: 'Enter your Password',
  },
  submit: 'Log In',
  forgotPassword: 'Forgot password?',
  azure: 'Log In with Azure AD',
  dividerText: 'or',
  azureLoginError: 'Something went wrong',
};
