import { takeLast } from 'ramda';

import address from 'assets/icons/address.svg';
import arrow from 'assets/icons/arrow.svg';
import attach from 'assets/icons/attach.svg';
import briefcase from 'assets/icons/briefcase.svg';
import calendar from 'assets/icons/calendar.svg';
import caret from 'assets/icons/caret.svg';
import chart from 'assets/icons/chart.svg';
import chartFilled from 'assets/icons/chartFilled.svg';
import chat from 'assets/icons/chat.svg';
import check from 'assets/icons/check.svg';
import columnsFilled from 'assets/icons/columnsFilled.svg';
import columnsOutline from 'assets/icons/columnsOutline.svg';
import communication from 'assets/icons/communication.svg';
import dashboard from 'assets/icons/dashboard.svg';
import doubleArrow from 'assets/icons/doubleArrow.svg';
import edit from 'assets/icons/edit.svg';
import envelope from 'assets/icons/envelope.svg';
import file from 'assets/icons/file.svg';
import group from 'assets/icons/group.svg';
import list from 'assets/icons/list.svg';
import listFilled from 'assets/icons/listFilled.svg';
import lock from 'assets/icons/lock.svg';
import simpleLock from 'assets/icons/simpleLock.svg';
import logout from 'assets/icons/logout.svg';
import marketing from 'assets/icons/marketing.svg';
import medal from 'assets/icons/medal.svg';
import more from 'assets/icons/more.svg';
import moreFilled from 'assets/icons/moreFilled.svg';
import notification from 'assets/icons/notification.svg';
import phone from 'assets/icons/phone.svg';
import plane from 'assets/icons/plane.svg';
import print from 'assets/icons/print.svg';
import settings from 'assets/icons/settings.svg';
import plus from 'assets/icons/plus.svg';
import trash from 'assets/icons/trash.svg';
import uploadFile from 'assets/icons/uploadFile.svg';
import addUser from 'assets/icons/addUser.svg';
import visibility from 'assets/icons/visibility.svg';
import visibilityOff from 'assets/icons/visibilityOff.svg';
import warning from 'assets/icons/warning.svg';
import website from 'assets/icons/website.svg';
import loupe from 'assets/icons/loupe.svg';
import sorter from 'assets/icons/sorter.svg';
import checkbox from 'assets/icons/checkbox.svg';
import checkboxEmpty from 'assets/icons/checkboxEmpty.svg';
import checkboxIndeterminate from 'assets/icons/checkboxIndeterminate.svg';
import chevron from 'assets/icons/chevron.svg';
import download from 'assets/icons/download.svg';
import infoCircle from 'assets/icons/infoCircle.svg';
import x from 'assets/icons/x.svg';
import plusCircle from 'assets/icons/plusCircle.svg';
import info from 'assets/icons/info.svg';
import checkCircle from 'assets/icons/checkCircle.svg';
import checkCircleFilled from 'assets/icons/checkCircleFilled.svg';
import clock from 'assets/icons/clock.svg';
import play from 'assets/icons/play.svg';
import leaderboardGold from 'assets/icons/leaderboardGold.svg';
import leaderboardSilver from 'assets/icons/leaderboardSilver.svg';
import leaderboardBronze from 'assets/icons/leaderboardBronze.svg';
import arrowDropdown from 'assets/icons/arrowDropdown.svg';
import expandArrow from 'assets/icons/expandArrow.svg';
import currency from 'assets/icons/currency.svg';
import percent from 'assets/icons/percent.svg';
import filter from 'assets/icons/filter.svg';
import exportReport from 'assets/icons/exportReport.svg';
import importIcon from 'assets/icons/import.svg';
import exportIcon from 'assets/icons/export.svg';
import blocks from 'assets/icons/blocks.svg';
import arrowCrumb from 'assets/icons/arrowCrumb.svg';
import leftArrow from 'assets/icons/leftArrow.svg';
import rightArrow from 'assets/icons/rightArrow.svg';
import tableView from 'assets/icons/tableView.svg';
import tableViewActive from 'assets/icons/tableViewActive.svg';
import kanbanView from 'assets/icons/kanbanView.svg';
import kanbanViewActive from 'assets/icons/kanbanViewActive.svg';
import diagramView from 'assets/icons/diagramView.svg';
import diagramViewActive from 'assets/icons/diagramViewActive.svg';
import radioButton from 'assets/icons/radioButton.svg';
import radioButtonChecked from 'assets/icons/radioButtonChecked.svg';
import listViewIcon from 'assets/icons/listView.svg';
import starIcon from 'assets/icons/star.svg';
import cupIcon from 'assets/icons/cup.svg';
import cupEditIcon from 'assets/icons/cupEdit.svg';

type IconDimensions = {
  width: string;
  height: string;
};

const getIconDimensions = (viewbox: string): IconDimensions => {
  const [width, height] = takeLast(2, viewbox.split(' '));

  return {
    width,
    height,
  };
};

interface BrowserSpriteSymbol {
  id: string;
  viewBox: string;
  content: string;
  node: SVGSymbolElement;
}

type IconAttributes = {
  src: BrowserSpriteSymbol;
  viewBox: string;
  id: string;
  width: string;
  height: string;
};

const getIconAttributes = (name: BrowserSpriteSymbol): IconAttributes => {
  const { width, height } = getIconDimensions(name.viewBox);

  return {
    src: name,
    viewBox: name.viewBox,
    id: `#${name.id}`,
    width,
    height,
  };
};

const dictonary = {
  address: getIconAttributes(address),
  arrow: getIconAttributes(arrow),
  attach: getIconAttributes(attach),
  briefcase: getIconAttributes(briefcase),
  calendar: getIconAttributes(calendar),
  caret: getIconAttributes(caret),
  chart: getIconAttributes(chart),
  chartFilled: getIconAttributes(chartFilled),
  chat: getIconAttributes(chat),
  check: getIconAttributes(check),
  columnsFilled: getIconAttributes(columnsFilled),
  columnsOutline: getIconAttributes(columnsOutline),
  communication: getIconAttributes(communication),
  dashboard: getIconAttributes(dashboard),
  doubleArrow: getIconAttributes(doubleArrow),
  edit: getIconAttributes(edit),
  envelope: getIconAttributes(envelope),
  file: getIconAttributes(file),
  group: getIconAttributes(group),
  list: getIconAttributes(list),
  listFilled: getIconAttributes(listFilled),
  lock: getIconAttributes(lock),
  simpleLock: getIconAttributes(simpleLock),
  logout: getIconAttributes(logout),
  marketing: getIconAttributes(marketing),
  medal: getIconAttributes(medal),
  more: getIconAttributes(more),
  moreFilled: getIconAttributes(moreFilled),
  notification: getIconAttributes(notification),
  phone: getIconAttributes(phone),
  plane: getIconAttributes(plane),
  print: getIconAttributes(print),
  plus: getIconAttributes(plus),
  settings: getIconAttributes(settings),
  trash: getIconAttributes(trash),
  uploadFile: getIconAttributes(uploadFile),
  addUser: getIconAttributes(addUser),
  visibility: getIconAttributes(visibility),
  visibilityOff: getIconAttributes(visibilityOff),
  warning: getIconAttributes(warning),
  website: getIconAttributes(website),
  loupe: getIconAttributes(loupe),
  sorter: getIconAttributes(sorter),
  checkbox: getIconAttributes(checkbox),
  checkboxEmpty: getIconAttributes(checkboxEmpty),
  checkboxIndeterminate: getIconAttributes(checkboxIndeterminate),
  chevron: getIconAttributes(chevron),
  download: getIconAttributes(download),
  infoCircle: getIconAttributes(infoCircle),
  x: getIconAttributes(x),
  plusCircle: getIconAttributes(plusCircle),
  info: getIconAttributes(info),
  checkCircle: getIconAttributes(checkCircle),
  checkCircleFilled: getIconAttributes(checkCircleFilled),
  clock: getIconAttributes(clock),
  play: getIconAttributes(play),
  leaderboardGold: getIconAttributes(leaderboardGold),
  leaderboardSilver: getIconAttributes(leaderboardSilver),
  leaderboardBronze: getIconAttributes(leaderboardBronze),
  arrowDropdown: getIconAttributes(arrowDropdown),
  expandArrow: getIconAttributes(expandArrow),
  currency: getIconAttributes(currency),
  percent: getIconAttributes(percent),
  filter: getIconAttributes(filter),
  exportReport: getIconAttributes(exportReport),
  import: getIconAttributes(importIcon),
  export: getIconAttributes(exportIcon),
  blocks: getIconAttributes(blocks),
  arrowCrumb: getIconAttributes(arrowCrumb),
  tableView: getIconAttributes(tableView),
  tableViewActive: getIconAttributes(tableViewActive),
  kanbanView: getIconAttributes(kanbanView),
  kanbanViewActive: getIconAttributes(kanbanViewActive),
  diagramView: getIconAttributes(diagramView),
  diagramViewActive: getIconAttributes(diagramViewActive),
  leftArrow: getIconAttributes(leftArrow),
  rightArrow: getIconAttributes(rightArrow),
  radioButton: getIconAttributes(radioButton),
  radioButtonChecked: getIconAttributes(radioButtonChecked),
  listView: getIconAttributes(listViewIcon),
  star: getIconAttributes(starIcon),
  cup: getIconAttributes(cupIcon),
  cupEdit: getIconAttributes(cupEditIcon),
};

export type IconsDictionary = keyof typeof dictonary;

export default dictonary;
