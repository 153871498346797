export default {
  title: 'Uncompleted items found',
  completedAtTitle: 'Completed date',
  count: 'You have ({{ count }}) ToDos opened in',
  question: 'Do you want to complete them all?',
  acceptButton: 'Yes',
  declineButton: 'No',
  completeError: 'You cannot change status',
  descriptionWithCompleteDate:
    'By agreeing, you are assigning all unassigned ToDos to yourself and marking the project and uncompleted ToDos complete as of the selected date.',
  descriptionWithoutCompleteDate:
    'By agreeing, you are assigning all unassigned ToDos to yourself and marking the project and uncompleted ToDos complete as of the today’s date.',
  dueDateBehaviorDescription:
    'Additionally, any ToDos without a Due Date will have a Due Date automatically set as today’s date.',
};
