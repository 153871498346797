import { createSlice } from '@reduxjs/toolkit';

import { ProjectDepartment } from 'domain/project/enums';
import { FetchStatus } from 'enums/FetchStatus';
import AttorneyDetailReportRepository, {
  AttorneyUserDetailReportUserParams,
  ProjectsTimeSpentParams,
  StatsParams,
} from 'repositories/Reports/AttorneyDetailReportRepository';
import { createAsyncAction } from 'utils/createAsyncAction';
import {
  IAttorneyDetailReportUser,
  IAttorneyDetailReportUserStatsResponse,
  IAttorneyDetailReportUserStats,
  IAttorneyDetailReportUserProjectsTypeTimeSpentResponse,
  IAttorneyDetailReportUserProjectsTypeTimeSpent,
} from 'domain/attorneyDetailReport/types';

type AttorneyDetailReportUserItem = {
  data: IAttorneyDetailReportUser[];
  meta: ResponseMeta;
  fetchStatus: FetchStatus;
};

export type AttorneyDetailReportUserStateType = Record<ProjectDepartment, AttorneyDetailReportUserItem> & {
  stats: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  reportStatsData: IAttorneyDetailReportUserStats;
  projectsTimeSpent: IAttorneyDetailReportUserProjectsTypeTimeSpent;
  projectsTimeSpentState: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type AttorneyDetailReportUserSliceActionsType = {
  loadAttorneyDetailReportUser: (params: AttorneyUserDetailReportUserParams) => {
    unwrap: () => Promise<CollectionResponse<'projects', IAttorneyDetailReportUser[]>>;
  };
  loadAttorneyDetailReportUserStats: (params: StatsParams) => {
    unwrap: () => Promise<IAttorneyDetailReportUserStatsResponse>;
  };
  loadAttorneyDetailReportUserProjectTimeSpent: (params: ProjectsTimeSpentParams) => {
    unwrap: () => Promise<IAttorneyDetailReportUserProjectsTypeTimeSpentResponse>;
  };
};

const initialState: AttorneyDetailReportUserStateType = {
  [ProjectDepartment.businessDevelopment]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  [ProjectDepartment.communications]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  [ProjectDepartment.marketing]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  reportStatsData: {} as IAttorneyDetailReportUserStats,
  stats: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  projectsTimeSpent: {} as IAttorneyDetailReportUserProjectsTypeTimeSpent,
  projectsTimeSpentState: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadAttorneyDetailReportUser = createAsyncAction(
  'attorneyDetailReport/user',
  AttorneyDetailReportRepository.user,
);
export const loadAttorneyDetailReportUserStats = createAsyncAction(
  'attorneyDetailReport/stats',
  AttorneyDetailReportRepository.stats,
);
export const loadAttorneyDetailReportUserProjectTimeSpent = createAsyncAction(
  'attorneyDetailReport/projectsTimeSpent',
  AttorneyDetailReportRepository.projectsTimeSpent,
);

const slice = createSlice({
  name: 'attorneyDetailReportUser',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadAttorneyDetailReportUser.pending, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadAttorneyDetailReportUser.fulfilled, (state, action) => {
      const { department } = action.meta.arg;
      state[department].fetchStatus = FetchStatus.fulfilled;
      state[department].data = action.payload.projects;
      state[department].meta = action.payload.meta;
    });
    builder.addCase(loadAttorneyDetailReportUser.rejected, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.fulfilled;
    });

    builder.addCase(loadAttorneyDetailReportUserStats.pending, state => {
      state.stats.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadAttorneyDetailReportUserStats.fulfilled, (state, { payload }) => {
      state.reportStatsData = payload.attorneyProjectsStats;
      state.stats.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadAttorneyDetailReportUserStats.rejected, state => {
      state.stats.fetchStatus = FetchStatus.rejected;
    });

    builder.addCase(loadAttorneyDetailReportUserProjectTimeSpent.pending, state => {
      state.projectsTimeSpentState.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadAttorneyDetailReportUserProjectTimeSpent.fulfilled, (state, { payload }) => {
      state.projectsTimeSpent = payload.attorneyProjectsTypeTimeSpent;
      state.projectsTimeSpentState.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadAttorneyDetailReportUserProjectTimeSpent.rejected, state => {
      state.projectsTimeSpentState.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
