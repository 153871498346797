/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'qs';
import { isEmpty, lensPath, path, set } from 'ramda';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { serialize } from 'object-to-formdata';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { decamelizeKeys } from 'humps';

import history from 'utils/history';
import { camelizeKeys, decamelize } from 'utils/keysConverter';
import { isUnauthorizedError } from 'utils/errors';
import { appRoutes } from 'routes';
import { HttpStatusCode } from 'enums/HttpStatusCode';

import { isCurrentLocationOneOf } from './location';
import { decamelizeString } from './string';

export const axiosInstance = axios.create({
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});
export const axiosFormDataInstance = axios.create({
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

const publicPaths = [
  appRoutes.public.passwordRecovery(),
  appRoutes.public.passwordReset(),
  appRoutes.public.profileEditPath(),
  appRoutes.public.rootPath(),
  appRoutes.public.signIn(),
];

const handleUnauthorizedError = (error: any) => {
  const {
    response: { status },
  } = error;

  const isPublicPath = publicPaths.includes(window.location.pathname);

  if (!isPublicPath && status === HttpStatusCode.unauthorized) {
    return window.location.replace(appRoutes.public.signIn());
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(response => response, handleUnauthorizedError);
axiosFormDataInstance.interceptors.response.use(response => response, handleUnauthorizedError);

export const authenticityToken = (): string | null => {
  const token: HTMLMetaElement = document.querySelector('meta[name="csrf-token"]');
  return token ? token.content : null;
};

axiosFormDataInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
axiosFormDataInstance.defaults.headers.common['X-CSRF-Token'] = authenticityToken();
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['X-CSRF-Token'] = authenticityToken();

axiosInstance.interceptors.response.use(null, error => {
  if (isUnauthorizedError(error)) {
    // isCurrentLocationOneOf accept array of locations
    const shouldRedirectToRoot = isCurrentLocationOneOf([]);

    if (shouldRedirectToRoot) {
      history.push(appRoutes.public.rootPath());
    }
  }

  return Promise.reject(error);
});

const handleResponse = ({ data }: AxiosResponse) => {
  return isEmpty(data) ? {} : camelizeKeys(data);
};

const handleError = (axiosError: AxiosError) => {
  const errorsPath = ['response', 'data', 'errors'];
  const axiosErrorResponseWithCamelizedErrors = set(
    lensPath(errorsPath),
    camelizeKeys(path(errorsPath, axiosError)),
    axiosError,
  );

  return Promise.reject(axiosErrorResponseWithCamelizedErrors);
};

const toFormData = <D>(data: D) => {
  const decamelizedParams = decamelize(data);
  return serialize(decamelizedParams, { indices: true, allowEmptyArrays: true });
};

const Fetcher = {
  get: <R>(url: string, params?: unknown): Promise<R> =>
    axiosInstance
      .get(url, { params: params ? decamelize(params) : null })
      .then(handleResponse)
      .catch(handleError),
  post: <D, R>(url: string, data?: D): Promise<R> =>
    axiosInstance.post(url, decamelize(data)).then(handleResponse).catch(handleError),
  put: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance.put(url, decamelize(data)).then(handleResponse).catch(handleError),
  patch: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance.patch(url, decamelize(data)).then(handleResponse).catch(handleError),
  delete: (url: string): Promise<null> => axiosInstance.delete(url).then(handleResponse).catch(handleError),
  postFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axiosFormDataInstance.post(url, formData).then(handleResponse).catch(handleError);
  },
  putFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axiosFormDataInstance.put(url, formData).then(handleResponse).catch(handleError);
  },
  patchFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axiosFormDataInstance.patch(url, formData).then(handleResponse).catch(handleError);
  },
};

export const paramsToNestedResourcesList = (params: Record<string, unknown>): string => {
  const joinedParams = Object.entries(params)
    .map(param => `"${param.join(`"%3A"`)}"`)
    .join('%2C');
  return `filter=%7B${joinedParams}%7D`;
};

export const createSortRuleForApi = (orderBy: string, orderDirection: string): string => {
  const sortRuleForApi = isEmpty(orderBy) ? '' : `${decamelizeString(orderBy)} ${orderDirection}`;

  return sortRuleForApi;
};

export const baseQueryWithCamelize = ({ baseUrl } = { baseUrl: '' }): BaseQueryFn => {
  const baseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions = {}) => {
    const baseQueryFn = await fetchBaseQuery({
      baseUrl,
      paramsSerializer: params => qs.stringify(decamelize(params), { arrayFormat: 'brackets' }),
      prepareHeaders: headers => {
        headers.set('X-CSRF-Token', authenticityToken());
        return headers;
      },
    });

    if (args.body) {
      args.body = decamelizeKeys(args.body);
    }

    const result = await baseQueryFn(args, api, extraOptions);

    if (result.data) {
      result.data = camelizeKeys(result.data as any);
    }

    return result;
  };

  return baseQuery;
};

export default Fetcher;
