import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiCssBaselineType = {
  defaultProps: ComponentsProps['MuiCssBaseline'];
  styleOverrides: ComponentsOverrides['MuiCssBaseline'];
};

const MuiCssBaseline: MuiCssBaselineType = {
  styleOverrides: {
    body: {
      whiteSpace: 'unset',
    },
    '@page': {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  defaultProps: {},
};

export default MuiCssBaseline;
