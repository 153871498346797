export const enum UserRole {
  attorney = 'attorney',
  marketeer = 'marketeer',
  administrator = 'administrator',
  clientAdmin = 'client_admin',
  executiveAdmin = 'exec_admin',
}

export enum UserRoleView {
  attorney = 'Attorney',
  marketeer = 'MBD',
}

export const enum UserType {
  NonLoginUser = 'NonLoginUser',
  LoginUser = 'LoginUser',
}
