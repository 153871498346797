import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiPaginationType = {
  defaultProps: ComponentsProps['MuiPagination'];
  styleOverrides: ComponentsOverrides['MuiPagination'];
};

const MuiPagination: MuiPaginationType = {
  styleOverrides: {
    root: {
      marginTop: '24px',
      '& .MuiPaginationItem-root': {
        color: palette.black.main,
        fontSize: '13px',
        margin: '0 4px',
        border: `1px solid ${palette.gray.main}`,
        '&.Mui-selected': {
          borderColor: palette.green.main,
          backgroundColor: 'transparent',
        },
      },
      '& .MuiPaginationItem-ellipsis': {
        border: 'none',
      },
      '& svg': {
        strokeWidth: '0',
      },
    },
  },
  defaultProps: {
    variant: 'outlined',
    shape: 'rounded',
  },
};

export default MuiPagination;
