import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import {
  IClientNotification,
  IClientNotificationResponse,
  IClientNotificationsResponse,
} from 'domain/clientNotifications/types';
import NotificationsRepository, {
  IndexParams,
  DeleteParams,
  ShowParams,
  UpdateParams,
  CreateParams,
} from 'repositories/Client/NotificationsRepository';

export type NotificationsSliceStateType = {
  notifications: IClientNotification[];
  meta: ResponseMeta;
  notification: IClientNotification;
  index: {
    fetchStatus: FetchStatus;
    error: null;
  };
  create: {
    fetchStatus: FetchStatus;
    error: null;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: null;
  };
  show: {
    fetchStatus: FetchStatus;
    error: null;
  };
  update: {
    fetchStatus: FetchStatus;
    error: null;
  };
};

export type NotificationsSliceActionsType = {
  loadNotificationsList: (params: IndexParams) => { unwrap: () => Promise<IClientNotificationsResponse> };
  createNotification: (params: CreateParams) => { unwrap: () => Promise<IClientNotification> };
  deleteNotification: (params: DeleteParams) => { unwrap: () => Promise<IClientNotification> };
  showNotification: (params: ShowParams) => { unwrap: () => Promise<IClientNotificationResponse> };
  updateNotification: (params: UpdateParams) => { unwrap: () => Promise<IClientNotification> };
  resetNotification: () => void;
};

const initialState: NotificationsSliceStateType = {
  notifications: [],
  notification: null,
  meta: null,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadNotificationsList = createAsyncAction('notifications/index', NotificationsRepository.index);
export const createNotification = createAsyncAction('notifications/create', NotificationsRepository.create);
export const deleteNotification = createAsyncAction('notification/delete', NotificationsRepository.delete);
export const showNotification = createAsyncAction('notifications/show', NotificationsRepository.show);
export const updateNotification = createAsyncAction('notifications/update', NotificationsRepository.update);

const slice = createSlice({
  name: 'clientNotifications',
  initialState,
  reducers: {
    resetNotification: state => {
      state.notification = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadNotificationsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadNotificationsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadNotificationsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.notifications = payload.notifications;
      state.meta = payload.meta;
    });
    builder.addCase(createNotification.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createNotification.rejected, state => {
      state.create.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(createNotification.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteNotification.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteNotification.rejected, state => {
      state.delete.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(deleteNotification.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(showNotification.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showNotification.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showNotification.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.notification = payload.notification;
    });
    builder.addCase(updateNotification.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateNotification.rejected, state => {
      state.update.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(updateNotification.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
  },
});

const { resetNotification } = slice.actions;

export { resetNotification };
export default slice.reducer;
