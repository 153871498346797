import { createSlice } from '@reduxjs/toolkit';

import { IGoal } from 'domain/goal/types';
import { FetchStatus } from 'enums/FetchStatus';
import GoalsRepository, {
  CreateParams,
  IndexParams,
  ShowParams,
  UpdateParams,
  CompleteParams,
} from 'repositories/GoalsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type GoalsSliceStateType = {
  goals: IGoal[];
  meta: ResponseMeta;
  goal: IGoal;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  complete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type GoalsSliceActionsType = {
  loadGoalsList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'goals', IGoal[]>> };
  createGoal: (params: CreateParams) => { unwrap: () => void };
  updateGoal: (params: UpdateParams) => { unwrap: () => void };
  showGoal: (params: ShowParams) => { unwrap: () => void };
  completeGoal: (params: CompleteParams) => { unwrap: () => void };
  exportGoalsList: (params: IndexParams) => { unwrap: () => void };
};

const initialState: GoalsSliceStateType = {
  goals: [],
  meta: { perPage: 8 } as ResponseMeta,
  goal: {} as IGoal,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  complete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadGoalsList = createAsyncAction('goals/index', GoalsRepository.index);
export const createGoal = createAsyncAction('goals/create', GoalsRepository.create);
export const updateGoal = createAsyncAction('goals/update', GoalsRepository.update);
export const showGoal = createAsyncAction('goals/show', GoalsRepository.show);
export const completeGoal = createAsyncAction('goals/complete', GoalsRepository.complete);
export const exportGoalsList = createAsyncAction('goals/export', GoalsRepository.export);

const slice = createSlice({
  name: 'goals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadGoalsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadGoalsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.goals = payload.goals;
      state.meta = payload.meta;
    });
    builder.addCase(loadGoalsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showGoal.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showGoal.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.goal = payload.goal;
    });
    builder.addCase(showGoal.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(completeGoal.pending, state => {
      state.complete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(completeGoal.fulfilled, (state, { payload }) => {
      state.complete.fetchStatus = FetchStatus.fulfilled;
      const goalId = payload.goal.id;
      const goalState = payload.goal.state;
      const currentGoal = state.goals.find(goal => goal.id === goalId);

      currentGoal.state = goalState;

      return state;
    });
    builder.addCase(completeGoal.rejected, state => {
      state.complete.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
