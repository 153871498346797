import { FetchStatus } from 'enums/FetchStatus';

type FetchStatusResponse = {
  isIdle: boolean;
  isPending: boolean;
  isFulfilled: boolean;
  isFailed: boolean;
  isFinished: boolean;
};

const getFetchStatus = (status: FetchStatus): FetchStatusResponse => {
  const isIdle = status === FetchStatus.idle;
  const isPending = status === FetchStatus.pending;
  const isFulfilled = status === FetchStatus.fulfilled;
  const isFailed = status === FetchStatus.rejected;

  return {
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isFinished: isFulfilled || isFailed,
  };
};

export default getFetchStatus;
