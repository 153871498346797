import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { ICompany } from 'domain/company/types';
import { TableFilterQueryParams } from 'types/tableFilter';
import { CompanyFormDataToSubmit } from 'forms/companyForm';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type CreateParams = {
  clientId: ID;
  params: CompanyFormDataToSubmit;
};

export type UpdateParams = {
  clientId: ID;
  companyId: ID;
  params: CompanyFormDataToSubmit;
};

export type ShowParams = {
  companyId: ID;
  clientId: ID;
};

const CompaniesRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'companies', ICompany[]>> {
    const url = apiRoutes.apiSiteV1ClientCompaniesPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  create({ clientId, params }: CreateParams): Promise<SingleResponse<'company', ICompany>> {
    const url = apiRoutes.apiSiteV1ClientCompaniesPath(clientId);
    return Fetcher.postFormData(url, params);
  },
  update({ clientId, companyId, params }: UpdateParams): Promise<SingleResponse<'company', ICompany>> {
    const url = apiRoutes.apiSiteV1ClientCompanyPath(clientId, companyId);
    return Fetcher.putFormData(url, params);
  },
  show({ clientId, companyId }: ShowParams): Promise<SingleResponse<'company', ICompany>> {
    const url = apiRoutes.apiSiteV1ClientCompanyPath(clientId, companyId);
    return Fetcher.get(url);
  },
};

export default CompaniesRepository;
