import { TypographyVariantsOptions } from '@mui/material';

const typography: TypographyVariantsOptions = {
  fontFamily: 'Inter, Arial',
  h1: {
    fontWeight: '700',
    fontSize: '36px',
    lineHeight: '36px',
  },
  h2: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '24px',
  },
  h3: {
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: '500',
    marginBottom: '32px',
  },
  h4: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  h5: {
    fontWeight: '500',
    fontSize: '15px',
  },
  h6: {
    fontSize: '14px',
    fontWeight: '500',
  },
  subtitle1: {},
  subtitle2: {},
  body1: {
    fontSize: '13px',
    fontWeight: '400',
    whiteSpace: 'pre',
  },
  body2: {
    fontSize: '13px',
    fontWeight: '400',
    marginBottom: '10px',
    whiteSpace: 'normal',
  },
  caption: {
    fontWeight: '400',
    fontSize: '13px',
    opacity: '0.6',
  },
  button: {},
  overline: {},
};

export default typography;
