import { decamelize, camelize } from 'humps';

export const getFirstLetter = (string: string): string => {
  if (!string) {
    return '';
  }

  return string.toUpperCase().charAt(0);
};

export const capitalizeString = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const decamelizeString = (string: string, separator = '_'): string => {
  return decamelize(string, { separator });
};

export const camelizeString = (string: string): string => {
  return camelize(string);
};

export const splitFileName = (fullName: string): { fileName: string; extension: string } => {
  const [extension] = fullName.split('.').reverse();
  const fileName = fullName.substring(0, fullName.length - extension.length - 1);

  return {
    fileName,
    extension,
  };
};

export const clearExtraSpaces = (text: string): string => {
  if (!text) {
    return null;
  }

  text = text.trim().replace(/\s\s+/g, ' ');

  return text;
};
