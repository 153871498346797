import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiInputType = {
  defaultProps: ComponentsProps['MuiInput'];
  styleOverrides: ComponentsOverrides['MuiInput'];
};

const MuiInput: MuiInputType = {
  styleOverrides: {
    root: {
      position: 'relative',
      color: palette.black.main,
      fontSize: '13px',
      boxShadow: `0 0 0 1px ${palette.dark.lighter}`,
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '11px 16px',
      height: '46px',
      maxWidth: '510px',
      backgroundColor: palette.orange.light,
      border: '1px solid transparent',
      '&&': {
        margin: 0,
      },
      '&.Mui-focused > .MuiSelect-select': {
        background: 'none',
      },
      '&.Mui-focused': {
        borderColor: palette.orange.main,
        boxShadow: `0px 0px 0px 5px ${palette.orange.lighter}`,
        '&.Mui-error': {
          borderColor: palette.red.main,
        },
      },
      '&.Mui-disabled': {
        color: palette.black.semiLight,
        backgroundColor: palette.gray.light,
        '& .MuiInput-input': {
          textFillColor: palette.black.semiLight,
        },
      },
      '&.Mui-error': {
        borderColor: palette.red.main,
        backgroundColor: palette.red.lighter,
      },
      '[type="number"]': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          margin: '-3px 0',
          opacity: '0.5',
        },
      },
    },
    sizeSmall: {
      padding: '0px 8px',
      height: '30px',
    },
    multiline: {
      height: 'auto',
    },
  },
  defaultProps: {
    disableUnderline: true,
    autoComplete: 'none',
    fullWidth: true,
  },
};

export default MuiInput;
