export default {
  nameLabel: 'Name',
  firstName: {
    label: 'First name',
    placeholder: 'John',
  },
  lastName: {
    label: 'Last name',
    placeholder: 'Appleseed',
  },
  company: {
    label: {
      title: 'Company',
      subtitle: 'Search for company',
    },
    placeholder: 'Enter company name...',
  },
  position: {
    label: 'Position',
    placeholder: 'Ex., Chief Executive Officer',
  },
  contactRelation: {
    label: 'Contact Relation',
    placeholder: 'Select type...',
  },
  photo: {
    label: {
      title: 'Photo',
      subtitle: 'Upload the image',
    },
    button: 'Upload image, up to 5mb',
  },
  notes: {
    label: 'Public Notes',
    placeholder: 'Enter additional details',
  },
  contactInformationTitle: 'Contact information',
  email: {
    label: 'Email',
    placeholder: 'example@domain.com',
  },
  phone: {
    label: 'Phone',
    placeholder: '+1(382)132-4562',
  },
  createSuccessMessage: 'Successfully created',
  updateSuccessMessage: 'Successfully updated',
  submit: 'Save contact',
  addToContacts: 'Add to Contacts',
  viewDetails: 'View details',
  contactExists: 'This contact has already been created.',
  contactInList: 'This contact is already on your list',
};
