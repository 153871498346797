export default {
  all: 'All',
  team: 'Team',
  user: 'User',
  selectRecipient: 'Select {{ recipient }}',
  sendToAll: 'Send to All',
  to: 'To',
  notification: 'Notification',
  createButton: 'Create',
  saveButton: 'Save',
  notificationTextPlaceholder: 'Enter your notification text',
};
