import { createSlice } from '@reduxjs/toolkit';

import { useAppDispatch } from 'hooks/useRedux';

export type SidebarSliceStateType = {
  isSidebarCollapsed: boolean;
};

export type SidebarSliceActionsType = {
  handleCollapseSidebar: () => void;
};

const initialState: SidebarSliceStateType = {
  isSidebarCollapsed: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    handleCollapseSidebar: state => {
      state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
  },
});

const { actions } = sidebarSlice;

export const useSidebarActions = (): SidebarSliceActionsType => {
  const dispatch = useAppDispatch();

  const handleCollapseSidebar = () => dispatch(actions.handleCollapseSidebar());

  return {
    handleCollapseSidebar,
  };
};

export default sidebarSlice.reducer;
