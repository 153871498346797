import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en, { ns } from './en';

export const resources = { en } as const;

const i18next = i18n.createInstance();

i18next.use(initReactI18next).init({
  resources: {
    en,
  },
  interpolation: { escapeValue: false },
  lng: 'en',
  ns,
});

export default i18next;
