import { UserRole } from 'domain/user/enums';

export default {
  title: 'Your workspaces',
  list: {
    [UserRole.attorney]: 'Attorneys',
    [UserRole.marketeer]: 'Marketing and Business Development',
  },
  current: 'Current',
};
