import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiFormHelperTextType = {
  defaultProps: ComponentsProps['MuiFormHelperText'];
  styleOverrides: ComponentsOverrides['MuiFormHelperText'];
};

const MuiFormHelperText: MuiFormHelperTextType = {
  styleOverrides: {
    root: {
      position: 'absolute',
      top: '100%',
      fontSize: '12px',
      fontWeight: '400',
      opacity: '1',
      marginLeft: '0',
      whiteSpace: 'nowrap',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  },
  defaultProps: {},
};

export default MuiFormHelperText;
