import { IGlobalSearchResponse } from 'domain/globalSearch/types';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

export type IndexParams = {
  clientId: ID;
  queryParams: {
    name: string;
    page: number;
    perPage: number;
  };
};

const GlobalSearchRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<IGlobalSearchResponse> {
    const url = apiRoutes.apiSiteV1ClientGlobalSearchIndexPath(clientId);
    return Fetcher.get(url, queryParams);
  },
};

export default GlobalSearchRepository;
