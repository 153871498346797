import { ProjectApprovalValue, ProjectWinLossValue, ProjectOutcomeValue } from 'domain/project/enums';

export default {
  projectInfoSubtitle: 'Project info',
  department: {
    label: {
      title: 'Department',
      subtitle: 'Choose project department',
    },
    placeholder: 'Choose project department...',
  },
  projectType: {
    label: {
      title: 'Project Type',
      subtitle: 'Choose the project type',
    },
    placeholder: 'Choose the project type...',
  },
  files: {
    label: {
      title: 'Attach File',
      subtitle: 'Choose your file',
    },
    button: 'Upload new file, up to 5mb',
  },
  practiceGroup: {
    label: {
      title: 'Practice Group',
      subtitle: 'Add practice group',
    },
    placeholder: 'Add practice group',
  },
  office: {
    label: {
      title: 'Office',
      subtitle: 'Add office',
    },
    placeholder: 'Add office',
  },
  taskTemplate: {
    label: 'ToDo Template',
    placeholder: 'Choose ToDo',
  },
  assignedTo: {
    label: 'Assign Project',
    placeholder: 'Assign to mbd',
  },
  assignToMyself: {
    label: 'Assign to myself',
    placeholder: 'Clear box to make changes',
  },
  name: {
    label: 'Project Name',
    placeholder: 'Enter project name',
  },
  eventName: {
    label: 'Event Name',
    placeholder: 'Enter event name',
  },
  sessionTitle: {
    label: 'Session Title',
    placeholder: 'Enter session title',
  },
  organization: {
    label: 'Organization',
    placeholder: 'Enter organization',
  },
  role: {
    label: 'Role',
    placeholder: 'Select role',
  },
  sponsoredEvent: {
    label: 'Did we sponsor this event',
  },
  company: {
    label: {
      title: 'Company',
      subtitle: 'Search for company',
    },
    placeholder: 'Enter company name...',
  },
  dueDate: {
    label: 'Due Date',
    placeholder: 'Select submitted date...',
  },
  status: {
    label: '% Complete',
  },
  timeSpent: {
    label: 'Time Spent',
    placeholder: {
      hours: 'HH',
      minutes: 'MM',
    },
  },
  doc: {
    label: 'Doc#',
    placeholder: 'Enter doc#',
  },
  addToCalendar: {
    label: {
      title: 'Add to calendar',
      subtitle: 'Sync with Google Calendar',
    },
  },
  isPrivate: {
    label: {
      title: 'Is Private?',
      subtitle: 'Hide from others',
    },
  },
  startingAt: {
    label: 'Created Date',
    placeholder: 'Created date',
  },
  purpose: {
    label: {
      title: 'Type',
      subtitle: 'Choose the type',
    },
    placeholder: 'Choose the type',
  },
  industry: {
    label: {
      title: 'Industry',
      subtitle: 'Add industry',
    },
    placeholder: 'Add industry',
  },
  contact: {
    label: {
      title: 'Contact',
      subtitle: 'Assign the contact',
    },
    placeholder: 'Assign the contact',
  },
  importedContacts: {
    label: 'Imported Contacts',
    placeholder: 'Enter imported contacts',
  },
  typeOfWork: {
    label: 'Type of Work',
    placeholder: 'Enter type of work',
  },
  otherTeamMembers: {
    label: 'Other Team Members',
    placeholder: 'Select team members',
  },
  projectedRevenue: {
    label: 'Projected Revenue',
    placeholder: 'Enter projected revenue',
  },
  referral: {
    label: 'Referral',
    placeholder: 'Enter referral',
  },
  winLoss: {
    label: 'Win/Loss',
    placeholder: 'Choose',
  },
  yearToDateRevenue: {
    label: 'YTD Revenue',
    placeholder: 'Enter YTD revenue',
  },
  additionalDetails: {
    label: 'Additional Details',
    placeholder: 'Enter additional details',
  },
  attorneyTeam: {
    label: {
      title: 'Attorney Team',
      subtitle: 'Choose the attorney team',
    },
    placeholder: 'Choose the attorney team',
  },
  leadRequestingAttorney: {
    label: {
      title: 'Lead Attorney(s)',
    },
    placeholder: 'Choose lead attorney(s)',
  },
  subpracticeGroups: {
    label: {
      title: 'Subpractice Groups',
      subtitle: 'Add subpractice group',
    },
    placeholder: 'Add Subpractice group',
  },
  currentClient: {
    label: {
      title: 'Current Client ',
    },
  },
  researchRequested: {
    label: {
      title: 'Research Requested',
    },
  },
  pricingIncluded: {
    label: {
      title: 'Pricing Included ',
    },
  },
  clientNumber: {
    label: {
      title: 'Client Number',
      placeholder: 'Enter client number',
    },
  },
  referredFromConference: {
    label: {
      title: 'If referred from a conference, please list below',
    },
  },
  matterNumber: {
    label: {
      title: 'Matter Number',
    },
    placeholder: 'Enter matter number',
  },
  activity: {
    label: 'Activity',
    placeholder: 'Enter activity',
  },
  goalsDiscussed: {
    label: 'Goals Discussed',
    placeholder: 'Enter goals discussed',
  },
  meetingNotes: {
    label: 'Meeting Notes',
    placeholder: 'Enter additional details',
  },
  clientRank: {
    label: 'Client Rank',
    placeholder: 'Enter Client`s rank',
  },
  feedback: {
    label: 'Feedback',
    placeholder: 'Enter feedback',
  },
  correctiveAction: {
    label: 'Corrective Action',
    placeholder: 'Enter corrective action',
  },
  venue: {
    label: 'Venue',
    placeholder: 'Enter venue',
  },
  location: {
    label: 'Location',
    placeholder: 'Enter location',
  },
  caterers: {
    label: 'Caterers',
    placeholder: 'Enter caterers',
  },
  clientGifts: {
    label: 'Client Gifts',
    placeholder: 'Enter client gifts',
  },
  budget: {
    label: 'Budget',
    placeholder: '',
  },
  actualSpent: {
    label: 'Actual Spent',
    placeholder: '',
  },
  costPerAttendee: {
    label: 'Cost Per Attendee',
    placeholder: '',
  },
  postEventFeedback: {
    label: 'Post Event Feedback',
    placeholder: 'Enter event feedback',
  },
  invitedPeople: {
    label: 'Number of People Invited',
    placeholder: 'Enter number of people invited',
  },
  attendedContacts: {
    label: 'Number of Contacts Attended',
    placeholder: 'Enter number of contacts attended',
  },
  attendedAttorneys: {
    label: 'Number of Attorneys Attended',
    placeholder: 'Enter number of attorneys attended',
  },
  individualName: {
    label: 'Individual Name',
    placeholder: 'Enter individual name',
  },
  sponsorLevel: {
    label: 'Sponsor Level',
    placeholder: 'Enter sponsor level',
  },
  sponsorBenefits: {
    label: 'Sponsor Benefits',
    placeholder: 'Enter sponsor benefits',
  },
  cost: {
    label: 'Cost',
    placeholder: '',
  },
  requireManagementApproval: {
    label: 'Require Leadership/Management Approval',
    placeholder: '',
  },
  managementApproval: {
    label: 'Leadership/Management Approval',
    placeholder: 'Require Leadership/Management Approval',
  },
  requireLogoApproval: {
    label: 'Require Paperwork/Logo Approval',
  },
  logoApproval: {
    label: 'Paperwork/Logo Submitted',
    placeholder: 'Require Paperwork/Logo submitted',
  },
  creativeApprovalRequired: {
    label: 'Creative Approval Required',
  },
  creativeApproval: {
    label: 'Creative Approval',
    placeholder: 'Require creative approval',
  },
  publication: {
    label: 'Publication',
    placeholder: 'Enter publication information...',
  },
  outlets: {
    label: 'Outlets',
    placeholder: 'Enter outlets',
  },
  occasion: {
    label: 'Occasion',
    placeholder: 'Enter occasion',
  },
  adValue: {
    label: 'Ad Value',
    placeholder: 'Ad Value',
  },
  typeOfAward: {
    label: 'Award Category',
    placeholder: 'Enter the award category',
    optionsTitle: 'Earlier added award category',
  },
  years: {
    label: 'Years',
    placeholder: 'Select',
  },
  outcome: {
    label: 'Outcome',
    placeholder: 'Select',
  },
  clientApprovalToPublicize: {
    label: 'Client Approval to Publicize',
    placeholder: 'Select',
  },
  submittedAt: {
    label: 'Date Submitted',
    placeholder: 'Select submitted date...',
  },
  requireApprovalToUpdate: {
    label: 'Require Approval to Update',
    placeholder: 'Select',
  },
  requireApprovalFrom: {
    label: 'Approval From',
    placeholder: 'Enter details',
  },
  topic: {
    label: 'Topic',
    placeholder: 'Enter topic',
  },
  details: {
    label: 'Details',
    placeholder: 'Enter details',
  },
  completedAt: {
    label: 'Completed At',
    placeholder: 'Completed date',
  },
  [ProjectApprovalValue.yes]: 'Yes',
  [ProjectApprovalValue.no]: 'No',
  [ProjectApprovalValue.notApplicable]: 'Not applicable',
  [ProjectWinLossValue.won]: 'Won',
  [ProjectWinLossValue.inProgress]: 'In Progress',
  [ProjectWinLossValue.passed]: 'Passed',
  [ProjectWinLossValue.notApplicable]: 'N/A',
  [ProjectWinLossValue.lost]: 'Lost',
  [ProjectWinLossValue.unknown]: 'Unknown',
  submit: 'Save project',
  updateSuccessMessage: 'Successfully updated',
  createSuccessMessage: 'Successfully created',
  [ProjectOutcomeValue.received]: 'Received',
  [ProjectOutcomeValue.notReceived]: 'Not received',
  [ProjectOutcomeValue.outstanding]: 'Outstanding',
};
