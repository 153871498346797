export default {
  name: {
    label: {
      title: 'Name',
      subtitle: 'Add activity name',
    },
    placeholder: 'Add activity name...',
    error: 'Name',
  },
  activityType: {
    label: {
      title: 'Type',
      subtitle: 'Add activity type',
    },
    placeholder: 'Select the type...',
    error: 'Type',
  },
  period: {
    label: {
      title: 'Period',
      subtitle: 'Starting and ending dates',
    },
    placeholder: {
      startDate: 'Starting date',
      endDate: 'Due date',
    },
  },
  details: {
    label: 'Additional Details',
    placeholder: 'Enter additional details',
  },
  hours: {
    label: 'Time Spent',
    placeholder: {
      hours: 'Hours',
      minutes: 'Minutes',
    },
  },
  relationsTitle: 'Relations',
  contact: {
    label: {
      title: 'Contact',
      subtitle: 'Assign the contact',
    },
    placeholder: 'Enter company name or contact...',
  },
  company: {
    label: {
      title: 'Company',
      subtitle: 'Search for company',
    },
    placeholder: 'Enter company name...',
  },
  goal: {
    label: {
      title: 'Goal',
      subtitle: 'Add a goal',
    },
    placeholder: 'Select the goal...',
    error: 'Goal',
  },
  completedAt: {
    label: 'Completed At',
    placeholder: 'Completed date',
  },
  createSuccessMessage: 'Successfully created',
  updateSuccessMessage: 'Successfully updated',
  submitCreate: 'Create activity',
  submitUpdate: 'Update activity',
};
