import { createSlice } from '@reduxjs/toolkit';

import { ITeam } from 'domain/team/types';
import { FetchStatus } from 'enums/FetchStatus';
import TeamsRepository, { IndexParams, ShowParams } from 'repositories/TeamsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type TeamsSliceStateType = {
  teams: ITeam[];
  meta: ResponseMeta;
  team: ITeam;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TeamsSliceActionsType = {
  loadTeamsList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'teams', ITeam[]>> };
  resetTeams: () => void;
  showTeam: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<'team', ITeam>> };
};

const initialState: TeamsSliceStateType = {
  teams: [],
  team: {} as ITeam,
  meta: { perPage: 8 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTeamsList = createAsyncAction('teams/index', TeamsRepository.index);
export const showTeam = createAsyncAction('teams/show', TeamsRepository.show);

const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    resetTeams: state => {
      state.teams = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTeamsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTeamsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.teams = payload.teams;
      state.meta = payload.meta;
    });
    builder.addCase(loadTeamsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showTeam.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showTeam.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;

      state.team = payload.team;
    });
    builder.addCase(showTeam.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetTeams },
} = slice;

export { resetTeams };

export default slice.reducer;
