export default {
  title: 'Password Recovery',
  text: 'We`ll send a recovery link to your email.',
  backLink: 'Back to Sign In',
  email: {
    label: 'Email',
    placeholder: 'Enter your Email',
  },
  submit: 'Send Recovery Link',
  successMessage: {
    text: 'Recovery link has been sent.\nCheck your email.',
    link: 'Resend Recovery Link',
  },
};
