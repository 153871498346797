import { createSlice } from '@reduxjs/toolkit';

import { IActivityType } from 'domain/activityType/types';
import { FetchStatus } from 'enums/FetchStatus';
import ActivityTypesRepository, { IndexParams } from 'repositories/ActivityTypesRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type ActivityTypesSliceStateType = {
  activityTypes: IActivityType[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ActivityTypesSliceActionsType = {
  loadActivityTypesList: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'activityTypes', IActivityType[]>>;
  };
  resetActivityTypes: () => void;
};

const initialState: ActivityTypesSliceStateType = {
  activityTypes: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadActivityTypesList = createAsyncAction('activityTypes/index', ActivityTypesRepository.index);

const slice = createSlice({
  name: 'activityTypes',
  initialState,
  reducers: {
    resetActivityTypes: state => {
      state.activityTypes = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadActivityTypesList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadActivityTypesList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.activityTypes = payload.activityTypes;
    });
    builder.addCase(loadActivityTypesList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetActivityTypes },
} = slice;

export { resetActivityTypes };

export default slice.reducer;
