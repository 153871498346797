import { Reducer } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

type CreateAdminReducersParams = {
  history: History;
};

const createAdminReducers = ({ history }: CreateAdminReducersParams): Record<string, Reducer> => {
  const reducers = {
    admin: {},
    router: connectRouter(history),
  };

  return reducers;
};

export default createAdminReducers;
