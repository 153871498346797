export default {
  title: 'Search Result for',
  resultsFound: '{{total}} results found',
  noResults: 'No results',
  project: 'Project',
  projectNote: 'Project Note',
  task: 'ToDo',
  company: 'Company',
  contact: 'Contact',
  activity: 'Activity',
  goal: 'Goal',
  lastUpdate: 'Updated {{date}} ago',
  sorting: 'Sorting:',
  searchInputPlaceholder: 'Type your search query here',
  sort: {
    nameDesc: 'Name (Z - A)',
    nameAsc: 'Name (A - Z)',
    updatedAtDesc: 'Updated At (new to old)',
    updatedAtAsc: 'Updated At (old to new)',
  },
};
