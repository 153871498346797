import { IPracticeGroupReportResponse } from 'domain/practiceGroupReport/types';
import { apiRoutes } from 'routes';
import { TableFilterQueryParams } from 'types/tableFilter';
import Fetcher from 'utils/fetcher';

export type IndexParams = {
  clientId: ID;
  queryParams?: TableFilterQueryParams;
};

const PracticeGroupReportRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<IPracticeGroupReportResponse> {
    const url = apiRoutes.apiSiteV1ClientReportsPracticeGroupReportPath(clientId);

    return Fetcher.get(url, queryParams);
  },
};

export default PracticeGroupReportRepository;
