import {
  IClientNotification,
  IClientNotificationResponse,
  IClientNotificationsResponse,
} from 'domain/clientNotifications/types';
import { NotificationFormDataToSubmit } from 'forms/notificationForm';
import { apiRoutes } from 'routes';
import { TableFilterQueryParams } from 'types/tableFilter';
import Fetcher from 'utils/fetcher';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type DeleteParams = {
  clientId: ID;
  notificationId: ID;
};

export type ShowParams = {
  clientId: ID;
  notificationId: ID;
};

export type UpdateParams = {
  clientId: ID;
  notificationId: ID;
  formData: NotificationFormDataToSubmit;
};

export type CreateParams = {
  clientId: ID;
  formData: NotificationFormDataToSubmit;
};

const NotificationsRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<IClientNotificationsResponse> {
    const url = apiRoutes.apiSiteV1ClientNotificationsPath(clientId);

    return Fetcher.get(url, queryParams);
  },
  show({ clientId, notificationId }: ShowParams): Promise<IClientNotificationResponse> {
    const url = apiRoutes.apiSiteV1ClientNotificationPath(clientId, notificationId);

    return Fetcher.get(url);
  },
  create({ clientId, formData }: CreateParams): Promise<IClientNotification> {
    const url = apiRoutes.apiSiteV1ClientNotificationsPath(clientId);

    return Fetcher.post(url, formData);
  },
  delete({ clientId, notificationId }: DeleteParams): Promise<IClientNotification> {
    const url = apiRoutes.apiSiteV1ClientNotificationPath(clientId, notificationId);

    return Fetcher.delete(url);
  },
  update({ clientId, notificationId, formData }: UpdateParams): Promise<IClientNotification> {
    const url = apiRoutes.apiSiteV1ClientNotificationPath(clientId, notificationId);

    return Fetcher.put(url, formData);
  },
};

export default NotificationsRepository;
