import { createSlice } from '@reduxjs/toolkit';

import { IPracticeGroupReportResponse } from 'domain/practiceGroupReport/types';
import PracticeGroupReportRepository, {
  IndexParams as PracticeGroupReportIndexParams,
} from 'repositories/Reports/PracticeGroupReportRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';

export type PracticeGroupReportSliceStateType = {
  practiceGroupReport: IPracticeGroupReportResponse;
  practiceGroupReportState: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type PracticeGroupReportSliceActionsType = {
  loadPracticeGroupReport: (params: PracticeGroupReportIndexParams) => {
    unwrap: () => Promise<IPracticeGroupReportResponse>;
  };
  reset: () => void;
};

export const loadPracticeGroupReport = createAsyncAction(
  'practiceGroupReport/index',
  PracticeGroupReportRepository.index,
);

const initialState: PracticeGroupReportSliceStateType = {
  practiceGroupReport: {} as IPracticeGroupReportResponse,
  practiceGroupReportState: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const practiceGroupReportSlice = createSlice({
  name: 'practiceGroupReport',
  initialState,
  reducers: {
    reset: state => {
      state.practiceGroupReport = {} as IPracticeGroupReportResponse;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadPracticeGroupReport.pending, state => {
      state.practiceGroupReportState.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadPracticeGroupReport.fulfilled, (state, { payload }) => {
      state.practiceGroupReportState.fetchStatus = FetchStatus.fulfilled;
      state.practiceGroupReport = payload;
    });
    builder.addCase(loadPracticeGroupReport.rejected, state => {
      state.practiceGroupReportState.fetchStatus = FetchStatus.rejected;
    });
  },
});

const { reset } = practiceGroupReportSlice.actions;

export { reset };

export default practiceGroupReportSlice.reducer;
