import { createSlice } from '@reduxjs/toolkit';

import TeamMembershipsRepository, { IndexParams } from 'repositories/TeamMembershipsRepository';
import { IMembership } from 'domain/membership/types';
import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';

export type TeamMembershipsSliceStateType = {
  memberships: IMembership[];
  meta: ResponseMeta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TeamMembershipsSliceActionsType = {
  loadTeamMemberships: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'memberships', IMembership[]>>;
  };
};

const initialState: TeamMembershipsSliceStateType = {
  memberships: [],
  meta: { perPage: 8 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTeamMemberships = createAsyncAction('teamMemberships/index', TeamMembershipsRepository.index);

const slice = createSlice({
  name: 'teamMemberships',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadTeamMemberships.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTeamMemberships.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;

      state.memberships = payload.memberships;
      state.meta = payload.meta;
    });
    builder.addCase(loadTeamMemberships.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
