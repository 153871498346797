import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiButtonType = {
  defaultProps: ComponentsProps['MuiButton'];
  styleOverrides: ComponentsOverrides['MuiButton'];
  variants: ComponentsVariants['MuiButton'];
};

const MuiButton: MuiButtonType = {
  styleOverrides: {
    root: {
      borderRadius: '6px',
      fontFamily: 'Nunito, Inter, Arial',
      fontWeight: '700',
      textTransform: 'none',
      maxWidth: '100%',
    },
    sizeSmall: {
      fontSize: '12px',
      padding: '8px 10px',
    },
    sizeMedium: {
      fontSize: '14px',
      padding: '14px 30px',
    },
    sizeLarge: {
      fontSize: '16px',
      lineHeight: '16px',
      padding: '16px 24px',
    },
    contained: {
      color: palette.white.main,
      backgroundColor: palette.green.main,
      '&:hover': {
        backgroundColor: palette.green.main,
      },
      '&:active': {
        backgroundColor: palette.green.main,
      },
      '&.Mui-disabled': {
        backgroundColor: palette.green.lighter,
      },
    },
    text: {
      color: palette.green.main,
      backgroundColor: 'transparent',
      border: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    outlined: {
      color: palette.black.main,
      border: `2px solid ${palette.green.main}`,
      '&:hover': {
        border: `2px solid ${palette.green.main}`,
        backgroundColor: palette.green.main,
      },
      '&:active': {
        backgroundColor: palette.green.main,
      },
      '&.Mui-disabled': {
        color: palette.black.light,
        borderColor: palette.black.light,
      },
    },
    containedSecondary: {
      color: palette.white.main,
      backgroundColor: palette.black.lighter,
      '&:hover': {
        backgroundColor: palette.green.main,
      },
    },
  },
  variants: [
    {
      props: { variant: 'link' },
      style: {
        color: palette.green.main,
        fontSize: '14px',
        fontWeight: '700',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    {
      props: { variant: 'underlineLink' },
      style: {
        color: palette.green.main,
        fontSize: '14px',
        fontWeight: '500',
        textDecoration: 'underline',
        padding: '0',
        minWidth: '0',
        display: 'inline-block',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  ],
  defaultProps: {
    variant: 'contained',
    size: 'medium',
    disableElevation: true,
    disableRipple: true,
  },
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true;
    underlineLink: true;
    containedSecondary: true;
  }
}

export default MuiButton;
