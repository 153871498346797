import { createSlice } from '@reduxjs/toolkit';

import { IMembership } from 'domain/membership/types';
import { ITeam } from 'domain/team/types';
import { FetchStatus } from 'enums/FetchStatus';
import AttorneyDashboardRepository, { ShowTotalPointsParams } from 'repositories/AttorneyDashboardRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type AttorneyDashboardSliceStateType = {
  teams: ITeam[];
  indexTeams: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  teamMembers: IMembership[];
  indexTeamMembers: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type AttorneyDashboardSliceActionsType = {
  loadTeamsTotalPoints: (params: ShowTotalPointsParams) => {
    unwrap: () => Promise<CollectionResponse<'teams', ITeam[]>>;
  };
  loadTeamMembersTotalPoints: (params: ShowTotalPointsParams) => {
    unwrap: () => Promise<CollectionResponse<'memberships', IMembership[]>>;
  };
};

const initialState: AttorneyDashboardSliceStateType = {
  teams: [],
  indexTeams: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  teamMembers: [],
  indexTeamMembers: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTeamsTotalPoints = createAsyncAction(
  'attorneyDashboard/teamsTotalPoints',
  AttorneyDashboardRepository.teamsTotalPoints,
);
export const loadTeamMembersTotalPoints = createAsyncAction(
  'attorneyDashboard/teamMembersTotalPoints',
  AttorneyDashboardRepository.teamMembersTotalPoints,
);

const slice = createSlice({
  name: 'attorneyDashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadTeamsTotalPoints.pending, state => {
      state.indexTeams.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTeamsTotalPoints.fulfilled, (state, { payload }) => {
      state.indexTeams.fetchStatus = FetchStatus.fulfilled;
      state.teams = payload.teams;
    });
    builder.addCase(loadTeamsTotalPoints.rejected, state => {
      state.indexTeams.fetchStatus = FetchStatus.rejected;
    });

    builder.addCase(loadTeamMembersTotalPoints.pending, state => {
      state.indexTeamMembers.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTeamMembersTotalPoints.fulfilled, (state, { payload }) => {
      state.indexTeamMembers.fetchStatus = FetchStatus.fulfilled;
      state.teamMembers = payload.memberships;
    });
    builder.addCase(loadTeamMembersTotalPoints.rejected, state => {
      state.indexTeamMembers.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
