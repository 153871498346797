import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiSwitchType = {
  defaultProps: ComponentsProps['MuiSwitch'];
  styleOverrides: ComponentsOverrides['MuiSwitch'];
};

const MuiSwitch: MuiSwitchType = {
  styleOverrides: {
    root: {
      marginTop: '-4px',
      padding: '8px',
      '& .MuiSwitch-thumb': {
        width: '16px',
        height: '16px',
        margin: '2px',
        boxShadow: 'none',
      },
      '& .MuiSwitch-switchBase': {
        '&:hover': {
          background: 'none',
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: '11px',
        opacity: '0.1',
      },
      '& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track': {
        backgroundColor: palette.orange.main,
        opacity: 'initial',
      },
      '& .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb': {
        color: palette.white.main,
      },
    },
  },
  defaultProps: {},
};

export default MuiSwitch;
