import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { I18nextProvider } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import locales from 'locales/i18n';
import Router from 'components/Router';
import { store } from 'store';
import theme from 'theme';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                <I18nextProvider i18n={locales}>
                  <Router />
                </I18nextProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
