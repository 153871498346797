export default {
  companyFilter: 'Filter by company',
  detailReport: 'Detailed report',
  export: 'Export',
  columns: {
    company: 'Company',
    businessDevelopment: 'Business development',
    marketing: 'Marketing',
    communication: 'Communication',
    totalHours: 'Total Hours',
    completed: 'Completed',
    outstanding: 'Outstanding',
    timeSpent: 'Time spent',
  },
};
