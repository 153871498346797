export default {
  downloadTemplate: 'Download Template',
  downloadDescription:
    'In order to upload contacts, you need to download the Excel-template and fill it in, otherwise, the system may give an error.',
  download: 'Download',
  uploading: 'Uploading',
  uploadFile: 'Upload your file',
  chooseFile: 'Choose your file',
  chooseFileDescription: 'Drag and drop your file here or use the button below to manually select the file',
  fileRestriction: 'Excel up to 5mb',
  successfullyImported: 'Successfully Imported:',
  seeContacts: 'See your Contacts',
  skippedEntries: 'Skipped entries',
  incorrectEntry: 'due to incorrect data entry:',
  incorrectDescription: 'Check if all the contact fields were filled in when filling out the template.',
  existingEntry: 'existing in the system:',
  existingDescription: 'These contacts have already been entered into the system.',
};
