import { UserRole } from './enums';
import { IUser } from './types';

export const hasAnyAdminAccess = (user: IUser): boolean => {
  const adminRoles = [UserRole.administrator, UserRole.clientAdmin, UserRole.executiveAdmin];

  return adminRoles.includes(user.role);
};

export const hasAdminAccess = (user: IUser): boolean => {
  return user.role === UserRole.administrator;
};

export const hasClientAdminAccess = (user: IUser): boolean => {
  return user.role === UserRole.clientAdmin;
};

export const hasExecutiveAdminAccess = (user: IUser): boolean => {
  return user.role === UserRole.executiveAdmin;
};

export const hasMarketeerAccess = (user: IUser): boolean => {
  return user.role === UserRole.marketeer;
};

export const hasAttorneyAccess = (user: IUser): boolean => {
  return user.role === UserRole.attorney;
};

export const isAttorneyWorkspaceEnable = (user: IUser): boolean => {
  const {
    client: { isAttorneyWorkspaceEnabled },
  } = user;

  return isAttorneyWorkspaceEnabled;
};

export const isMBDWorkspaceEnable = (user: IUser): boolean => {
  const {
    client: { isMbdWorkspaceEnabled },
  } = user;

  return isMbdWorkspaceEnabled;
};

export const getClientId = (user: IUser): ID => {
  const {
    client: { id },
  } = user;

  return id;
};

export const ROLE_OPTIONS = [
  {
    id: UserRole.attorney,
    name: 'Attorney',
    value: UserRole.attorney,
  },
  {
    id: UserRole.marketeer,
    name: 'MBD',
    value: UserRole.marketeer,
  },
  {
    id: UserRole.administrator,
    name: 'Administrator',
    value: UserRole.administrator,
  },
  {
    id: UserRole.clientAdmin,
    name: 'Client Administrator',
    value: UserRole.clientAdmin,
  },
  {
    id: UserRole.executiveAdmin,
    name: 'Executive Administrator',
    value: UserRole.executiveAdmin,
  },
];
