export default {
  industryLabel: 'Industries',
  addCompany: 'Add new company',
  myCompaniesLabel: 'My companies only',
  actions: {
    seeDetailsLabel: 'See details',
  },
  columnsHeads: {
    companyName: 'Company Name',
    industry: 'Industry',
    contacts: 'Contacts',
  },
};
