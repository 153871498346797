import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import palette from 'theme/baseOptions/palette';

type MuiCircularProgressType = {
  defaultProps: ComponentsProps['MuiCircularProgress'];
  styleOverrides: ComponentsOverrides['MuiCircularProgress'];
};

const MuiCircularProgress: MuiCircularProgressType = {
  styleOverrides: {
    root: {
      color: palette.dark.main,
    },
  },
  defaultProps: {},
};

export default MuiCircularProgress;
