export default {
  name: {
    label: 'Company Name',
    placeholder: 'Acme Corporation',
  },
  industry: {
    label: 'Industry',
    placeholder: 'Select the industry...',
  },
  logo: {
    label: {
      title: 'Logo',
      subtitle: 'Upload the logo',
    },
  },
  fileUploadInputText: 'Upload image, up to 5mb',
  contactsTitle: 'Contact information',
  address: {
    label: 'Address',
    placeholder: 'New York, NY, United States',
  },
  website: {
    label: 'Website',
    placeholder: 'example.com',
  },
  createSuccessMessage: 'Successfully created',
  updateSuccessMessage: 'Successfully updated',
  showCompany: 'View Details',
  alreadyExist: 'This company already exists in the system.',
  optionsTitle: 'Earlier added companies',
  submit: 'Save company',
};
