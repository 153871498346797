import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import SessionRepository, { SsoLoginResponse } from 'repositories/SessionRepository';
import { SignInFormDataToSubmit } from 'forms/public/signInForm';

export type SessionSliceStateType = {
  signOut: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  signIn: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  ssoLogin: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type SessionSliceActionsType = {
  signIn: (params: SignInFormDataToSubmit) => { unwrap: () => void };
  signOut: () => { unwrap: () => void };
  ssoLogin: () => { unwrap: () => Promise<SsoLoginResponse> };
};

const initialState: SessionSliceStateType = {
  signOut: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  signIn: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  ssoLogin: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const signIn = createAsyncAction('session/signIn', SessionRepository.create);
export const signOut = createAsyncAction('session/signOut', SessionRepository.delete);
export const ssoLogin = createAsyncAction('session/ssoLogin', SessionRepository.ssoLogin);

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(signOut.pending, state => {
      state.signOut.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(signOut.fulfilled, state => {
      state.signOut.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(signOut.rejected, state => {
      state.signOut.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(signIn.pending, state => {
      state.signIn.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(signIn.fulfilled, state => {
      state.signIn.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(signIn.rejected, state => {
      state.signIn.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(ssoLogin.pending, state => {
      state.ssoLogin.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(ssoLogin.fulfilled, state => {
      state.ssoLogin.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(ssoLogin.rejected, state => {
      state.ssoLogin.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
