import { makeRoutes } from 'utils/routes';

const paths = makeRoutes({
  userPath: (id: ID) => `/loginUsers/${id}/show`,
  contactPath: (id: ID) => `/contacts/${id}/show`,
  clientPath: (id: ID) => `/clients/${id}/show`,
  activityTypePath: (id: ID) => `/activityTypes/${id}/show`,
  goalPath: (id: ID) => `/goals/${id}/show`,
  companyPath: (id: ID) => `/companies/${id}/show`,
});

export default paths;
