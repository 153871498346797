import {
  loadMonthlyProjectsReports,
  MonthlyProjectsReportsSliceType,
  MonthlyProjectsReportsSliceActionsType,
} from 'store/reports/monthlyProjectsReportsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';
import { MonthlyProjectsReportsParams } from 'repositories/Reports/MonthlyProjectsReportsRepository';

type UseMonthlyProjectsReports = Pick<MonthlyProjectsReportsSliceType, 'monthlyProjectsReports'> &
  MonthlyProjectsReportsSliceActionsType & {
    isMonthlyProjectsReportsLoading: boolean;
    isMonthlyProjectsReportsFinished: boolean;
  };

const useMonthlyProjectsReports = (): UseMonthlyProjectsReports => {
  const dispatch = useAppDispatch();
  const {
    monthlyProjectsReports,
    show: { fetchStatus: showFetchStatus },
  } = useAppSelector(state => state.monthlyProjectsReports);

  const isMonthlyProjectsReportsLoading = getFetchStatus(showFetchStatus).isPending;
  const isMonthlyProjectsReportsFinished = getFetchStatus(showFetchStatus).isFinished;

  return {
    monthlyProjectsReports,
    loadMonthlyProjectsReports: (params: MonthlyProjectsReportsParams) => dispatch(loadMonthlyProjectsReports(params)),
    isMonthlyProjectsReportsLoading,
    isMonthlyProjectsReportsFinished,
  };
};

export default useMonthlyProjectsReports;
