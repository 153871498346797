import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiInputLabelType = {
  defaultProps: ComponentsProps['MuiInputLabel'];
  styleOverrides: ComponentsOverrides['MuiInputLabel'];
};

const MuiInputLabel: MuiInputLabelType = {
  styleOverrides: {
    standard: {
      position: 'relative',
      display: 'block',
      marginBottom: '8px',
      transform: 'none',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '12px',
    },
  },
  defaultProps: {
    disableAnimation: true,
    shrink: true,
    variant: 'standard',
  },
};

export default MuiInputLabel;
