import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import Icon from 'components/Icon';
import palette from 'theme/baseOptions/palette';

type MuiChipType = {
  defaultProps: ComponentsProps['MuiChip'];
  styleOverrides: ComponentsOverrides['MuiChip'];
};

const MuiChip: MuiChipType = {
  styleOverrides: {
    root: {
      fontSize: '13px',
      fontWeight: '500',
      borderRadius: '4px',
      border: `1px solid ${palette.dark.lighter}`,
      backgroundColor: palette.orange.medium,
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
        borderRadius: '50%',
        padding: '2px',
        transition: 'background .2s ease',
        '&:hover': {
          backgroundColor: palette.dark.lighter,
        },
      },
    },
    outlinedPrimary: {
      borderColor: palette.green.main,
      color: palette.green.main,
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: '700',
      letterSpacing: '0.08em',
    },
  },
  defaultProps: {
    deleteIcon: <Icon name="x" />,
  },
};

export default MuiChip;
