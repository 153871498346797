import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import { IUserClientNotification, IUserClientNotificationsResponse } from 'domain/userClientNotifications/types';
import UserClientNotificationsRepository, {
  IndexParams,
  ChangeNotificationStateParams,
} from 'repositories/Client/UserClientNotificationsRepository';
import { NotificationState } from 'domain/userClientNotifications/enums';

export type UserClientNotificationsSliceStateType = {
  notifications: IUserClientNotification[];
  meta: ResponseMeta;
  unreadNotificationsCount: number;
  index: {
    fetchStatus: FetchStatus;
    error: null;
  };
  markAsRead: {
    fetchStatus: FetchStatus;
    error: null;
  };
  markAsUnread: {
    fetchStatus: FetchStatus;
    error: null;
  };
};

export type UserClientNotificationsSliceActionsType = {
  loadNotificationsList: (params: IndexParams) => { unwrap: () => Promise<IUserClientNotificationsResponse> };
  loadUnreadNotifications: (params: IndexParams) => { unwrap: () => Promise<IUserClientNotificationsResponse> };
  markAsRead: (params: ChangeNotificationStateParams) => { unwrap: () => Promise<IUserClientNotification> };
  markAsUnread: (params: ChangeNotificationStateParams) => { unwrap: () => Promise<IUserClientNotification> };
};

const initialState: UserClientNotificationsSliceStateType = {
  notifications: [],
  meta: null,
  unreadNotificationsCount: null,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  markAsRead: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  markAsUnread: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadNotificationsList = createAsyncAction(
  'userClientNotifications/index',
  UserClientNotificationsRepository.index,
);

export const loadUnreadNotifications = createAsyncAction(
  'userClientNotifications/unreadNotifications',
  UserClientNotificationsRepository.index,
);
export const markNotificationAsRead = createAsyncAction(
  'userClientNotifications/markAsRead',
  UserClientNotificationsRepository.markAsRead,
);
export const markNotificationAsUnread = createAsyncAction(
  'userClientNotifications/markAsUnread',
  UserClientNotificationsRepository.markAsUnread,
);

const slice = createSlice({
  name: 'userClientNotifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadNotificationsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadNotificationsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadNotificationsList.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const notificationState = meta.arg.queryParams.q.stateEq;
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.notifications = payload.userClientNotifications;
      state.meta = payload.meta;
      state.unreadNotificationsCount =
        notificationState === NotificationState.unread ? payload.meta.totalCount : state.unreadNotificationsCount;
    });
    builder.addCase(loadUnreadNotifications.fulfilled, (state, { payload }) => {
      state.unreadNotificationsCount = payload.meta.totalCount;
    });
    builder.addCase(markNotificationAsRead.pending, state => {
      state.markAsRead.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(markNotificationAsRead.rejected, state => {
      state.markAsRead.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(markNotificationAsRead.fulfilled, state => {
      state.markAsRead.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(markNotificationAsUnread.pending, state => {
      state.markAsUnread.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(markNotificationAsUnread.rejected, state => {
      state.markAsUnread.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(markNotificationAsUnread.fulfilled, state => {
      state.markAsUnread.fetchStatus = FetchStatus.fulfilled;
    });
  },
});

export default slice.reducer;
