import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IProjectNote } from 'domain/projectNote/types';
import { ProjectNoteFormDataToSubmit } from 'forms/projectNoteForm';

export type IndexParams = {
  clientId: ID;
  projectId: ID;
  queryParams: TableFilterQueryParams;
};

export type CreateParams = {
  clientId: ID;
  projectId: ID;
  params: ProjectNoteFormDataToSubmit;
};

export type ActionParams = {
  clientId: ID;
  projectId: ID;
  noteId: ID;
};

const ProjectNotesRepository = {
  index({
    clientId,
    projectId,
    queryParams,
  }: IndexParams): Promise<CollectionResponse<'projectNotes', IProjectNote[]>> {
    const url = apiRoutes.apiSiteV1ClientProjectProjectNotesPath(clientId, projectId);
    return Fetcher.get(url, queryParams);
  },
  create({ clientId, projectId, params }: CreateParams): Promise<SingleResponse<'projectNotes', IProjectNote>> {
    const url = apiRoutes.apiSiteV1ClientProjectProjectNotesPath(clientId, projectId);
    return Fetcher.postFormData(url, params);
  },
  delete({ clientId, projectId, noteId }: ActionParams): Promise<SingleResponse<'projectNotes', IProjectNote>> {
    const url = apiRoutes.apiSiteV1ClientProjectProjectNotePath(clientId, projectId, noteId);
    return Fetcher.delete(url);
  },
};

export default ProjectNotesRepository;
