import { createSlice } from '@reduxjs/toolkit';

import DetailedPracticeGroupReportRepository, {
  ProjectIndexParams,
  ProjectStatsParams,
} from 'repositories/Reports/PracticeGroups/DetailedPracticeGroupReportRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import { IPracticeGroupDetailReportProjectStats, IReportProject } from 'domain/practiceGroupDetailReport/types';
import { ProjectDepartment } from 'domain/project/enums';

type PracticeGroupDetailReportProjectsItem = {
  data: IReportProject[];
  meta: ResponseMeta;
  fetchStatus: FetchStatus;
};

export type PracticeGroupDetailReportSliceStateType = {
  projects: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  projectStats: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  practiceGroupDetailReportProjects: Record<ProjectDepartment, PracticeGroupDetailReportProjectsItem>;
  practiceGroupDetailReportProjectStats: IPracticeGroupDetailReportProjectStats;
};

export type PracticeGroupDetailReportSliceActionsType = {
  loadPracticeGroupProjects: (params: ProjectIndexParams) => {
    unwrap: () => Promise<CollectionResponse<'projects', IReportProject[]>>;
  };
  loadPracticeGroupProjectStats: (params: ProjectStatsParams) => { unwrap: () => Promise<unknown> };
};

export const loadPracticeGroupProjects = createAsyncAction(
  'practiceGroupDetailReport/projects',
  DetailedPracticeGroupReportRepository.projects,
);
export const loadPracticeGroupProjectStats = createAsyncAction(
  'practiceGroupDetailReport/projectStats',
  DetailedPracticeGroupReportRepository.projectStats,
);

const initialState: PracticeGroupDetailReportSliceStateType = {
  projects: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  projectStats: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  practiceGroupDetailReportProjects: {
    [ProjectDepartment.businessDevelopment]: {
      data: [],
      meta: { perPage: 8 } as ResponseMeta,
      fetchStatus: FetchStatus.idle,
    },
    [ProjectDepartment.communications]: {
      data: [],
      meta: { perPage: 8 } as ResponseMeta,
      fetchStatus: FetchStatus.idle,
    },
    [ProjectDepartment.marketing]: {
      data: [],
      meta: { perPage: 8 } as ResponseMeta,
      fetchStatus: FetchStatus.idle,
    },
  },
  practiceGroupDetailReportProjectStats: {} as IPracticeGroupDetailReportProjectStats,
};

const practiceGroupDetailReportSlice = createSlice({
  name: 'practiceGroupDetailReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPracticeGroupProjects.pending, (state, action) => {
      const { department } = action.meta.arg;

      state.practiceGroupDetailReportProjects[department].fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadPracticeGroupProjects.fulfilled, (state, action) => {
      const { department } = action.meta.arg;

      state.practiceGroupDetailReportProjects[department].fetchStatus = FetchStatus.fulfilled;
      state.practiceGroupDetailReportProjects[department].data = action.payload.projects;
      state.practiceGroupDetailReportProjects[department].meta = action.payload.meta;
    });
    builder.addCase(loadPracticeGroupProjects.rejected, (state, action) => {
      const { department } = action.meta.arg;

      state.practiceGroupDetailReportProjects[department].fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadPracticeGroupProjectStats.pending, state => {
      state.projectStats.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadPracticeGroupProjectStats.fulfilled, (state, { payload }) => {
      state.projectStats.fetchStatus = FetchStatus.fulfilled;
      state.practiceGroupDetailReportProjectStats = payload;
    });
    builder.addCase(loadPracticeGroupProjectStats.rejected, state => {
      state.projectStats.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default practiceGroupDetailReportSlice.reducer;
