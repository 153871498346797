export default {
  name: {
    label: 'Goal Title',
    placeholder: 'Enter your goal title',
  },
  dueDate: {
    label: 'Due Date',
    placeholder: 'Select due date',
  },
  completedAt: {
    label: 'Completed At',
    placeholder: 'Completed date',
  },
  updateSuccessMessage: 'Successfully updated',
  submit: 'Save goal',
  backButton: 'Back to List',
  viewActivities: 'View Activities',
  addActivity: 'Add Activity to Goal',
};
