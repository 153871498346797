import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TeamScore } from 'domain/team/types';

export type IndexParams = {
  clientId: ID;
  teamId: ID;
};

const TeamScoreRepository = {
  index({ clientId, teamId }: IndexParams): Promise<CollectionResponse<'dailyGroupedScores', TeamScore[]>> {
    const url = apiRoutes.dailyGroupedApiSiteV1ClientTeamScoreEventsPath(clientId, teamId);
    return Fetcher.get(url);
  },
};

export default TeamScoreRepository;
