import { createSlice } from '@reduxjs/toolkit';

import { IMembership } from 'domain/membership/types';
import { FetchStatus } from 'enums/FetchStatus';
import MembershipsRepository, { IndexParams } from 'repositories/MembershipsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type MembershipsSliceStateType = {
  memberships: IMembership[];
  meta: ResponseMeta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type MembershipsSliceActionsType = {
  loadMembershipsList: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'memberships', IMembership[]>>;
  };
  resetMemberships: () => void;
};

const initialState: MembershipsSliceStateType = {
  memberships: [],
  meta: { perPage: 8 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadMembershipsList = createAsyncAction('memberships/index', MembershipsRepository.index);

const slice = createSlice({
  name: 'memberships',
  initialState,
  reducers: {
    resetMemberships: state => {
      state.memberships = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadMembershipsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadMembershipsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.memberships = payload.memberships;
      state.meta = payload.meta;
    });
    builder.addCase(loadMembershipsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetMemberships },
} = slice;

export { resetMemberships };

export default slice.reducer;
