import { createSlice } from '@reduxjs/toolkit';

import { IContact } from 'domain/contact/types';
import { FetchStatus } from 'enums/FetchStatus';
import ContactsRepository, {
  CreateParams,
  IndexParams,
  ShowParams,
  UpdateParams,
} from 'repositories/ContactsRepository';
import { createAsyncAction } from 'utils/createAsyncAction';
import { IUser } from 'domain/user/types';

export type ContactsSliceStateType = {
  contacts: IContact[];
  contact: IContact;
  contactUsers: IUser[];
  meta: ResponseMeta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadUsers: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ContactsSliceActionsType = {
  loadContactsList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'contacts', IContact[]>> };
  createContact: (params: CreateParams) => { unwrap: () => void };
  updateContact: (params: UpdateParams) => { unwrap: () => void };
  showContact: (params: ShowParams) => { unwrap: () => Promise<IContact> };
  loadContactUsers: (params: IndexParams) => { unwrap: () => void };
  exportContactsList: (params: IndexParams) => { unwrap: () => void };
  exportContactActivitiesList: (params: IndexParams) => { unwrap: () => void };
  resetContact: () => void;
  resetContacts: () => void;
};

const initialState: ContactsSliceStateType = {
  contacts: [],
  contact: {} as IContact,
  contactUsers: [],
  meta: { perPage: 8 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadUsers: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadContactsList = createAsyncAction('contacts/index', ContactsRepository.index);
export const createContact = createAsyncAction('contacts/create', ContactsRepository.create);
export const updateContact = createAsyncAction('contacts/update', ContactsRepository.update);
export const showContact = createAsyncAction('contacts/show', ContactsRepository.show);
export const loadContactUsers = createAsyncAction('contacts/users', ContactsRepository.loadUsers);
export const exportContactsList = createAsyncAction('contacts/export', ContactsRepository.export);
export const exportContactActivitiesList = createAsyncAction(
  'contacts/exportActivities',
  ContactsRepository.exportActivities,
);

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetContact: state => {
      state.contact = {} as IContact;
      state.show.fetchStatus = FetchStatus.idle;
    },
    resetContacts: state => {
      state.contacts = [];
      state.index.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadContactsList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadContactsList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.contacts = payload.contacts;
      state.meta = payload.meta;
    });
    builder.addCase(loadContactsList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadContactUsers.pending, state => {
      state.loadUsers.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadContactUsers.fulfilled, (state, { payload }) => {
      state.loadUsers.fetchStatus = FetchStatus.fulfilled;
      state.contactUsers = payload.users;
      state.meta = payload.meta;
    });
    builder.addCase(loadContactUsers.rejected, state => {
      state.loadUsers.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showContact.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showContact.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.contact = payload.contact;
    });
    builder.addCase(showContact.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetContact, resetContacts },
} = slice;

export { resetContact, resetContacts };

export default slice.reducer;
