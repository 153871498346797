import { createSlice } from '@reduxjs/toolkit';

import { ICompany } from 'domain/company/types';
import { FetchStatus } from 'enums/FetchStatus';
import CompaniesRepository, {
  CreateParams,
  IndexParams,
  ShowParams,
  UpdateParams,
} from 'repositories/CompaniesRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type CompaniesSliceStateType = {
  companies: ICompany[];
  meta: ResponseMeta;
  company: ICompany;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type CompaniesSliceActionsType = {
  loadCompaniesList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'companies', ICompany[]>> };
  createCompany: (params: CreateParams) => { unwrap: () => { company: ICompany } };
  updateCompany: (params: UpdateParams) => { unwrap: () => void };
  showCompany: (params: ShowParams) => { unwrap: () => void };
  resetCompany: () => void;
};

const initialState: CompaniesSliceStateType = {
  companies: [],
  meta: { perPage: 8 } as ResponseMeta,
  company: {} as ICompany,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadCompaniesList = createAsyncAction('companies/index', CompaniesRepository.index);
export const createCompany = createAsyncAction('companies/create', CompaniesRepository.create);
export const updateCompany = createAsyncAction('companies/update', CompaniesRepository.update);
export const showCompany = createAsyncAction('companies/show', CompaniesRepository.show);

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    resetCompany: state => {
      state.company = {} as ICompany;
      state.show.fetchStatus = FetchStatus.idle;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadCompaniesList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadCompaniesList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.companies = payload.companies;
      state.meta = payload.meta;
    });
    builder.addCase(loadCompaniesList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showCompany.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showCompany.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.company = payload.company;
    });
    builder.addCase(showCompany.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetCompany },
} = slice;

export { resetCompany };

export default slice.reducer;
