import { UserRole } from 'domain/user/enums';

export default {
  [UserRole.attorney]: {
    dashboard: {
      main: 'Dashboard',
      item: 'Dashboard Item',
    },
    activities: {
      main: 'Activities',
      allActivities: 'All activities',
      goals: 'Goals',
    },
    contacts: {
      main: 'Contacts',
      companies: 'Companies',
      allContacts: 'All contacts',
    },
    members: {
      main: 'Members',
      team: 'Team',
    },
    notifications: 'Notifications',
    reports: 'Reports',
    settings: 'Settings',
  },
  [UserRole.marketeer]: {
    dashboard: {
      main: 'Dashboard',
      item: 'Dashboard Item',
    },
    members: {
      main: 'Members',
      allMembers: 'All members',
      teams: 'Teams',
    },
    projects: {
      main: 'Projects',
      allProjects: 'All projects',
      tasks: 'ToDos',
    },
    contacts: {
      main: 'Contacts',
      companies: 'Companies',
      allContacts: 'All contacts',
    },
    reports: {
      main: 'Reports',
    },
  },
};
