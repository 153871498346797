import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IActivityType } from 'domain/activityType/types';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

const ActivityTypesRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'activityTypes', IActivityType[]>> {
    const url = apiRoutes.apiSiteV1ClientActivityTypesPath(clientId);
    return Fetcher.get(url, queryParams);
  },
};

export default ActivityTypesRepository;
