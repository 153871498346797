export default {
  clientLabel: 'Client',
  nonLoginUsersImportLabel: 'Non Login Users Import',
  industriesImportLabel: 'Industries Import',
  companiesImportLabel: 'Companies Import',
  practiceGroupsImportLabel: 'Practice Groups Import',
  officesImportLabel: 'Offices Import',
  badgesLabel: 'Badges',
  attorneyReportsLabel: 'Attorney Reports',
  usersLabel: 'Users',
  client: {
    logoLabel: 'Logo',
    nameLabel: 'Name',
    descriptionLabel: 'Description',
    enableGamificationLabel: 'Enable gamification',
    sendUpcomingNotificationsLabel: 'Send upcoming notifications',
    loginUsersLabel: 'Login Users',
    fullNameLabel: 'Full name',
    nonLoginUsersLabel: 'Non Login Users',
    attorneyWorkspaceLabel: 'Attorney workspace',
    mbdWorkspaceLabel: 'MBD workspace',
  },
};
