export default {
  todo: 'ToDo',
  projectFilterLabel: 'Project',
  departmentFilterLabel: 'Departments',
  projectTypeFilterLabel: 'Project type',
  stateFilterLabel: 'Status',
  assigneeFilterLabel: 'Assignee',
  addTask: 'Add new ToDo',
  completionDate: {
    openedLabel: 'opened',
  },
  actions: {
    delete: 'Delete',
    cancel: 'Cancel',
    markAsCompleted: 'Mark as Completed',
    reopen: 'Reopen',
  },
  columnsHeads: {
    project: 'Project',
    name: 'Name',
    assignee: 'Assignee',
    toDo: 'To Do',
    dueDate: 'Due Date',
    completed: 'Completed',
    completedAt: 'Completed At',
    assignedTo: 'Assigned to',
    hours: 'Hours',
  },
};
