import { useEffect, lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-dom-last-location';

import { useUsers } from 'hooks';
import { appRoutes } from 'routes';
import { hasAttorneyAccess, hasMarketeerAccess, hasAnyAdminAccess } from 'domain/user/service';
import Modal from 'components/Modal';

const AdminRouter = lazy(() => import('./components/AdminRouter'));
const AttorneyRouter = lazy(() => import('./components/AttorneyRouter'));
const MarketeerRouter = lazy(() => import('./components/MarketeerRouter'));
const PublicRouter = lazy(() => import('./components/PublicRouter'));

const Router: React.FC = () => {
  const { loadCurrentUser, currentUser, isCurrentUserLoadFinished } = useUsers();

  useEffect(() => {
    loadCurrentUser();
  }, []);

  const options = [
    {
      key: 'attorney',
      access: currentUser && hasAttorneyAccess(currentUser),
      path: appRoutes.public.rootPath(),
      router: <AttorneyRouter />,
    },
    {
      key: 'marketeer',
      access: currentUser && hasMarketeerAccess(currentUser),
      path: appRoutes.public.rootPath(),
      router: <MarketeerRouter />,
    },
    {
      key: 'admin',
      access: currentUser && hasAnyAdminAccess(currentUser),
      path: appRoutes.public.rootPath(),
      router: <AdminRouter />,
    },
    {
      key: 'public',
      access: !currentUser,
      path: appRoutes.public.rootPath(),
      router: <PublicRouter />,
    },
  ];

  if (!isCurrentUserLoadFinished) return <Suspense fallback={null} />;

  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Suspense fallback={null}>
          <Routes>
            {options.map(option => {
              if (!option.access) return null;
              return <Route key={option.key} path={option.path} element={option.router} />;
            })}
          </Routes>
          <Modal />
        </Suspense>
      </LastLocationProvider>
    </BrowserRouter>
  );
};

export default Router;
