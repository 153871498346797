export default {
  type: 'Type',
  repetitionOfEvent: 'Repetition of event',
  additionalDetails: 'Additional Details',
  location: 'Location',
  startedAt: 'Start Time',
  finishedAt: 'Finished At',
  endAt: 'End Time',
  startedDate: 'Start Date',
  endDate: 'End Date',
  removeFromCalendar: 'Remove from Calendar',
  removeRecurringEvent: 'Remove recurring event',
  actions: {
    thisEventOnly: 'This event only',
    allEvents: 'All events',
    remove: 'Remove',
    cancel: 'Cancel',
  },
  owner: 'Owner',
};
