import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiSelectType = {
  defaultProps: ComponentsProps['MuiSelect'];
  styleOverrides: ComponentsOverrides['MuiSelect'];
};

const MuiSelect: MuiSelectType = {
  styleOverrides: {
    root: {},
  },
  defaultProps: {
    variant: 'standard',
  },
};

export default MuiSelect;
