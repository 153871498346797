import { createSlice } from '@reduxjs/toolkit';

import { IUser } from 'domain/user/types';
import { FetchStatus } from 'enums/FetchStatus';
import UsersRepository, { UpdateCurrentUserParams } from 'repositories/UsersRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type UsersSliceStateType = {
  currentUser: IUser;
  current: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type UsersSliceActionsType = {
  loadCurrentUser: () => { unwrap: () => void };
  reloadCurrentUser: () => { unwrap: () => void };
  updateCurrentUser: (params: UpdateCurrentUserParams) => { unwrap: () => void };
  stopImpersonating: () => { unwrap: () => void };
};

const initialState: UsersSliceStateType = {
  currentUser: null,
  current: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadCurrentUser = createAsyncAction('users/current', UsersRepository.current);
export const reloadCurrentUser = createAsyncAction('users/reloadCurrent', UsersRepository.current);
export const updateCurrentUser = createAsyncAction('users/updateCurrentUser', UsersRepository.updateCurrentUser);
export const stopImpersonating = createAsyncAction('users/stopImpersonating', UsersRepository.stopImpersonating);

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadCurrentUser.pending, state => {
      state.current.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadCurrentUser.fulfilled, (state, { payload }) => {
      state.current.fetchStatus = FetchStatus.fulfilled;

      state.currentUser = payload.user;
    });
    builder.addCase(loadCurrentUser.rejected, state => {
      state.current.fetchStatus = FetchStatus.rejected;
      state.currentUser = null;
    });
    builder.addCase(reloadCurrentUser.fulfilled, (state, { payload }) => {
      state.currentUser = payload.user;
    });
  },
});

export default slice.reducer;
