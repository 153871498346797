import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiIconButtonType = {
  defaultProps: ComponentsProps['MuiIconButton'];
  styleOverrides: ComponentsOverrides['MuiIconButton'];
};

const MuiIconButton: MuiIconButtonType = {
  styleOverrides: {},
  defaultProps: {
    disableRipple: true,
  },
};

export default MuiIconButton;
