import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiAvatarType = {
  defaultProps: ComponentsProps['MuiAvatar'];
  styleOverrides: ComponentsOverrides['MuiAvatar'];
};

const MuiAvatar: MuiAvatarType = {
  styleOverrides: {
    square: {
      borderRadius: '4px',
    },
  },
  defaultProps: {},
};

export default MuiAvatar;
