import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IDailyGroupedScore } from 'domain/dailyGroupedScore/types';
import { FetchStatus } from 'enums/FetchStatus';
import DailyScoresRepository, { DailyScoreParams } from 'repositories/AttorneyDashboardRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type DailyScoresSliceStateType = {
  personalDailyGroupedScores: IDailyGroupedScore[];
  averageDailyGroupedScores: IDailyGroupedScore[];
  dailyGroupedScores: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type DailyScoresSliceActionsType = {
  loadPersonalDailyScoreEvents: (params: DailyScoreParams) => {
    unwrap: () => Promise<CollectionResponse<'dailyGroupedScores', IDailyGroupedScore[]>>;
  };
  loadAverageDailyScoreEvents: (params: DailyScoreParams) => {
    unwrap: () => Promise<CollectionResponse<'dailyGroupedScores', IDailyGroupedScore[]>>;
  };
};

const initialState: DailyScoresSliceStateType = {
  personalDailyGroupedScores: [],
  averageDailyGroupedScores: [],
  dailyGroupedScores: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadPersonalDailyScoreEvents = createAsyncAction(
  'attorneyDashboard/personalDailyScoreEvents',
  DailyScoresRepository.personalDailyScoreEvents,
);
export const loadAverageDailyScoreEvents = createAsyncAction(
  'attorneyDashboard/averageDailyScoreEvents',
  DailyScoresRepository.averageDailyScoreEvents,
);

const slice = createSlice({
  name: 'attorneyDashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPersonalDailyScoreEvents.fulfilled, (state, { payload }) => {
      state.personalDailyGroupedScores = payload.dailyGroupedScores;
    });
    builder.addCase(loadAverageDailyScoreEvents.fulfilled, (state, { payload }) => {
      state.averageDailyGroupedScores = payload.dailyGroupedScores;
    });
    builder.addMatcher(isAnyOf(loadPersonalDailyScoreEvents.pending, loadAverageDailyScoreEvents.pending), state => {
      state.dailyGroupedScores.fetchStatus = FetchStatus.pending;
    });
    builder.addMatcher(
      isAnyOf(loadPersonalDailyScoreEvents.fulfilled, loadAverageDailyScoreEvents.fulfilled),
      state => {
        state.dailyGroupedScores.fetchStatus = FetchStatus.fulfilled;
      },
    );
    builder.addMatcher(isAnyOf(loadPersonalDailyScoreEvents.rejected, loadAverageDailyScoreEvents.rejected), state => {
      state.dailyGroupedScores.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
