import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProject } from 'domain/project/types';
import { ProjectState } from 'domain/project/enums';
import { apiRoutes } from 'routes';
import { IndexParams } from 'repositories/ProjectsRepository';
import { HttpRequestMethod } from 'enums/HttpRequestMethod';

import api from './api';

export type ProjectsColumnsSnapshotsStateType = {
  projectsFrom0To33Complete: Array<IProject>;
  projectsFrom34To67Complete: Array<IProject>;
  projectsFrom68To100Complete: Array<IProject>;
  completedProjects: Array<IProject>;
};

export type ProjectsColumnsSnapshotsActionsType = {
  updateProjectsFrom0To33Complete: (projects: Array<IProject>) => void;
  updateProjectsFrom34To67Complete: (projects: Array<IProject>) => void;
  updateProjectsFrom68To100Complete: (projects: Array<IProject>) => void;
  updateCompletedProjects: (projects: Array<IProject>) => void;
};

type MutationParams = {
  clientId: ID;
  projectId: ID;
};

type ProjectsResponse = CollectionResponse<'projects', Array<IProject>>;

const initialState: ProjectsColumnsSnapshotsStateType = {
  projectsFrom0To33Complete: [],
  projectsFrom34To67Complete: [],
  projectsFrom68To100Complete: [],
  completedProjects: [],
};

const projectsColumnsSnapshots = createSlice({
  name: 'projectsColumnsSnapshots',
  initialState,
  reducers: {
    updateProjectsFrom0To33Complete: (state, { payload }: PayloadAction<Array<IProject>>) => {
      state.projectsFrom0To33Complete = payload;
    },
    updateProjectsFrom0To33CompleteFromRTK: (state, { payload }: PayloadAction<ProjectsResponse>) => {
      const { projects, meta } = payload;
      const { currentPage } = meta;
      const { projectsFrom0To33Complete } = state;
      const newProjects = currentPage === 1 ? projects : projectsFrom0To33Complete.concat(projects);
      state.projectsFrom0To33Complete = newProjects;
    },
    updateProjectsFrom34To67Complete: (state, { payload }: PayloadAction<Array<IProject>>) => {
      state.projectsFrom34To67Complete = payload;
    },
    updateProjectsFrom34To67CompleteFromRTK: (state, { payload }: PayloadAction<ProjectsResponse>) => {
      const { projects, meta } = payload;
      const { currentPage } = meta;
      const { projectsFrom34To67Complete } = state;
      const newProjects = currentPage === 1 ? projects : projectsFrom34To67Complete.concat(projects);
      state.projectsFrom34To67Complete = newProjects;
    },
    updateProjectsFrom68To100Complete: (state, { payload }: PayloadAction<Array<IProject>>) => {
      state.projectsFrom68To100Complete = payload;
    },
    updateProjectsFrom68To100CompleteFromRTK: (state, { payload }: PayloadAction<ProjectsResponse>) => {
      const { projects, meta } = payload;
      const { currentPage } = meta;
      const { projectsFrom68To100Complete } = state;
      const newProjects = currentPage === 1 ? projects : projectsFrom68To100Complete.concat(projects);
      state.projectsFrom68To100Complete = newProjects;
    },
    updateCompletedProjects: (state, { payload }: PayloadAction<Array<IProject>>) => {
      state.completedProjects = payload;
    },
    updateCompletedProjectsFromRTK: (state, { payload }: PayloadAction<ProjectsResponse>) => {
      const { projects, meta } = payload;
      const { currentPage } = meta;
      const { completedProjects } = state;
      const newProjects = currentPage === 1 ? projects : completedProjects.concat(projects);
      state.completedProjects = newProjects;
    },
  },
});

const {
  updateProjectsFrom0To33Complete,
  updateProjectsFrom0To33CompleteFromRTK,
  updateProjectsFrom34To67Complete,
  updateProjectsFrom34To67CompleteFromRTK,
  updateProjectsFrom68To100Complete,
  updateProjectsFrom68To100CompleteFromRTK,
  updateCompletedProjects,
  updateCompletedProjectsFromRTK,
} = projectsColumnsSnapshots.actions;

const projectsApi = api.injectEndpoints({
  endpoints: build => ({
    get0To33CompleteProjects: build.query<ProjectsResponse, IndexParams>({
      query: ({ clientId, queryParams }) => ({
        url: apiRoutes.apiSiteV1ClientProjectsPath(clientId),
        params: {
          q: {
            ...queryParams.q,
            status_lt: 0.34,
            state_not_eq: ProjectState.completed,
          },
          page: queryParams.page,
          per_page: queryParams.perPage,
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateProjectsFrom0To33CompleteFromRTK(data));
      },
    }),
    get34To67CompleteProjects: build.query<ProjectsResponse, IndexParams>({
      query: ({ clientId, queryParams }) => ({
        url: apiRoutes.apiSiteV1ClientProjectsPath(clientId),
        params: {
          q: {
            ...queryParams.q,
            status_gteq: 0.34,
            status_lt: 0.68,
            state_not_eq: ProjectState.completed,
          },
          page: queryParams.page,
          per_page: queryParams.perPage,
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateProjectsFrom34To67CompleteFromRTK(data));
      },
    }),
    get68To100CompleteProjects: build.query<ProjectsResponse, IndexParams>({
      query: ({ clientId, queryParams }) => ({
        url: apiRoutes.apiSiteV1ClientProjectsPath(clientId),
        params: {
          q: {
            ...queryParams.q,
            status_gteq: 0.68,
            state_not_eq: ProjectState.completed,
          },
          page: queryParams.page,
          per_page: queryParams.perPage,
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateProjectsFrom68To100CompleteFromRTK(data));
      },
    }),
    getCompletedProjects: build.query<ProjectsResponse, IndexParams>({
      query: ({ clientId, queryParams }) => ({
        url: apiRoutes.apiSiteV1ClientProjectsPath(clientId),
        params: {
          q: {
            ...queryParams.q,
            state_eq: ProjectState.completed,
          },
          page: queryParams.page,
          per_page: queryParams.perPage,
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateCompletedProjectsFromRTK(data));
      },
    }),
    completeProject: build.mutation<void, MutationParams>({
      query: ({ clientId, projectId }) => ({
        url: apiRoutes.completeApiSiteV1ClientProjectPath(clientId, projectId),
        method: HttpRequestMethod.put,
      }),
    }),
    deleteProject: build.mutation<void, MutationParams>({
      query: ({ clientId, projectId }) => ({
        url: apiRoutes.apiSiteV1ClientProjectPath(clientId, projectId),
        method: HttpRequestMethod.delete,
      }),
    }),
    openProject: build.mutation<void, MutationParams>({
      query: ({ clientId, projectId }) => ({
        url: apiRoutes.openApiSiteV1ClientProjectPath(clientId, projectId),
        method: HttpRequestMethod.put,
      }),
    }),
  }),
});

const {
  useLazyGet0To33CompleteProjectsQuery,
  useLazyGet34To67CompleteProjectsQuery,
  useLazyGet68To100CompleteProjectsQuery,
  useLazyGetCompletedProjectsQuery,
  useCompleteProjectMutation,
  useDeleteProjectMutation,
  useOpenProjectMutation,
} = projectsApi;

export {
  useLazyGet0To33CompleteProjectsQuery,
  useLazyGet34To67CompleteProjectsQuery,
  useLazyGet68To100CompleteProjectsQuery,
  useLazyGetCompletedProjectsQuery,
  useCompleteProjectMutation,
  useDeleteProjectMutation,
  useOpenProjectMutation,
  updateProjectsFrom0To33Complete,
  updateProjectsFrom34To67Complete,
  updateProjectsFrom68To100Complete,
  updateCompletedProjects,
};

export default projectsColumnsSnapshots.reducer;
