import { alpha, darken } from '@mui/material/styles';

const mainGreen = '#007770';
const semiLightGreen = '#549159';
const mainRed = '#D21313';
const darkRed = '#C83131';
const mainOrange = '#F2AE00';
const mediumOrange = '#F4E7C5';
const lightOrange = '#FAF8F2';
const mainBrown = '#3E362D';
const lighterBlue = '#E6F1F1';
const lightBlue = '#E9F2F2';
const mainBlue = '#336699';
const mainGray = '#E1E1E1';
const mainDark = '#211F1D';
const lightDark = '#838281';
const semiDark = '#B9B7B2';
const mainBlack = '#201C17';
const textBlack = '#000000DE';
const mainWhite = '#FFF';
const mainPurple = '#9184E6';
const lightBrown = '#EAD06E';

const COLORS = {
  green: {
    extraLight: alpha(mainGreen, 0.05),
    light: alpha(semiLightGreen, 0.2),
    lighter: alpha(mainGreen, 0.1),
    semiLight: semiLightGreen,
    main: mainGreen,
    darker: darken(mainGreen, 0.2),
  },
  red: {
    lighter: alpha(mainRed, 0.1),
    main: mainRed,
    dark: darkRed,
  },
  orange: {
    lighter: alpha(mainOrange, 0.15),
    semiLighter: alpha(mainOrange, 0.2),
    light: lightOrange,
    medium: mediumOrange,
    main: mainOrange,
  },
  gray: {
    light: alpha(mainGray, 0.5),
    main: mainGray,
    dark: darken(mainGray, 0.05),
    darker: darken(mainGray, 0.2),
  },
  black: {
    ultraLight: alpha(mainBlack, 0.1),
    extraLight: alpha(mainBlack, 0.2),
    lighter: alpha(mainBlack, 0.3),
    light: alpha(mainBlack, 0.45),
    semiLight: alpha(mainBlack, 0.7),
    main: mainBlack,
    text: textBlack,
  },
  dark: {
    extraLight: alpha(mainDark, 0.05),
    lighter: alpha(mainDark, 0.1),
    light: lightDark,
    semiDark,
    main: mainDark,
  },
  blue: {
    lighter: lighterBlue,
    light: lightBlue,
    main: mainBlue,
  },
  brown: {
    main: mainBrown,
    light: lightBrown,
  },
  purple: {
    light: alpha(mainPurple, 0.2),
    main: mainPurple,
  },
  white: {
    lighter: alpha(mainWhite, 0.2),
    light: alpha(mainWhite, 0.6),
    main: mainWhite,
  },
};

const palette = {
  ...COLORS,
};

declare module '@mui/material/styles' {
  interface Palette {
    green: {
      extraLight: string;
      lighter: string;
      light: string;
      main: string;
      darker: string;
      semiLight: string;
    };
    red: {
      lighter: string;
      main: string;
      dark: string;
    };
    orange: {
      lighter: string;
      semiLighter: string;
      light: string;
      medium: string;
      main: string;
    };
    gray: {
      light: string;
      main: string;
      dark: string;
      darker: string;
    };
    black: {
      ultraLight: string;
      extraLight: string;
      lighter: string;
      light: string;
      semiLight: string;
      main: string;
      text: string;
    };
    dark: {
      extraLight: string;
      lighter: string;
      light: string;
      semiDark: string;
      main: string;
    };
    blue: {
      lighter: string;
      light: string;
      main: string;
    };
    brown: {
      main: string;
    };
    white: {
      main: string;
      lighter: string;
    };
    purple: {
      light: string;
      main: string;
    };
  }

  interface PaletteColor {
    lighter?: string;
    darker?: string;
    extraDark?: string;
    extraLight?: string;
  }

  interface SimplePaletteColorOptions {
    light?: string;
    lighter?: string;
    darker?: string;
    extraDark?: string;
    extraLight?: string;
  }

  interface PaletteOptions {
    green?: PaletteOptions['primary'];
  }
}

export default palette;
