import { ProjectDepartment } from 'domain/project/enums';
import { IProjectNameDetailReportStats, IReportProject } from 'domain/projectNameDetailReport/types';
import { apiRoutes } from 'routes';
import { TableFilterQueryParams } from 'types/tableFilter';
import Fetcher from 'utils/fetcher';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
  department: ProjectDepartment;
};
export type StatsParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

const ProjectTypeReportRepository = {
  index({ clientId, queryParams, department }: IndexParams): Promise<CollectionResponse<'projects', IReportProject[]>> {
    const url = apiRoutes.projectsApiSiteV1ClientReportsProjectTypeReportIndexPath(clientId);
    const params = {
      ...queryParams,
      q: {
        ...queryParams.q,
        departmentEq: department,
      },
    };

    return Fetcher.get(url, params);
  },
  stats({ clientId, queryParams }: StatsParams): Promise<IProjectNameDetailReportStats> {
    const url = apiRoutes.statsApiSiteV1ClientReportsProjectTypeReportIndexPath(clientId);
    return Fetcher.get(url, queryParams);
  },
};

export default ProjectTypeReportRepository;
