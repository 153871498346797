import { createSlice } from '@reduxjs/toolkit';

import { IActivity } from 'domain/activity/types';
import { FetchStatus } from 'enums/FetchStatus';
import ActivitiesRepository, {
  IndexParams,
  CreateParams,
  UpdateParams,
  ShowParams,
  ActionParams,
  CompleteParams,
} from 'repositories/ActivitiesRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type ActivitiesSliceStateType = {
  activities: IActivity[];
  meta: ResponseMeta;
  activity: IActivity;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  complete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  open: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ActivitiesSliceActionsType = {
  loadActivitiesList: (params: IndexParams) => { unwrap: () => Promise<CollectionResponse<'activities', IActivity[]>> };
  deleteActivity: (params: ActionParams) => { unwrap: () => void };
  completeActivity: (params: CompleteParams) => { unwrap: () => void };
  openActivity: (params: ActionParams) => { unwrap: () => void };
  exportActivitiesList: (params: IndexParams) => { unwrap: () => void };
  showActivity: (params: ShowParams) => { unwrap: () => void };
  createActivity: (params: CreateParams) => { unwrap: () => void };
  updateActivity: (params: UpdateParams) => { unwrap: () => void };
  resetActivity: () => void;
  resetActivities: () => void;
};

const initialState: ActivitiesSliceStateType = {
  activities: [],
  activity: {} as IActivity,
  meta: { perPage: 8 } as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  complete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  open: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadActivitiesList = createAsyncAction('activities/index', ActivitiesRepository.index);
export const deleteActivity = createAsyncAction('activities/delete', ActivitiesRepository.delete);
export const completeActivity = createAsyncAction('activities/complete', ActivitiesRepository.complete);
export const openActivity = createAsyncAction('activities/open', ActivitiesRepository.open);
export const exportActivitiesList = createAsyncAction('activities/export', ActivitiesRepository.export);
export const createActivity = createAsyncAction('activities/create', ActivitiesRepository.create);
export const updateActivity = createAsyncAction('activities/update', ActivitiesRepository.update);
export const showActivity = createAsyncAction('activities/show', ActivitiesRepository.show);

const slice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    resetActivity: state => {
      state.activity = {} as IActivity;
      state.show.fetchStatus = FetchStatus.idle;
    },
    resetActivities: state => {
      state.activities = [];
      state.index.fetchStatus = FetchStatus.idle;
      state.meta = { perPage: 8 } as ResponseMeta;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadActivitiesList.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadActivitiesList.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.activities = payload.activities;
      state.meta = payload.meta;
    });
    builder.addCase(loadActivitiesList.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(deleteActivity.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteActivity.fulfilled, state => {
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteActivity.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });

    builder.addCase(completeActivity.pending, state => {
      state.complete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(completeActivity.fulfilled, (state, { payload }) => {
      state.complete.fetchStatus = FetchStatus.fulfilled;
      const activityId = payload.activity.id;
      const activityState = payload.activity.state;
      const currentActivity = state.activities.find(activity => activity.id === activityId);

      currentActivity.state = activityState;

      return state;
    });
    builder.addCase(completeActivity.rejected, state => {
      state.complete.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(openActivity.pending, state => {
      state.open.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(openActivity.fulfilled, (state, { payload }) => {
      state.open.fetchStatus = FetchStatus.fulfilled;
      const activityId = payload.activity.id;
      const activityState = payload.activity.state;
      const currentActivity = state.activities.find(activity => activity.id === activityId);

      currentActivity.state = activityState;

      return state;
    });
    builder.addCase(openActivity.rejected, state => {
      state.open.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showActivity.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showActivity.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.activity = payload.activity;
    });
    builder.addCase(showActivity.rejected, state => {
      state.show.fetchStatus = FetchStatus.rejected;
    });
  },
});

const {
  actions: { resetActivity, resetActivities },
} = slice;

export { resetActivity, resetActivities };

export default slice.reducer;
