import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IPracticeGroup } from 'domain/practiceGroup/types';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type ShowParams = {
  clientId: ID;
  practiceGroupId: ID;
};

const PracticeGroupsRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'practiceGroups', IPracticeGroup[]>> {
    const url = apiRoutes.apiSiteV1ClientPracticeGroupsPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  show({ clientId, practiceGroupId }: ShowParams): Promise<SingleResponse<'practiceGroup', IPracticeGroup>> {
    const url = apiRoutes.apiSiteV1ClientPracticeGroupPath(clientId, practiceGroupId);
    return Fetcher.get(url);
  },
};

export default PracticeGroupsRepository;
