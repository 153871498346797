import { createSlice } from '@reduxjs/toolkit';

import { ProjectDepartment } from 'domain/project/enums';
import { IProjectNameDetailReportStats, IReportProject } from 'domain/projectNameDetailReport/types';
import { FetchStatus } from 'enums/FetchStatus';
import ProjectNameDetailReportRepository, {
  IndexParams,
  StatsParams,
} from 'repositories/Reports/ProjectNameDetailReportRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

type ProjectNameDetailReportItem = {
  data: IReportProject[];
  meta: ResponseMeta;
  fetchStatus: FetchStatus;
};

export type ProjectNameDetailReportsStateType = Record<ProjectDepartment, ProjectNameDetailReportItem> & {
  stats: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  reportStatsData: IProjectNameDetailReportStats;
};

export type ProjectNameDetailReportSliceActionsType = {
  loadReportProjectsList: (params: IndexParams) => {
    unwrap: () => Promise<CollectionResponse<'projects', IReportProject[]>>;
  };
  loadReportProjectsStats: (params: StatsParams) => {
    unwrap: () => Promise<IProjectNameDetailReportStats>;
  };
};

const initialState: ProjectNameDetailReportsStateType = {
  [ProjectDepartment.businessDevelopment]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  [ProjectDepartment.communications]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  [ProjectDepartment.marketing]: {
    data: [],
    meta: { perPage: 8 } as ResponseMeta,
    fetchStatus: FetchStatus.idle,
  },
  reportStatsData: {} as IProjectNameDetailReportStats,
  stats: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadReportProjectsList = createAsyncAction(
  'projectNameDetailReport/index',
  ProjectNameDetailReportRepository.index,
);
export const loadReportProjectsStats = createAsyncAction(
  'projectNameDetailReport/stats',
  ProjectNameDetailReportRepository.stats,
);

const slice = createSlice({
  name: 'projectNameDetailReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadReportProjectsList.pending, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadReportProjectsList.fulfilled, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.fulfilled;
      state[department].data = action.payload.projects;
      state[department].meta = action.payload.meta;
    });
    builder.addCase(loadReportProjectsList.rejected, (state, action) => {
      const { department } = action.meta.arg;

      state[department].fetchStatus = FetchStatus.fulfilled;
    });

    builder.addCase(loadReportProjectsStats.pending, state => {
      state.stats.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadReportProjectsStats.fulfilled, (state, { payload }) => {
      state.reportStatsData = payload;
      state.stats.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadReportProjectsStats.rejected, state => {
      state.stats.fetchStatus = FetchStatus.rejected;
    });
  },
});

export default slice.reducer;
