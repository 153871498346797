export default {
  nameLabel: 'Display Name',
  firstName: {
    label: 'First name',
    placeholder: 'John',
  },
  lastName: {
    label: 'Last name',
    placeholder: 'Appleseed',
  },
  phone: {
    label: 'Phone Number',
    placeholder: '(___)-___-____',
  },
  photo: {
    label: {
      title: 'Photo',
      subtitle: 'Change your photo',
    },
    button: 'Upload new photo, up to 5mb',
    removeButton: 'delete profile image',
  },
  title: {
    label: 'Title',
    placeholder: 'Enter Title',
  },
  companyName: {
    label: 'Company',
    placeholder: 'Enter Company Name',
  },
  office: {
    label: 'Office',
    placeholder: 'Enter Office',
  },
  practiceGroup1: {
    label: 'Practice Group',
    placeholder: 'Enter Practice Group',
  },
  practiceGroup2: {
    label: 'Practice Group 2',
    placeholder: 'Enter Practice Group 2',
  },
  lockoutEnable: {
    label: 'Lockout Enabled',
  },
  userName: {
    label: 'User Name',
  },
  accountSecurityTitle: 'Account & security',
  email: {
    label: 'Email',
    subtitle: 'To receive an invitation',
    placeholder: 'Enter Email',
  },
  newPasswordButton: 'Set new password',
  updateSuccessMessage: 'Successfully updated',
  submit: 'Save changes',
};
