export default {
  upcomingTasks: {
    title: 'Upcoming ToDo',
    noData: 'No ToDo',
    moreTasksLabel: 'more ToDo',
    showAllLabel: 'Show all ToDo',
    addTaskButton: 'Add New ToDo',
  },
  monthlyProjectsReport: {
    title: 'Personal Projects Complete/Outstanding',
    complete: 'Complete',
    outstanding: 'Outstanding',
    addProjectButton: 'Add New Project',
  },
  calendar: {
    addEvent: 'Add event',
    showAllEvent: 'Show all Events',
  },
};
