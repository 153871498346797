export default {
  title: 'Calendar Items',
  projectCategoryEventPrefix: 'Project - {{department}} - {{type}}',
  customCategoryEventPrefix: 'Custom Events - {{type}}',
  moreButton: 'And {{num}} more...',
  owner: 'Owner: ',
  assignee: 'Assignee: ',
  addCalendarEventButton: 'Add new event',
  customCategoryName: 'Event',
  teamMemberFilterLabel: 'Team member',
  categoryFilterLabel: 'Category',
  eventTypeFilterLabel: 'Type',
  projectItemDueDate: 'Due date: {{date}}',
  list: {
    removeButton: 'Remove from Calendar',
    editButton: 'Edit',
  },
  removeSuccessMessage: 'Calendar Event Was Removed',
};
