export default {
  workspaceFilter: 'Team type',
  actions: {
    details: 'Details',
  },
  columnsHeads: {
    teamName: 'Team name',
    workspace: 'Workspace',
  },
};
