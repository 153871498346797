import { concat } from 'ramda';

import { Workspace } from 'enums/Workspace';

export interface Routes {
  [key: string]: (...args: ID[]) => string;
}

export const makeRoutes = <T extends Routes>(routes: T, basepath = '/'): T => {
  return Object.keys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: (...params: ID[]) => concat(basepath, routes[key](...params)).replace(/\/\//, '/'),
    }),
    {},
  ) as T;
};

export const getPathPrefixByWorkspace = (workspace: Workspace): string => {
  const paths = {
    [Workspace.attorney]: 'attorney',
    [Workspace.marketeer]: 'mbd',
  };

  return paths[workspace] || null;
};
