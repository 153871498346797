import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiTableCellType = {
  defaultProps: ComponentsProps['MuiTableCell'];
  styleOverrides: ComponentsOverrides['MuiTableCell'];
};

const MuiTableCell: MuiTableCellType = {
  styleOverrides: {
    root: {
      padding: '10px 0',
      fontSize: '13px',
    },
  },
  defaultProps: {},
};

export default MuiTableCell;
