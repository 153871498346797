import { makeRoutes } from 'utils/routes';

const paths = makeRoutes({
  rootPath: () => '/*',
  signIn: () => '/sign-in',
  passwordRecovery: () => '/password-recovery',
  passwordReset: () => '/password-reset',
  profileEditPath: () => '/profile/edit',
});

export default paths;
