import { createSlice } from '@reduxjs/toolkit';

import { IGlobalSearchItem, IGlobalSearchResponse } from 'domain/globalSearch/types';
import { FetchStatus } from 'enums/FetchStatus';
import GlobalSearchRepository, { IndexParams } from 'repositories/GlobalSearchRepository';
import { createAsyncAction } from 'utils/createAsyncAction';

export type GlobalSearchStateType = {
  searchResult: IGlobalSearchItem[];
  meta: ResponseMeta;
  index: {
    fetchStatus: FetchStatus;
    error: null;
  };
};

export type GlobalSearchActionsType = {
  loadGlobalSearchResult: (params: IndexParams) => { unwrap: () => Promise<IGlobalSearchResponse> };
};

export const loadGlobalSearchResult = createAsyncAction('globalSearch/index', GlobalSearchRepository.index);

const initialState: GlobalSearchStateType = {
  searchResult: [],
  meta: {} as ResponseMeta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadGlobalSearchResult.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadGlobalSearchResult.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadGlobalSearchResult.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.searchResult = payload.globalSearchItems;
      state.meta = payload.meta;
    });
  },
});

export default slice.reducer;
