import { makeRoutes } from 'utils/routes';

const paths = makeRoutes({
  rootPath: () => '/admin/',
  usersPath: () => '/admin/loginUsers',
  clientsPath: () => '/admin/clients',
  reportsPath: () => '/reports',
});

export default paths;
