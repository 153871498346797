export default {
  goal: 'Goal',
  addGoal: 'Add new goal',
  goalFilterLabel: 'Goal',
  stateFilterLabel: 'Status',
  activityTypeFilterLabel: 'Activity Types',
  exportButton: 'Export',
  completedGoalState: 'Completed',
  uncompletedGoalState: 'Mark as completed',
  noActiveActivities: 'No active activities',
  activeActivity_one: '{{count}} active activity',
  activeActivity_other: '{{count}} active activities',
  actions: {
    seeDetailsLabel: 'See details',
  },
  no: 'No',
  state: {
    complete: 'Yes, complete Goal',
    review: 'Review Activities',
    cancel: 'Cancel',
  },
  columnsHeads: {
    goal: 'Goal',
    dueDate: 'Due Date',
    activities: 'Activities',
  },
};
