export default {
  name: {
    label: 'Event Name',
    placeholder: 'Enter event Name',
  },
  location: {
    label: 'Location',
    placeholder: 'Enter location',
  },
  dateRange: {
    label: 'Date Range',
    subtitle: 'Set in your local timezone [{{timezone}}]',
  },
  startedAt: {
    placeholder: 'Start Date',
  },
  finishedAt: {
    placeholder: 'End Date',
  },
  recurrence: {
    placeholder: 'Recurrence',
    label: 'Repetition of the event',
  },
  recurrenceType: {
    label: 'Ends',
    placeholder: 'Choose',
  },
  recurrenceEndTime: {
    label: 'End date',
    placeholder: 'Choose Date',
  },
  recurrenceCount: {
    placeholder: 'Number of iterations',
    suffix: 'occurrences',
  },
  eventType: {
    label: 'Type',
    placeholder: 'Choose event type',
  },
  additionalDetails: {
    label: 'Additional Details',
    placeholder: 'Enter additional details',
  },
  submit: 'Create',
  update: 'Update',
  createSuccessMessage: 'Successfully created',
  updateSuccessMessage: 'Successfully updated',
};
