export default {
  companyLabel: 'Company',
  addContact: 'Add new contact',
  exportButton: 'Export',
  importButton: 'Import',
  seeDetailsButton: 'See details',
  myContactsLabel: 'My contacts',
  columnsHeads: {
    person: 'Person',
    company: 'Company',
    position: 'Position',
  },
};
