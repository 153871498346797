import { createSlice } from '@reduxjs/toolkit';

import { ITeamDetailReport, ITeamDetailUser } from 'domain/teamDetailReport/types';
import TeamDetailReportRepository, {
  IndexParams,
  ShowParams,
  UsersParams,
  StatsParams,
} from 'repositories/Reports/TeamDetailReportRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncAction } from 'utils/createAsyncAction';
import { IProjectNameDetailReportStats, IReportProject } from 'domain/projectNameDetailReport/types';
import { ProjectDepartment } from 'domain/project/enums';

type ProjectNameDetailReportItem = {
  data: IReportProject[];
  meta: ResponseMeta;
  fetchStatus: FetchStatus;
};

export type TeamDetailReportSliceStateType = {
  teamDetailReport: ITeamDetailReport;
  personalDetailReport: Record<ProjectDepartment, ProjectNameDetailReportItem>;
  personalDetailReportStats: IProjectNameDetailReportStats;
  teamDetailUsers: ITeamDetailUser[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  users: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  stats: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  filterUsers: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TeamDetailReportSliceActionsType = {
  loadTeamDetailReport: (params: IndexParams) => { unwrap: () => Promise<ITeamDetailReport> };
  loadPersonalTeamDetailStats: (params: StatsParams) => { unwrap: () => Promise<unknown> };
  showTeamDetailReport: (params: ShowParams) => { unwrap: () => Promise<unknown> };
  loadTeamDetailUsers: (params: UsersParams) => {
    unwrap: () => Promise<CollectionResponse<'users', ITeamDetailUser[]>>;
  };
  reset: () => void;
};

export const loadTeamDetailReport = createAsyncAction('teamDetailReport/index', TeamDetailReportRepository.index);
export const showTeamDetailReport = createAsyncAction('teamDetailReport/show', TeamDetailReportRepository.show);
export const loadTeamDetailUsers = createAsyncAction('teamDetailReport/users', TeamDetailReportRepository.users);
export const loadPersonalTeamDetailStats = createAsyncAction(
  'teamDetailReport/stats',
  TeamDetailReportRepository.stats,
);

const initialState: TeamDetailReportSliceStateType = {
  teamDetailReport: {} as ITeamDetailReport,
  personalDetailReportStats: {} as IProjectNameDetailReportStats,
  teamDetailUsers: [],
  personalDetailReport: {
    [ProjectDepartment.businessDevelopment]: {
      data: [],
      meta: { perPage: 8 } as ResponseMeta,
      fetchStatus: FetchStatus.idle,
    },
    [ProjectDepartment.communications]: {
      data: [],
      meta: { perPage: 8 } as ResponseMeta,
      fetchStatus: FetchStatus.idle,
    },
    [ProjectDepartment.marketing]: {
      data: [],
      meta: { perPage: 8 } as ResponseMeta,
      fetchStatus: FetchStatus.idle,
    },
  },
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  users: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  stats: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  filterUsers: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const teamDetailReportSlice = createSlice({
  name: 'teamDetailReport',
  initialState,
  reducers: {
    reset: state => {
      state.teamDetailReport = {} as ITeamDetailReport;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTeamDetailReport.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTeamDetailReport.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.teamDetailReport = payload;
    });
    builder.addCase(loadTeamDetailReport.rejected, state => {
      state.index.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(showTeamDetailReport.pending, (state, action) => {
      const { department } = action.meta.arg;

      state.personalDetailReport[department].fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(showTeamDetailReport.fulfilled, (state, action) => {
      const { department } = action.meta.arg;

      state.personalDetailReport[department].fetchStatus = FetchStatus.fulfilled;
      state.personalDetailReport[department].data = action.payload.projects;
      state.personalDetailReport[department].meta = action.payload.meta;
    });
    builder.addCase(showTeamDetailReport.rejected, (state, action) => {
      const { department } = action.meta.arg;

      state.personalDetailReport[department].fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadPersonalTeamDetailStats.pending, state => {
      state.stats.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadPersonalTeamDetailStats.fulfilled, (state, { payload }) => {
      state.stats.fetchStatus = FetchStatus.fulfilled;
      state.personalDetailReportStats = payload;
    });
    builder.addCase(loadPersonalTeamDetailStats.rejected, state => {
      state.stats.fetchStatus = FetchStatus.rejected;
    });
    builder.addCase(loadTeamDetailUsers.pending, state => {
      state.users.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTeamDetailUsers.fulfilled, (state, { payload }) => {
      state.users.fetchStatus = FetchStatus.fulfilled;
      state.teamDetailUsers = payload.users;
    });
    builder.addCase(loadTeamDetailUsers.rejected, state => {
      state.users.fetchStatus = FetchStatus.rejected;
    });
  },
});

const { reset } = teamDetailReportSlice.actions;

export { reset };

export default teamDetailReportSlice.reducer;
