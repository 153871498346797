import { SvgIcon, SvgIconProps } from '@mui/material';

import dictonary, { IconsDictionary } from './dictionary';

type IconProps = {
  name: IconsDictionary;
};

const Icon: React.FC<IconProps & SvgIconProps> = props => {
  const { name, ...restProps } = props;
  const { viewBox, width, height, id } = dictonary[name];

  return (
    <SvgIcon viewBox={viewBox} width={width} height={height} {...restProps}>
      <use xlinkHref={id} />
    </SvgIcon>
  );
};

export default Icon;
