import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { IUser } from 'domain/user/types';
import { SignInFormDataToSubmit } from 'forms/public/signInForm';

export type SsoLoginResponse = {
  samlRedirectLink: Location;
};

const SessionRepository = {
  create(params: SignInFormDataToSubmit): Promise<SingleResponse<'user', IUser>> {
    const url = apiRoutes.apiSiteV1SessionPath();
    return Fetcher.post(url, params);
  },
  ssoLogin(): Promise<SsoLoginResponse> {
    const url = apiRoutes.loginApiSiteV1SsoSamlPath();
    return Fetcher.get(url);
  },
  delete(): Promise<unknown> {
    const url = apiRoutes.apiSiteV1SessionPath();
    return Fetcher.delete(url);
  },
};

export default SessionRepository;
